import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { fetchData } from "src/async/fetch";
import { apiUrl } from "src/config/host";
import WorkspaceLayouts from "src/components/Workspaces/LayoutsAndStyles/WorkspaceLayouts";
import { getAuthorizedUser } from "src/actions/auth";
import WorkspaceStyles from "src/components/Workspaces/LayoutsAndStyles/WorkspaceStyles";
import { connect } from "react-redux";

const BgWrappers = ({ children, name }) => {
  return (
    <div className="relative space-y-2">
      <div className="text-lg font-medium text-slate-400">{name}</div>
      <div className="relative">{children}</div>
    </div>
  );
};

const WorkspaceLayoutStyles = ({ getAuthorizedUser }) => {
  const { id } = useParams();
  const [workspace, setWorkspace] = useState({});

  const loadData = async (ac = {}) => {
    try {
      let workspaceData = {};

      try {
        const workspaceResponse = await fetchData("GET", `${apiUrl}/workspaces/${id}`);
        workspaceData = await workspaceResponse.json();

        if (workspaceData.status === 200) setWorkspace(workspaceData.data);
        else {
          throw new Error(workspaceData.message);
        }
      } catch (error) {
        throw new Error(error.message);
      }
    } catch (error) {
      console.dir(error.message);
    }
  };

  useEffect(() => {
    if (id) {
      const ac = new AbortController();
      loadData(ac);
      return () => ac.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const setUser = async (id) => {
    const ac = new AbortController();
    await getAuthorizedUser(ac.signal);
  };

  useEffect(() => {
    setUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative">
      <BgWrappers name="Workspace Styles">
        <WorkspaceStyles workspace={workspace} />
      </BgWrappers>
      <BgWrappers name="Workspace Layout">
        <WorkspaceLayouts workspace={workspace} />
      </BgWrappers>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, { getAuthorizedUser })(WorkspaceLayoutStyles);
