import { fetchData } from "src/async/fetch";
import { apiUrl } from "src/config/host";
import { dispatcher } from "src/actions/helpers/dispatcher";
import { GET_SSO_DATA, DELETE_SSO_DATA } from "src/actions/type";

export const addSSO = (body, signal = null, url = "/sso/add", httpType = "POST", dispatchType = GET_SSO_DATA) => dispatcher({ body }, signal, url, httpType, dispatchType);
export const getSSOData = (body, signal = null, url = "/sso/list", httpType = "POST", dispatchType = GET_SSO_DATA) => dispatcher({ body }, signal, url, httpType, dispatchType);
export const getSSODataCount = (payload, signal = null, url = "/sso/list", httpType = "POST", dispatchType = GET_SSO_DATA) => dispatcher(payload, signal, url, httpType, dispatchType);


// export const getSSODataCount2 =
//   (payload, signal = null) =>
//   async (dispatch) => {
//     try {
//       const res = await fetchData("POST", `${apiUrl}/sso/list`, body, signal);
//       const data = await res.json();

//       if (data.status === 200) {
//         dispatch({ type: GET_SSO_DATA, payload: data.data });
//         return data;
//       } else {
//         throw new Error(data.message);
//       }
//     } catch (error) {
//       throw new Error(error.message);
//     }
//   };

export const getXmlData = (body, signal = null, url = "/sso/details/url", httpType = "POST", dispatchType = GET_SSO_DATA) => dispatcher({ body }, signal, url, httpType, dispatchType);
export const getDefaultSSOData = (body, signal = null, url = "/sso/default", httpType = "POST", dispatchType = GET_SSO_DATA) => dispatcher({ body }, signal, url, httpType, dispatchType);
export const getSso = (body, signal = null, url = "/sso/details", httpType = "POST", dispatchType = GET_SSO_DATA) => dispatcher({ body }, signal, url, httpType, dispatchType);
export const updateSso = (body, signal = null, url = "/sso/edit", httpType = "POST", dispatchType = GET_SSO_DATA) => dispatcher({ body }, signal, url, httpType, dispatchType);

export const deleteSso = (id, signal) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/sso/remove`, { id }, signal);
    const data = await res.json();

    if (data.status === 200) {
      dispatch({ type: DELETE_SSO_DATA, payload: id });
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const makeDefault = (body, signal = null, url = "/sso/set-default", httpType = "POST") => dispatcher({ body }, signal, url, httpType);
export const ssoLoginData = (body, signal = null, url = "/sso-url/details", httpType = "POST", dispatchType = GET_SSO_DATA) => dispatcher({ body }, signal, url, httpType, dispatchType);