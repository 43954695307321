import { ArrowDownTrayIcon, CloudArrowUpIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { addBulkUser, getUsers, getUsersList } from "src/actions/user";
import Button from "src/components/Shared/Buttons/Button";
import { filePath } from "src/config/host";
import { convertToBase64 } from "src/helpers/image";

import FormContainer from "src/components/Shared/Forms/FormContainer";

const BulkUpload = ({ addBulkUser, users, creds, getUsers, getUsersList, type, paginationLimit, status, setStatus, group, setGroup, keyword, setKeyword, offset, groups, ...props }) => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const navigate = useNavigate();

  const handleFileChange = (e) => {
    if (e.target.files[0]?.name) {
      setFileName(e.target.files[0]?.name);
      convertToBase64(e.target.files[0], setFile);
    }
  };

  const onSubmit = async () => {
    try {
      const message = await addBulkUser({ file });
      toast.success(message);

      return navigate("/users");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <FormContainer className="max-w-4xl">
      <div className="pb-6 flex items-end flex-wrap gap-y-3 sm:flex-nowrap gap-x-8 max-w-3xl">
        <div className="w-full flex items-end sm:justify-end gap-x-5">
          <div className="grid space-y-2">
            <div className="flex text-sm font-medium">
              Select CSV File
              <span className="text-gray-400 pl-3">*Case sensitive</span>
            </div>
            <div className="relative flex rounded-md w-96">
              <div className="w-[140px] shadow-sm flex items-center px-3 rounded border border-gray-300 bg-gray-50 text-gray-500 relative overflow-hidden">
                <CloudArrowUpIcon className="w-5 h-5" />
                <span className="flex text-sm text-gray-600 mx-auto font-medium">Select File</span>
                <input
                  type="file"
                  className="absolute opacity-0 w-96 top-0 right-0 h-full cursor-pointer z-10"
                  onChange={handleFileChange}
                  accept=".csv"
                />
              </div>
              <div className="absolute -left-4 -bottom-10 w-40 flex sm:px-2">
                <a
                  href={filePath + "/upload-users.csv"}
                  className="font-medium underline focus:outline-none text-gray-400 border-leftNavColor cursor-pointer sm:w-auto">
                  <div className="flex items-center gap-x-2 text-sm">
                    <ArrowDownTrayIcon className="ml-2 w-5 h-5" /> CSV Format
                  </div>
                </a>
              </div>
              {/* <input type="text" value={fileName} readOnly className="cursor-not-allowed flex-1 min-w-0 block w-full px-3 py-1 h-10 rounded-r-md text-gray-500 border-gray-300 focus:ring-0 focus:border-highlightColor" /> */}
              <p className="cursor-not-allowed flex items-center flex-1 min-w-0 w-full px-3 py-1 h-10 font-light rounded-r-md text-gray-400 border-gray-300 focus:ring-0 focus:border-highlightColor">{fileName ? fileName : "No file chosen."}</p>
            </div>
          </div>
          <div className="mb-[1px]">
            <div>
              <Button
                disabled={fileName === ""}
                onClick={onSubmit}>
                Upload file
              </Button>
            </div>
          </div>
        </div>
      </div>
    </FormContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    creds: state.creds,
    site: state.site,
    groups: Object.values(state.groups),
  };
};

export default connect(mapStateToProps, { addBulkUser, getUsers, getUsersList })(BulkUpload);
