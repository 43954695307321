import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { deleteActivityData, getActivity } from "src/actions/activity";
import { getPages } from "src/actions/page";
import ActivityEntry from "src/components/Activity/ActivityEntry";
import DateSearch from "src/components/Activity/DateSearch";
import PaginationFooter from "src/components/Shared/PaginationFooter";
import ButtonExportContainer from "src/components/Shared/Buttons/ButtonExportContainer";
import ModalDefault from "src/components/Shared/Modals/ModalDefault";
import Table from "src/components/Shared/Table/Table";
import TRHeader from "src/components/Shared/Table/TRHeader";
import { utils, writeFile } from "xlsx";
import TableOptions from "src/components/Shared/TableOptions";
import MultiSelectObjectFilter from "src/components/Shared/Table/MultiSelectObjectFilter";
import SortOrder from "src/components/Shared/Table/SortOrder";
import { apiRequest } from "src/async/apiUtils";

const ActivityList = ({ pages, ...props }) => {
  const [activities, setActivities] = useState([]);
  const [count, setCount] = useState(0);
  const [sortDirection, setSortDirection] = useState("ASC");

  const [paginationLimit, setPaginationLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [paginationTabCount, setPaginationTabCount] = useState(1);
  const [start_date, setStateDate] = useState("");
  const [end_date, setEndDate] = useState("");

  const [users, setUsers] = useState([]);

  // Filters
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedActivityTypes, setSelectedActivityTypes] = useState([]);
  const [selectedPages, setSelectedPages] = useState([]);

  const [loaded, setLoaded] = useState(false);
  const [exportType, setExportType] = useState("xls");

  const [deleteId, setDeleteId] = useState(null);

  const handleSelectedItems = (itemsArray, setSelected) => {
    let updatedSelected = itemsArray.reduce((filteredItemsArray, item) => (item.selected ? [...filteredItemsArray, item.key] : filteredItemsArray), []);
    setSelected(updatedSelected);
    setOffset(0);
  };

  const loadActivityData = async (ac) => {
    setLoaded(false);
    try {
      const data = await props.getActivity(
        {
          limit: paginationLimit,
          offset: offset * paginationLimit,
          start_date: start_date,
          end_date: end_date,
          users: selectedUsers,
          pages: selectedPages,
          activity_types: selectedActivityTypes,
          sort_direction: sortDirection,
        },
        ac.signal
      );
      setCount(data.meta.count);
      setActivities(data.data);
      const availablePages = Math.ceil(data.meta.count / paginationLimit);
      setPaginationTabCount(availablePages);
      setLoaded(true);
    } catch (error) {
      setLoaded(true);
      console.dir(error.message);
    }
  };

  const handlePaginationChange = ({ limit, offset }) => {
    setPaginationLimit(limit);
    setOffset(offset);
  };

  const loadPages = async (ac) => {
    try {
      await props.getPages({ sort_direction: "ASC" }, ac.signal);
    } catch (error) {
      console.dir(error.message);
    }
  };

  const loadUsers = async () => {
    try {
      let data = await apiRequest("post", "/users/list", { body: { sort_direction: "ASC" } });
      setUsers(data.data.data);
    } catch (error) {
      console.dir(error.message);
    }
  };

  useEffect(() => {
    const ac = new AbortController();

    loadActivityData(ac);

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationLimit, offset, start_date, end_date, selectedUsers, selectedActivityTypes, selectedPages, sortDirection]);

  useEffect(() => {
    const ac = new AbortController();

    loadPages(ac);
    loadUsers(ac);

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeDel = async (e) => {
    e.preventDefault();

    try {
      const message = await props.deleteActivityData(deleteId);
      setDeleteId(null);
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const formatMemberDate = (date) => {
    if (!date) return "";

    return moment(new Date(date)).format("LLL");
  };

  const exportData = async () => {
    const params = {
      start_date: start_date,
      end_date: end_date,
      users: selectedUsers,
      pages: selectedPages,
      activity_types: selectedActivityTypes,
      sort_direction: sortDirection,
    };
    try {
      const res = await apiRequest("post", "/list-activity", { body: params }, (res) => res.data);
      let data = [];
      if (res.length > 0) {
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          data.push({
            Email: element?.user_id?.email,
            Name: element?.user_id?.name,
            "Activity Type": element?.type,
            "Page Accessed": element?.data?.page_id?.name,
            Date: formatMemberDate(element?.created_at),
          });
        }
      }
      const headings = [["Email", "Name", "Activity Type", "Page Accessed", "Date"]];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
      utils.book_append_sheet(wb, ws, "Report");
      if (exportType === "xls") {
        writeFile(wb, "Activity Report.xls");
      } else {
        writeFile(wb, "Activity Report.csv");
      }
    } catch (error) {
      console.dir(error.message);
    }
  };

  return (
    <>
      <TableOptions
        leftContent={
          <DateSearch
            setStateDate={setStateDate}
            setEndDate={setEndDate}
          />
        }
      />
      <Table
        tableHeader={
          <TRHeader>
            <th
              scope="col"
              className="rounded-l-xl py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              <div className="flex items-center gap-x-1">
                <p>Name</p>
                <div className="flex">
                  <MultiSelectObjectFilter
                    filterSelect={true}
                    defaultOptions={users?.map((user) => {
                      return { key: user._id, value: user.name, selected: selectedUsers.includes(user._id), object: user };
                    })}
                    searchableFields={["name", "email"]}
                    onChange={(e) => {
                      handleSelectedItems(e, setSelectedUsers);
                    }}
                  />
                </div>
              </div>
            </th>
            <th
              scope="col"
              className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              <div className="flex items-center gap-x-1">
                <p>Activity Type</p>
                <div className="flex">
                  <MultiSelectObjectFilter
                    filterSelect={true}
                    defaultOptions={[
                      { key: "PAGE_VIEW", value: "Page View" },
                      { key: "LOGIN", value: "Logins/Authentication" },
                    ].map((type) => {
                      return { key: type.key, value: type.value, selected: selectedActivityTypes.includes(type.key), object: { _id: type.key, ...type } };
                    })}
                    onChange={(e) => {
                      handleSelectedItems(e, setSelectedActivityTypes);
                    }}
                  />
                </div>
              </div>
            </th>
            <th
              scope="col"
              className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              <div className="flex items-center gap-x-1">
                <p>
                  {" "}
                  Page <span className="md:inline hidden">Accessed</span>
                </p>
                <div className="flex">
                  <MultiSelectObjectFilter
                    filterSelect={true}
                    defaultOptions={pages.map((page) => {
                      return { key: page._id, value: page.name, selected: selectedPages.includes(page._id), object: page };
                    })}
                    searchableFields={["name"]}
                    onChange={(e) => {
                      handleSelectedItems(e, setSelectedPages);
                    }}
                  />
                </div>
              </div>
            </th>
            <th
              scope="col"
              className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              <div className="flex items-center gap-x-1">
                <p>Date</p>
                <SortOrder
                  sortDirection={sortDirection}
                  setSortDirection={(value) => {
                    setSortDirection(value);
                    setOffset(0);
                  }}
                />
              </div>
            </th>
            <th
              scope="col"
              className="rounded-r-xl relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Edit</span>
            </th>
          </TRHeader>
        }
        colSpan="5"
        setDeleteId={setDeleteId}
        deleteId={deleteId}
        loaded={loaded}
        dataExists={activities.length > 0}>
        {activities.map((activity) => (
          <ActivityEntry
            key={activity._id}
            activity={activity}
            deleteId={deleteId}
            setDeleteId={setDeleteId}
            dataExists={activities.length > 0}
          />
        ))}
      </Table>
      {activities.length > 0 && (
        <PaginationFooter
          offset={offset}
          limit={paginationLimit}
          tabCount={paginationTabCount}
          totalEntriesCount={count}
          itemName="Activity records"
          loaded={loaded}
          onChange={handlePaginationChange}
        />
      )}
      {activities.length > 0 && (
        <ButtonExportContainer
          exportType={exportType}
          setExportType={setExportType}
          onSubmit={exportData}
        />
      )}
      <ModalDefault
        isOpen={!!deleteId}
        closeModal={() => setDeleteId(null)}
        onSubmit={removeDel}
        submitText="Delete"
        cancelText="Cancel"
        title="Activity"
        secondaryTitle="Delete">
        <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete?</div>
      </ModalDefault>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    pages: Object.values(state.pages),
  };
};
export default connect(mapStateToProps, {
  getActivity,
  deleteActivityData,
  getPages,
})(ActivityList);
