import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useEffect, useRef, useState } from "react";
import { classNames } from "src/helpers/classNames";
import useOnClickOutside from "src/hooks/useOnClickOutside";

const MultiSelect = ({ options = [], searchable = true, placeholder = "Search", title, error = false, selectedOptions = [], setSelectedOptions = () => {} }) => {
  // const [selectedOptions, setSelectedOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState(options);

  const [panelOpen, setPanelOpen] = useState(false);
  const [keyword, setKeyword] = useState("");

  const ref = useRef();

  useOnClickOutside(ref, () => setPanelOpen(false));

  const updateSelected = (option) => {
    let updatedOptions;
    if (selectedOptions.find((selectedOption) => selectedOption === option)) {
      updatedOptions = selectedOptions.filter((selectedOption) => selectedOption !== option);
    } else {
      updatedOptions = [...selectedOptions, option];
    }
    setSelectedOptions(updatedOptions);
  };

  useEffect(() => {
    if (searchable && keyword) {
      let updatedFilteredOptions = options.filter((option) => option.searchableFields.toLowerCase().includes(keyword.toLowerCase()));
      setFilteredOptions(updatedFilteredOptions);
    }
  }, [keyword, options, searchable]);

  return (
    <div className="relative">
      <div className={classNames("flex justify-between text-sm font-medium text-gray-700 mb-1", error ? "text-red-600" : "")}>
        {title} <div className="pl-2">{error ? "*Required" : ""}</div>
      </div>
      <div className="h-10 relative rounded-md shadow-sm w-full flex" onClick={() => setPanelOpen(!panelOpen)} ref={ref}>
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" aria-hidden="true">
          <MagnifyingGlassIcon className="mr-3 h-4 text-gray-400" aria-hidden="true" />
        </div>
        <input type="text" name="search" id="search" value={keyword} className="block w-full pl-9 border-gray-300 text-sm rounded-md focus:ring-0 focus:border-highlightColor" placeholder={placeholder} onChange={(e) => setKeyword(e.target.value)} />
      </div>
      <div className={classNames("bg-white py-2 max-h-48 overflow-auto min-w-full shadow z-30 absolute top-[60px] rounded-md", panelOpen ? "flex flex-col" : "hidden")}>
        {filteredOptions.map((option) => {
          return (
            <div
              key={option._id || option.id}
              className="cursor-pointer w-full flex items-center px-4 py-2 gap-x-3 border-white hover:bg-gray-100"
              onClick={() => {
                updateSelected(option._id || option.id);
              }}
            >
              <input type="checkbox" checked={selectedOptions.includes(option._id || option.id)} className="cursor-pointer rounded text-highlightColor focus:ring-highlightColor" readOnly />
              <div className="">{option.name}</div>
            </div>
          );
        })}
      </div>
      <div className="mt-3 w-full flex justify-between">
        <div className="flex gap-2 flex-wrap">
          {selectedOptions.length ? (
            options
              .filter((option) => selectedOptions.includes(option._id || option.id))
              .map((selectedOption) => (
                <div className="flex items-center rounded-full px-[2px] pl-[8px] py-[2px] bg-highlightColor text-white text-xs h-6" key={(selectedOption._id || selectedOption.id) + "selected"}>
                  {selectedOption.name}
                  <div onClick={() => updateSelected(selectedOption._id || selectedOption.id)} className="rounded-full p-1 ml-1">
                    <XMarkIcon className="h-4 w-4 text-white" />
                  </div>
                </div>
              ))
          ) : (
            <div className="h-6 flex items-center">None selected</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MultiSelect;
