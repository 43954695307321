import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { getOperators } from "src/actions/operator";
import { getPages } from "src/actions/page";
import { manageSiteSettings } from "src/actions/site";
import AddPermissionForm from "src/components/Permissions/AddPermissionForm";
import Button from "src/components/Shared/Buttons/Button";
import MultiSelectObject from "src/components/Shared/Forms/Selects/MultiSelectObject";
import { operatorNameConverter } from "src/helpers/operatorNameConverter";

const SSOServiceAttribute = ({ site, manageSiteSettings, pages, operators, ...props }) => {
  const [sso_role, setRole] = useState("");
  // const [sso_value, setRoleValue] = useState({ _id: -1, name: "None selected" });
  const [sso_trusted_attribute, setSsoTrustedAttribute] = useState("");
  // const [sso_trusted_attribute_alt, setSsoTrustedAttributeAlt] = useState({ _id: -1, name: "None selected" });
  const [sso_trusted_attribute_value, setSsoTrustedAttributeValue] = useState("");
  const [trusted_attribute, setTrustedAttribute] = useState("");
  const [selectedGroups, setSelectedGroups] = useState([]);

  const [permissionFormCount, setPermissionFormCount] = useState([0]);
  const [permissionState, setPermissionState] = useState({});
  const trustedAttributes = ["user.firstName", "user.lastName", "user.email", "user.username", "user.company", "user.department"];
  const trustedAttributeArr = trustedAttributes.map((trustedAttribute) => {
    return {
      _id: trustedAttribute,
      value: trustedAttribute,
      name: trustedAttribute,
      searchableFields: "name",
    };
  });

  // Load pages
  useEffect(() => {
    const ac = new AbortController();

    const loadPages = async () => {
      try {
        props.getPages({}, ac.signal);
      } catch (error) {
        console.dir(error.message);
      }
    };

    loadPages();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Load operators
  useEffect(() => {
    const ac = new AbortController();

    const loadOperators = async () => {
      try {
        props.getOperators({}, ac.signal);
      } catch (error) {
        // console.dir(error.message);
      }
    };

    loadOperators();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (site?.permissions && site?.permissions.length) {
      const finalPermissions = {};
      const updatedStateCount = [];
      site?.permissions.forEach((permission, i) => {
        const operatorIndex = operators.findIndex((item) => item._id === permission?.operator_id);
        const trustedIndex = trustedAttributeArr.findIndex((item) => item._id === permission?.trusted_attribute);
        if (finalPermissions[permission?.page_id]) {
          finalPermissions[permission?.page_id].filters.push({
            operator: { ...operators[operatorIndex], name: operatorNameConverter(operators[operatorIndex]?.name) },
            operator_id: permission?.operator_id,
            trustedAttribute: { ...trustedAttributeArr[trustedIndex] },
            trusted_attribute: permission?.trusted_attribute,
            column_name: permission?.column_name,
            column_value: permission?.column_value,
          });
        } else {
          let permisn = {
            page_id: permission?.page_id,
            filters: [
              {
                operator: { ...operators[operatorIndex], name: operatorNameConverter(operators[operatorIndex]?.name) },
                operator_id: permission?.operator_id,
                trustedAttribute: { ...trustedAttributeArr[trustedIndex] },
                trusted_attribute: permission?.trusted_attribute,
                column_name: permission?.column_name,
                column_value: permission?.column_value,
              },
            ],
          };
          finalPermissions[permission?.page_id] = permisn;
        }
      });

      let permission = {};
      Object.values(finalPermissions).forEach((permiss, i) => {
        permission[i] = permiss;
        updatedStateCount.push(i);
        return true;
      });

      setPermissionState(permission);
      setPermissionFormCount(updatedStateCount);
    } else if (site?.permissions && !site?.permissions.length) {
      setPermissionState({});
      setPermissionFormCount([0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site?.permissions]);

  useEffect(() => {
    // if (site?.sso_role) {
    //   let isUser = site?.sso_role === "user";
    //   setRoleValue({
    //     _id: isUser ? 0 : 1,
    //     value: isUser ? "user" : "admin",
    //     name: isUser ? "User" : "Admin",
    //   });
    // }
    setRole(site?.sso_role);
  }, [site?.sso_role]);

  useEffect(() => {
    // if (site?.sso_trusted_attribute.includes("user.")) {
    //   setSsoTrustedAttributeAlt({
    //     _id: site?.sso_trusted_attribute,
    //     value: site?.sso_trusted_attribute,
    //     name: site?.sso_trusted_attribute,
    //   });
    // }
    setSsoTrustedAttribute(site?.sso_trusted_attribute);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site?.sso_trusted_attribute]);

  useEffect(() => {
    setSsoTrustedAttributeValue(site?.sso_trusted_attribute_value);
  }, [site?.sso_trusted_attribute_value]);

  useEffect(() => {
    setTrustedAttribute(site?.trusted_attribute);
  }, [site?.trusted_attribute]);

  useEffect(() => {
    if (site?.groups && site?.groups.length > 0) {
      setSelectedGroups(site?.groups);
    }
  }, [site?.groups]);

  const addNewPermission = (e) => {
    e.preventDefault();
    if (permissionFormCount.length > 0) {
      setPermissionFormCount([...permissionFormCount, permissionFormCount.slice(-1)[0] + 1]);
    } else {
      setPermissionFormCount([0]);
    }
  };

  const handleSelectedGroups = (groups) => {
    let filteredGroups = groups.filter((group) => group.selected);
    setSelectedGroups(filteredGroups.map((group) => group.key));
  };

  const updateSSORole = async () => {
    try {
      let permissions = [];
      let orderValue = 1;
      Object.values(permissionState).map((pe) => {
        if (pe.filters) {
          pe.filters.map((flt) => {
            permissions.push({
              dashboard_id: pe.dashboard_id,
              operator_id: flt.operator_id,
              column_name: flt.column_name,
              column_value: flt.column_value,
              trusted_attribute: flt.trusted_attribute,
              ordering: orderValue,
            });
            orderValue++;
            return true;
          });
        } else {
          permissions.push({
            dashboard_id: pe.dashboard_id,
            operator_id: null,
            column_name: "",
            column_value: "",
            trusted_attribute: "",
            ordering: orderValue,
          });
          orderValue++;
          return true;
        }
        return true;
      });

      const message = await manageSiteSettings({
        sso_role: sso_role,
        groups: selectedGroups,
        trusted_attribute: trusted_attribute,
        sso_trusted_attribute: sso_trusted_attribute,
        sso_trusted_attribute_value: sso_trusted_attribute_value,
        permissions: permissions,
      });
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(Object.values(permissionState), result.source.index, result.destination.index);
    setPermissionState({ ...items });
  };

  return (
    <>
      <div className="shadow bg-white rounded-md mt-4 p-6 flex flex-col gap-y-3">
        <h5 className="text-base font-medium mb-4">Map Attributes</h5>
        <div className="w-full h-[1px] bg-gray-200" />
        <div className="w-full flex flex-col gap-y-5">
          {/* <div className="pl-7 flex flex-wrap items-end space-x-3 py-5">
            <div className="relative py-2.5 text-sm font-medium text-gray-700">Assign as</div>
            <div className="relative w-36">
              <Select
                label="Role"
                options={[
                  {
                    _id: 0,
                    value: "user",
                    name: "User",
                  },
                  {
                    _id: 1,
                    value: "admin",
                    name: "Admin",
                  },
                ]}
                selected={sso_value}
                setSelected={(e) => {
                  setRole(e.value);
                  setRoleValue(e);
                }}
              />
            </div>
            <div className="relative py-2.5 text-sm font-medium text-gray-700">when</div>
            <div className="relative w-48">
              <Select
                label="Trusted attribute"
                options={trustedAttributeArr}
                selected={sso_trusted_attribute_alt}
                setSelected={(e) => {
                  setSsoTrustedAttribute(e.value);
                  setSsoTrustedAttributeAlt(e);
                }}
              />
            </div>
            <div className="relative py-2.5 text-sm font-medium text-gray-700">equals</div>
            <div className="relative w-48">
              <div className="grow">
                <Input
                  name="sso_trusted_attribute_value"
                  label={""}
                  value={sso_trusted_attribute_value}
                  onChange={(e) => {
                    setSsoTrustedAttributeValue(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="w-full h-[1px] bg-gray-200" /> */}
          <div className="pl-5 grid grid-cols-1 gap-4 w-full py-5">
            {permissionFormCount.length > 0 ? (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}>
                      {permissionFormCount.map((count, i) => {
                        return (
                          <Draggable
                            key={i}
                            draggableId={`${i}`}
                            index={i}>
                            {(provided, snapshot) => (
                              <AddPermissionForm
                                innerRef={provided.innerRef}
                                draggableProps={provided.draggableProps}
                                dragHandleProps={provided.dragHandleProps}
                                key={i}
                                index={count}
                                pages={pages}
                                operators={operators?.map((operator) => {
                                  return { ...operator, name: operatorNameConverter(operator.name), id: operator._id };
                                })}
                                usesTrustedValue={true}
                                formCount={permissionFormCount}
                                setFormCount={setPermissionFormCount}
                                state={permissionState}
                                setState={setPermissionState}
                              />
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <span className="text-gray-400">No permissions</span>
            )}
            <div className="flex justify-end sm:px-14">
              <button className="h-full inline-flex items-center justify-center rounded-md border px-4 py-2 text-regular font-medium focus:ring-2 focus:border-highlightColor bg-white text-gray-500 border-gray-400 hover:shadow-sm transition-all duration-75 sm:w-auto">
                <div
                  className="flex items-center gap-x-2"
                  onClick={addNewPermission}>
                  Add page
                </div>
              </button>
            </div>
          </div>
          <div className="w-full h-[1px] bg-gray-200" />
          <div className="grid grid-cols-4 gap-4 py-5">
            <div className="pl-4 col-span-4 sm:col-span-3 lg:col-span-2">
              <MultiSelectObject
                defaultOptions={trustedAttributeArr.map((trustedAttribute) => {
                  return { key: trustedAttribute._id, value: trustedAttribute.name, selected: site.groups?.includes(trustedAttribute.name), object: trustedAttribute };
                })}
                searchableFields={["name"]}
                title="Assign groups"
                onChange={handleSelectedGroups}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end items-center w-full pt-6 space-x-4">
        <Button styleType="gray">Cancel</Button>
        <Button onClick={updateSSORole}>Save</Button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    pages: state.pages,
    operators: Object.values(state.operators),
  };
};
export default connect(mapStateToProps, { manageSiteSettings, getPages, getOperators })(SSOServiceAttribute);
