import React from "react";
import { CheckIcon } from "@heroicons/react/24/solid";
import { classNames } from "src/helpers/classNames";

const Checkbox = ({ checkboxName, checkboxInputClass, isChecked, disabled = false, checkboxLabel, checkboxLableClass, onChange = () => {} }) => {
  return (
    <>
      <div className="relative overflow-hidden flex items-center gap-3">
        <input
          type="checkbox"
          disabled={disabled}
          name={checkboxName}
          className={classNames("peer absolute top-0 left-0 w-5 h-5 min-w-[20px] min-h-[20px] opacity-0 z-10 cursor-pointer " + checkboxInputClass, disabled ? "bg-gray-300 cursor-not-allowed" : "")}
          checked={isChecked && !disabled}
          onChange={onChange}
        />
        <div
          className={classNames(
            "w-5 h-5 min-w-[20px] min-h-[20px] border border-slate-300 rounded flex items-center justify-center text-sm bg-white text-black/0 peer-checked:bg-slate-900 peer-checked:border-slate-900 peer-checked:text-white",
            disabled ? "bg-gray-100 cursor-not-allowed" : ""
          )}>
          <CheckIcon className="w-5 h-5" />
        </div>
        {checkboxLabel && <div className={"text-sm text-slate-500 w-full " + checkboxLableClass}>{checkboxLabel}</div>}
      </div>
    </>
  );
};

export default Checkbox;
