import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { baseUrl, noFavicon, exampleFavi, noLogo, apiUrl } from "src/config/host";
import { manageWorkspaceSettings, removeWorkspaceImages } from "src/actions/workspace";
import { XMarkIcon } from "@heroicons/react/24/solid";
import Button from "src/components/Shared/Buttons/Button";
import { convertToBase64 } from "src/helpers/image";
import SitePreview from "src/components/Site/SitePreview";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { getAuthorizedUser } from "src/actions/auth";
import { useParams } from "react-router";
import { fetchData } from "src/async/fetch";

const WorkspaceStyles = ({ user, getAuthorizedUser, manageWorkspaceSettings, removeWorkspaceImages }) => {
  const [imageLogo, setImageLogo] = useState("");
  const [imageFavicon, setImageFavicon] = useState("");
  const [headerColor, setHeaderColor] = useState("#000000");
  const [headerTextColor, setHeaderTextColor] = useState("#ffffff");
  const [leftNavColor, setLeftNavColor] = useState("#ffffff");
  const [leftNavTextColor, setLeftNavTextColor] = useState("#000000");
  const [leftNavActiveColor, setLeftNavActiveColor] = useState("#000000");
  const [highlightColor, setHighlightColor] = useState("#ffffff");
  const [leftNavActiveTextColor, setLeftNavActiveTextColor] = useState("#ffffff");
  const allowedExts = ["jpg", "jpeg", "png", "bmp", "gif", "svg", "webp"];

  const [workspace, setWorkspace] = useState({});

  const { id } = useParams();

  useEffect(() => {
    if (workspace?._id) {
      setImageLogo(workspace.image_logo || noLogo);
      setImageFavicon(workspace.image_favicon || noFavicon);
      setHeaderColor(workspace.top_bar_color || "#ffffff");
      setHeaderTextColor(workspace.top_bar_text_color || "#ffffff");
      setLeftNavColor(workspace.side_bar_color || "#ffffff");
      setLeftNavTextColor(workspace.side_bar_text_color || "#ffffff");
      setLeftNavActiveColor(workspace.side_bar_shade_color || "#ffffff");
      setHighlightColor(workspace.highlight_color || "#ffffff");
      setLeftNavActiveTextColor(workspace?.left_nav_active_text_color || "#ffffff");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace]);

  const loadData = async (ac = {}) => {
    try {
      let workspaceData = {};

      try {
        const workspaceResponse = await fetchData("GET", `${apiUrl}/workspaces/${id}`);
        workspaceData = await workspaceResponse.json();

        if (workspaceData.status === 200) setWorkspace(workspaceData.data);
        else {
          throw new Error(workspaceData.message);
        }
      } catch (error) {
        throw new Error(error.message);
      }
    } catch (error) {
      console.dir(error.message);
    }
  };

  useEffect(() => {
    if (id) {
      const ac = new AbortController();
      loadData(ac);
      return () => ac.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const setUser = async (id) => {
    const ac = new AbortController();
    await getAuthorizedUser(ac.signal);
  };

  useEffect(() => {
    setUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFaviconChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts, "ico", "x-icon"].includes(ext)) {
        toast.error("Please upload a valid image.");
      } else {
        convertToBase64(e.target.files[0], setImageFavicon);
      }
    }
  };

  const onLogoChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (!allowedExts.includes(ext)) {
        toast.error("Please upload a valid image.");
      } else {
        convertToBase64(e.target.files[0], setImageLogo);
      }
    }
  };

  const onClear = () => {
    setImageLogo(workspace.image_logo || noLogo);
    setImageFavicon(workspace.image_favicon || noFavicon);
    setHeaderColor(workspace.top_bar_color || "#ffffff");
    setHeaderTextColor(workspace.top_bar_text_color || "#ffffff");
    setLeftNavColor(workspace.side_bar_color || "#ffffff");
    setLeftNavTextColor(workspace.side_bar_text_color || "#ffffff");
    setLeftNavActiveColor(workspace.side_bar_shade_color || "#ffffff");
    setHighlightColor(workspace.highlight_color || "#ffffff");
    setLeftNavActiveTextColor(workspace?.left_nav_active_text_color || "#ffffff");
  };

  const onSubmit = async () => {
    try {
      const message = await manageWorkspaceSettings({
        workspace_id: workspace?._id,
        image_logo: imageLogo,
        image_favicon: imageFavicon,
        top_bar_color: headerColor,
        top_bar_text_color: headerTextColor,
        side_bar_color: leftNavColor,
        side_bar_text_color: leftNavTextColor,
        side_bar_shade_color: leftNavActiveColor,
        left_nav_active_text_color: leftNavActiveTextColor,
        highlight_color: highlightColor,
        sso_button_color: workspace.sso_button_color,
        sso_button_text_color: workspace.sso_button_text_color,
      });

      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onImageDelete = async (body) => {
    try {
      const message = await removeWorkspaceImages(body);
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="grid space-y-8">
        <div className="bg-white shadow-md p-6 rounded-md flex flex-wrap justify-between w-full">
          <div className="w-full flex flex-wrap">
            <div className="w-full sm:w-8/12">
              <SitePreview
                layoutType={workspace.layout_type}
                menu={["Home", "About Us", "Feedback"]}
                site={workspace}
                logo={imageLogo}
                favicon={imageFavicon}
                headerColor={headerColor}
                headerTextColor={headerTextColor}
                leftNavColor={leftNavColor}
                highlightColor={highlightColor}
                leftNavTextColor={leftNavTextColor}
                leftNavActiveColor={leftNavActiveColor}
                leftNavActiveTextColor={leftNavActiveTextColor}
                setHeaderColor={setHeaderColor}
                setHeaderTextColor={setHeaderTextColor}
                setLeftNavTextColor={setLeftNavTextColor}
                setLeftNavColor={setLeftNavColor}
                setHighlightColor={setHighlightColor}
                setLeftNavActiveColor={setLeftNavActiveColor}
                setLeftNavActiveTextColor={setLeftNavActiveTextColor}
              />
            </div>
            <div className="w-full sm:w-4/12 sm:pl-10">
              <div className="relative w-full mt-6">
                <div className="text-base uppercase text-black font-semibold">Workspace Logo</div>
                <div
                  className="group w-60 relative h-60  border border-gray-200 p-4 rounded-md"
                  style={{ backgroundColor: headerColor }}>
                  {workspace._id && (
                    <img
                      src={imageLogo.includes(".s3.") ? imageLogo : imageLogo?.includes("data:image/") ? imageLogo : baseUrl + imageLogo}
                      alt="Logo"
                      className="w-full h-full object-contain"
                      onError={(e) => (e.target.src = `${noLogo}`)}
                    />
                  )}
                  {workspace.image_logo && authorizeUserComponentAccess(user, id, "layout", ["delete"]) && (
                    <div
                      className="group-hover:flex cursor-pointer hidden bg-gray-200 hover:bg-gray-300 absolute h-8 w-8 rounded-full text-center -top-3 -right-3 z-30 justify-center items-center transition-all"
                      onClick={() => onImageDelete({ imageType: "logo", workspace_id: workspace._id })}>
                      <XMarkIcon className="h-5 w-5 text-gray-600" />
                    </div>
                  )}
                </div>
                {authorizeUserComponentAccess(user, id, "layout", ["update"]) && (
                  <div className="relative w-full mt-3 overflow-hidden">
                    <label htmlFor="change-logo">
                      <div className="h-full inline-flex items-center justify-center rounded-md border px-4 py-2 text-regular font-medium shadow-sm focus:outline-none text-white bg-highlightColor cursor-pointer">Change Logo</div>
                    </label>
                    <input
                      type="file"
                      id="change-logo"
                      className="hidden"
                      onChange={onLogoChange}
                    />
                  </div>
                )}
              </div>
              <div className="relative w-full mt-12">
                <div className="text-base uppercase text-black font-semibold">Favicon</div>
                <div className="group w-32 relative h-32 border border-gray-200 bg-gray-100 p-4 rounded-md">
                  {workspace._id && (
                    <img
                      src={imageFavicon.includes(".s3.") ? imageFavicon : imageFavicon?.includes("data:image/") ? imageFavicon : baseUrl + imageFavicon}
                      alt="Logo"
                      className="w-full h-full object-contain"
                      onError={(e) => (e.target.src = `${exampleFavi}`)}
                    />
                  )}
                  {workspace.image_favicon && authorizeUserComponentAccess(user, id, "layout", ["update"]) && (
                    <div
                      className="group-hover:flex cursor-pointer hidden bg-gray-200 hover:bg-gray-300 absolute h-8 w-8 rounded-full text-center -top-3 -right-3 z-30 justify-center items-center transition-all"
                      onClick={() => onImageDelete({ imageType: "favicon", workspace_id: workspace._id })}>
                      <XMarkIcon className="h-5 w-5 text-gray-600" />
                    </div>
                  )}
                </div>
                {authorizeUserComponentAccess(user, id, "layout", ["update"]) && (
                  <div className="relative w-full mt-3 overflow-hidden">
                    <label htmlFor="change-fav">
                      <div className="h-full inline-flex items-center justify-center rounded-md border px-4 py-2 text-regular font-medium shadow-sm focus:outline-none text-white bg-highlightColor cursor-pointer">Change Favicon</div>
                    </label>
                    <input
                      type="file"
                      id="change-fav"
                      className="hidden"
                      onChange={onFaviconChange}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3 w-full flex justify-end gap-x-4 pb-20">
        <Button
          styleType="gray"
          onClick={onClear}>
          Undo changes
        </Button>
        {authorizeUserComponentAccess(user, id, "layout", ["update"]) && <Button onClick={onSubmit}>Update</Button>}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {
  manageWorkspaceSettings,
  removeWorkspaceImages,
  getAuthorizedUser,
})(WorkspaceStyles);
