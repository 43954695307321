import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { managePageData, removePage, setPageData } from "src/actions/page";
import PageEntry from "src/components/Pages/PageEntry";
import Input from "src/components/Shared/Forms/Inputs/Input";
import TextArea from "src/components/Shared/Forms/Inputs/TextArea";
import ModalDefault from "src/components/Shared/Modals/ModalDefault";
import Table from "src/components/Shared/Table/Table";
import TRHeader from "src/components/Shared/Table/TRHeader";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { useLoadData } from "src/hooks/useLoadData";
import { validate as uuidValidate } from "uuid";
import Dropdown from "src/components/Shared/Buttons/Dropdown";
import TableOptions from "src/components/Shared/TableOptions";
import CreateDomoPageContent from "src/components/Pages/PageModelContent/CreateDomoPageContent";
import CreateLandingPageContent from "src/components/Pages/PageModelContent/LandingPages/CreateLandingPageContent";
import Search from "src/components/Shared/Search";
import PaginationFooter from "src/components/Shared/PaginationFooter";

const PageList = ({ workspaceId = "", ...props }) => {
  const navigate = useNavigate();
  const [createModal, setCreateModal] = useState(false);
  const [advancedFeatures, setAdvancedFeatures] = useState(false);
  const [startIndex, setStartIndex] = useState(-1);
  const [deleteId, setDeleteId] = useState(null);
  const [filteredPages, setFilteredPages] = useState([]);

  const [keyword, setKeyword] = useState("");
  const [workspaceFilter] = useState("");

  const [pages, setPages] = useState([]);
  const [loadedPages, setLoadedPages] = useState(false);

  const jsonArr = {
    _id: "",
    name: "",
    page_type: "DOMO",
    embed_type: "EMBED_PAGE",
    credential_id: null,
    embed_id: "",
    custom_html: "",
    workspace_id: workspaceId || null,
    navigation_blocks: [
      {
        title: "",
        image: "",
        height: "",
        width: "",
        links: [
          {
            link_text: "",
            page_id: null,
          },
        ],
      },
    ],
  };

  const [newPage, setNewPage] = useState(jsonArr);

  const [editPageCredentialOptions, setEditPageCredentialOptions] = useState([]);
  const [pageCredentialOptions, setPageCredentialOptions] = useState([]);
  const [navigationPageList, setNavigationPageList] = useState([]);

  // Pagination
  const [totalEntriesCount, setTotalEntriesCount] = useState(0);
  const [paginationLimit, setPaginationLimit] = useState(10);
  const [paginationOffset, setPaginationOffset] = useState(0);
  const [paginationTabCount, setPaginationTabCount] = useState(1);

  const handlePaginationChange = ({ limit, offset }) => {
    setPaginationLimit(limit);
    setPaginationOffset(offset);
    setLoadedPages(false);
  };

  useLoadData(
    workspaceId ? "get" : "post",
    workspaceId ? `/workspaces/${workspaceId}/pages` : "/pages/list",
    { body: { limit: paginationLimit, offset: paginationOffset } },
    (res) => {
      setPages(res.data);
      if (res.meta?.count) {
        setTotalEntriesCount(res.meta.count);
        setPaginationTabCount(Math.ceil(res.meta.count / paginationLimit));
      }
    },
    loadedPages,
    setLoadedPages
  );
  useLoadData("get", `/pages/credentials`, {}, (res) => setEditPageCredentialOptions(res.data));

  useEffect(() => {
    if (pages?.length > 0) {
      let filtered = pages.filter((page) => (page?.name.toLowerCase() + " " + page?.page_type.toLowerCase() + " " + page?.embed_type.toLowerCase()).includes(keyword?.toLowerCase()));
      if (workspaceFilter) {
        filtered = filtered.filter((page) => {
          return page?.workspace_id._id === workspaceFilter;
        });
      }
      filtered.sort(function (a, b) {
        return a.workspace_id?.name.localeCompare(b.workspace_id?.name);
      });
      setFilteredPages(filtered);
      let navigationPage = [];
      // eslint-disable-next-line array-callback-return
      pages.map((page) => {
        if (page.page_type !== "LANDING_PAGE") {
          navigationPage.push({ value: page._id, key: page.name });
        }
      });
      setNavigationPageList(navigationPage);
    }
  }, [keyword, workspaceFilter, pages]);

  const createNewPage = async () => {
    try {
      let page = {
        workspace_id: newPage.workspace_id,
        credential_id: newPage.credential_id,
        page_type: newPage.page_type,
        custom_html: newPage.custom_html,
        created_at: "",
        created_by: props.user._id,
        deleted_at: null,
        deleted_by: null,
        embed_id: newPage.embed_id,
        embed_type: newPage.embed_type,
        name: newPage.name,
        navigation_blocks: newPage.navigation_blocks,
        updated_at: "",
        updated_by: props.user._id,
        _id: newPage._id || null,
      };

      const { message, data } = await props.managePageData({
        pages: [page],
        workspace_id: workspaceId || null,
      });
      setPages(data);
      setCreateModal(false);
      setAdvancedFeatures(false);
      toast.success(message);
      setNewPage({
        _id: "",
        name: "",
        page_type: "DOMO",
        embed_type: "EMBED_CARD",
        credential_id: null,
        embed_id: "",
        custom_html: "",
        workspace_id: workspaceId || null,
        navigation_blocks: [
          {
            title: "",
            image: "",
            height: "",
            width: "",
            links: [
              {
                link_text: "",
                page_id: null,
              },
            ],
          },
        ],
      });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const deletePage = async () => {
    if (deleteId && !uuidValidate(deleteId)) {
      try {
        await props.removePage(deleteId);
        setDeleteId(null);
        setPages(pages.filter((page) => page._id.toString() !== deleteId));
      } catch (error) {
        console.dir(error);
      }
    }
  };

  const editPage = async (page) => {
    setNewPage(page);
    setCreateModal(true);
  };

  const addPage = async (pageType) => {
    setNewPage({ ...jsonArr, page_type: pageType });
    setCreateModal(true);
  };

  const pagePrev = async (page) => {
    navigate("/workspace/" + page._id + "/previous");
  };

  useEffect(() => {
    if (newPage?.workspace_id?._id) {
      let creds = [];
      // eslint-disable-next-line array-callback-return
      editPageCredentialOptions.map((cred) => {
        if (newPage?.workspace_id?._id === cred.workspace_id) {
          creds.push({ value: cred._id, key: cred.name });
        }
      });
      setPageCredentialOptions(creds);
    } else {
      let creds = [];
      // eslint-disable-next-line array-callback-return
      editPageCredentialOptions.map((cred) => {
        if (workspaceId === cred.workspace_id) {
          creds.push({ value: cred._id, key: cred.name });
        }
      });
      setPageCredentialOptions(creds);
    }
  }, [editPageCredentialOptions, newPage?.workspace_id?._id, workspaceId]);

  useEffect(() => {
    setStartIndex(pageCredentialOptions.findIndex((pageCredentialOption) => pageCredentialOption.value === newPage.credential_id));
  }, [pageCredentialOptions, newPage]);

  return (
    <>
      <TableOptions
        leftContent={
          <Search
            keyword={keyword}
            setKeyword={setKeyword}
          />
        }
        rightContent={
          workspaceId &&
          authorizeUserComponentAccess(props.user, workspaceId, "page", ["create"]) && (
            <div className="flex items-center justify-between relative z-10">
              <div className="relative flex min-w-[140px] items-center justify-end gap-x-1 ml-auto">
                <Dropdown addPage={addPage} />
              </div>
            </div>
          )
        }></TableOptions>
      <Table
        tableHeader={
          <TRHeader>
            <th
              scope="col"
              className="rounded-l-xl overflow-hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Page Name
            </th>
            <th
              scope="col"
              className="hidden sm:table-cell overflow-hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Type
            </th>
            <th
              scope="col"
              className="rounded-r-xl overflow-hidden  hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
          </TRHeader>
        }
        colSpan="5"
        loaded={loadedPages}
        dataExists={filteredPages?.length > 0}>
        {filteredPages?.length > 0 &&
          filteredPages.map((page, i) => {
            return (
              <Fragment key={page._id}>
                {!workspaceId && page?.workspace_id?.name !== filteredPages[i - 1]?.workspace_id?.name && (
                  <tr>
                    <td
                      colSpan={3}
                      className="break-all py-4 px-4 sm:pl-6 sm:py-3 text-sm font-semibold text-gray-500 whitespace-pre-wrap bg-gray-100">
                      {page?.workspace_id?.name}
                    </td>
                  </tr>
                )}
                <PageEntry
                  pageCredentialOptions={editPageCredentialOptions}
                  workspaceId={page.workspace_id?._id || page.workspace_id}
                  key={page._id}
                  page={page}
                  deleteId={deleteId}
                  setDeleteId={setDeleteId}
                  reloadPagesData={() => setLoadedPages(false)}
                  editPage={editPage}
                  pagePrev={pagePrev}
                />
              </Fragment>
            );
          })}
      </Table>

      <PaginationFooter
        itemName={"Pages"}
        totalEntriesCount={totalEntriesCount}
        loaded={loadedPages}
        limit={paginationLimit}
        offset={paginationOffset}
        tabCount={paginationTabCount}
        onChange={handlePaginationChange}
      />

      <ModalDefault
        isOpen={!!deleteId}
        closeModal={() => setDeleteId(null)}
        onSubmit={deletePage}
        submitText="Delete"
        cancelText="Cancel"
        title="Page"
        secondaryTitle="Delete">
        <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete {pages && pages.find((item) => item._id === deleteId)?.name}?</div>
      </ModalDefault>
      <ModalDefault
        isOpen={createModal}
        closeModal={() => setCreateModal(false)}
        onSubmit={createNewPage}
        submitText="Submit"
        cancelText="Cancel"
        title="Page"
        secondaryTitle="Create"
        maxWidth="w-[800px]">
        <div className="grid gap-y-8 whitespace-nowrap px-2 text-sm text-gray-500">
          <div className="mb-6 grid gap-y-3 px-4">
            <div className="my-5 grid space-y-2">
              <Input
                type="text"
                name="page-name"
                label="Name"
                value={newPage.name}
                onChange={(e) => setNewPage({ ...newPage, name: e.target.value })}
              />
            </div>
            <div className="relative">
              {newPage.page_type === "CUSTOM_HTML" && (
                <TextArea
                  label="Custom HTML"
                  value={newPage.custom_html}
                  onChange={(e) => setNewPage({ ...newPage, custom_html: e.target.value })}
                  placeholder="<iframe> ... </iframe>"
                  textType="code"
                />
              )}

              {newPage.page_type === "DOMO" && (
                <CreateDomoPageContent
                  newPage={newPage}
                  setNewPage={setNewPage}
                  startIndex={startIndex}
                  pageCredentialOptions={pageCredentialOptions}
                  advancedFeatures={advancedFeatures}
                  setAdvancedFeatures={setAdvancedFeatures}
                />
              )}

              {newPage.page_type === "LANDING_PAGE" && (
                <CreateLandingPageContent
                  navigationPageList={navigationPageList}
                  newPage={newPage}
                  setNewPage={setNewPage}
                />
              )}
            </div>
          </div>
        </div>
      </ModalDefault>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {
  managePageData,
  removePage,
  setPageData,
})(PageList);
