// import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import NavigationBlock from "src/components/Pages/PageModelContent/LandingPages/NavigationBlock";
import Button from "src/components/Shared/Buttons/Button";

const CreateLandingPageContent = ({ navigationPageList = [], newPage = {}, setNewPage = () => {} }) => {
  return (
    <>
      <div className="relative w-full">
        <div className="mb-2 text-lg font-semibold text-gray-700">Navigation Blocks</div>
        {newPage.navigation_blocks.map((navigation, index) => {
          return (
            <NavigationBlock
              key={index + "-" + navigation.name}
              navigation={navigation}
              index={index}
              navigationPageList={navigationPageList}
              newPage={newPage}
              setNewPage={setNewPage}
            />
          );
        })}
      </div>

      <div className="relative w-full mb-4">
        <Button
          onClick={() => {
            setNewPage({
              ...newPage,
              navigation_blocks: [
                ...newPage.navigation_blocks,
                {
                  title: "",
                  image: "",
                  height: "",
                  width: "",
                  links: [
                    {
                      link_text: "",
                      page_id: null,
                    },
                  ],
                },
              ],
            });
          }}
          styleType={"black"}>
          Add Navigation Block
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {})(CreateLandingPageContent);
