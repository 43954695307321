import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
//import mapKeys from 'lodash/mapKeys';
//import { validate as uuidValidate } from 'uuid';
import { toast } from "react-toastify";
import { manageEmailerData } from "src/actions/emailer";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Button from "src/components/Shared/Buttons/Button";
import MultiSelect from "src/components/Shared/Forms/Selects/MultiSelect";

const EmailerItem = ({ page, pages, groups, index, state, setState, i, ...props }) => {
  const clientName = localStorage.getItem("clientName") ? localStorage.getItem("clientName") : "";
  const clientEmail = localStorage.getItem("clientEmail") ? localStorage.getItem("clientEmail") : "";
  const clientUnites = localStorage.getItem("clientUnites") ? localStorage.getItem("clientUnites") : "";
  const clientProduct = localStorage.getItem("clientProduct") ? localStorage.getItem("clientProduct") : "";
  const html = state[index] ? state[index].content : "";
  const [emailSubject, setToEmailSubject] = useState(state[index] ? state[index].subject : "");
  let string;
  if (clientName && clientEmail && clientUnites) {
    string = html.replace("[client]", clientName).replace("[units]", clientUnites).replace("[product]", clientProduct);
  } else {
    string = html;
  }
  const [content, setContent] = useState(string);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [toId, setToId] = useState([]);

  useEffect(() => {
    let updatedGroups = groups.filter((group) => selectedGroups.includes(group._id));
    let userIds = [];
    updatedGroups.map((it) => userIds.push(...it.user_id));
    setToId(userIds.filter((item, index) => userIds.indexOf(item) === index));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroups]);

  const onSubmit = async () => {
    try {
      let msg = await manageEmailerData({
        to: toId,
        groups: selectedGroups,
        subject: emailSubject,
        content: content,
      });
      if (msg.status === 200) {
        setSelectedGroups([]);
        setToId([]);
        setToEmailSubject(state[index] ? state[index].subject : "");
        setContent(string);
        localStorage.removeItem("clientName");
        localStorage.removeItem("clientEmail");
        localStorage.removeItem("clientUnites");
        localStorage.removeItem("clientProduct");
        //toEmail = '';
        //content = state[index]? state[index].content:'';
        toast.success(msg.message);
      } else {
        toast.error(msg.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="grid space-y-8">
      <div className="bg-white shadow-md px-6 py-8 rounded-md flex flex-wrap justify-between w-full">
        <div className="flex flex-wrap w-1/2 gap-y-6">
          {/* Send To */}
          <div className=" w-full px-2">
            <MultiSelect options={groups} title="Send To:" selectedOptions={selectedGroups} setSelectedOptions={setSelectedGroups}></MultiSelect>
            {/* <Input name="site-name" label="Send To:" value={toEmail} onChange={(e) => setToEmail(e.target.value)} /> */}
          </div>
          {/* Subject Message */}
          <div className=" w-full px-2">
            <Input name="site-name" label="Subject Message:" value={emailSubject} onChange={(e) => setToEmailSubject(e.target.value)} />
          </div>
          {/* Body */}
          <div className=" w-full px-2">
            <textarea className="w-full h-40 text-sm rounded border-gray-300 border outline-none no-shadow py-2 px-3 focus:border-highlightColor" placeholder="Body:" value={content} onChange={(e) => setContent(e.target.value)}></textarea>
          </div>
          <div className="w-full flex justify-end gap-x-4 px-2">
            <Button onClick={onSubmit}>Submit</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, { manageEmailerData })(EmailerItem);
