import TableBody from "./TableBody";
import TableBodyLoader from "./TableBodyLoader";
import { AnimatePresence } from "framer-motion";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import { classNames } from "src/helpers/classNames";

const Table = ({ children, tableHeader, dataExists = false, loaded = false, colSpan = 0 }) => {
  return (
    <AnimatePresence>
      <div className="relative flex flex-col">
        <div className="w-full my-3">
          <div className="shadow-md ring-1 ring-black ring-opacity-5 rounded-md md:rounded-lg">
            <table className="table-auto w-full divide-y divide-gray-300">
              {tableHeader}
              <TableBody>
                {dataExists && children}
                {!dataExists && !loaded && (
                  <TableBodyLoader
                    dataExists={dataExists}
                    loaded={loaded}
                    colSpan={colSpan}
                  />
                )}
              </TableBody>
            </table>
          </div>
        </div>
        <div className={classNames("absolute -bottom-6 right-1 h-10 transition-all duration-300", dataExists && !loaded ? "opacity-100" : "opacity-0")}>
          <Preloader
            className="h-[40px]"
            circleDimension="5"
            size="sm"
          />
        </div>
      </div>
    </AnimatePresence>
  );
};

export default Table;
