import { CodeBracketIcon, PresentationChartBarIcon, WindowIcon } from "@heroicons/react/24/outline"
import { motion, useIsPresent } from "framer-motion";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { managePageData } from "src/actions/page";
import QuickMenu from "src/components/Shared/QuickMenu";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";

// const PageEntry = ({ placeholder, innerRef, draggableProps, dragHandleProps, page, isDefault, updateDefaultKey, deletePage, editPage, getPages, managePageData, ...props }) => {
const PageEntry = ({ workspaceDetails, user = null, pageCredentialOptions, workspaceId = "", page, isDefault, updateDefaultKey, deleteId, setDeleteId, editPage, pagePrev, reloadPagesData, managePageData, ...props }) => {
  const [items, setItems] = useState([]);
  let isPresent = useIsPresent();

  let generateStyle = () => {
    if (isPresent) {
      return {
        position: "relative",
      };
    } else {
      return {
        position: "absolute",
        display: "flex",
        alignItems: "center",
      };
    }
  };

  const iconTypes = {
    DOMO: {
      icon: <PresentationChartBarIcon />,
      name: "Dashboard",
    },
    CUSTOM_HTML: {
      icon: <CodeBracketIcon />,
      name: "Custom HTML",
    },
    LANDING_PAGE: {
      icon: <WindowIcon />,
      name: "Landing Page",
    },
  };

  useEffect(() => {
    let item = [];
    if (authorizeUserComponentAccess(user, workspaceId, "page", ["update"])) {
      item.push({ name: "Edit", onClick: () => editPage(page), color: "text-grey-500" });
    }

    item.push({ name: "Preview", onClick: () => pagePrev(page), color: "text-grey-500" });

    if (authorizeUserComponentAccess(user, workspaceId, "page", ["delete"])) {
      item.push({ name: "Delete", onClick: () => setDeleteId(page._id), color: "text-red-500" });
    }
    setItems(item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, page]);

  return (
    <motion.tr
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{
          opacity: 0,
        }}
        transition={{ opacity: { duration: 0.2 } }}
        style={generateStyle}
        // ref={innerRef}
        // {...draggableProps}
        className="group relative flex w-full flex-col items-start justify-between rounded-md bg-white py-4 sm:table-row">
        <td className="bg-white px-3 py-3 text-sm">
          <div className="text-gray-900 font-medium">{page.name}</div>
        </td>
        <td className="bg-white px-3 py-3 text-sm">
          <div className="text-gray-900 font-medium">
            <div className="flex item-center gap-x-4">
              <div className="h-5 w-5">{iconTypes[page.page_type]?.icon}</div>
              {iconTypes[page.page_type].name}
            </div>
          </div>
        </td>
        <td className="bg-white px-3 py-3 text-sm">
          <div className="flex justify-end">
            <QuickMenu items={items} />
          </div>
        </td>
      </motion.tr>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    user: state.auth.user,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, { managePageData })(PageEntry);
