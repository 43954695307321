import { classNames } from "src/helpers/classNames";

const defaultStyles = "font-semibold text-gray-900 mb-6 md:mb-8";


const H1 = ({ className = "", children, item = { defaultCss: "", onHoverCss: "", onClick: () => {} } }) => {
  return (
    <h1 className={`${defaultStyles} flex items-center gap-x-2 text-3xl md:mb-6 text-gray-700 ${className}`}>
      {item.icon ? (
        <item.icon

          onClick={item.onClick}
          className={classNames("h-8 w-8", item.defaultCss, item.onHoverCss)}
        />
      ) : null}
      {children}
    </h1>
  );
};

const H2 = ({ className = "", children }) => {
  return <h2 className={`${defaultStyles} text-2xl md:mb-4 text-gray-600 ${className}`}>{children}</h2>;
};

const H3 = ({ className = "", children }) => {
  return <h3 className={`${defaultStyles} pb-1 text-xl text-gray-500 ${className}`}>{children}</h3>;
};

const H4 = ({ className = "", children }) => {
  return <h4 className={`${defaultStyles} text-lg font-semibold text-gray-400 mb-6 md:mb-2 ${className}`}>{children}</h4>;
};

export { H1, H2, H3, H4 };
