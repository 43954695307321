import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { noLogo } from "src/config/host";

const Logo = ({ children, ...props }) => {
  return (
    <div className="flex items-center h-[56px] flex-shrink-0">
      {children}
      <div className="transition-all duration-75 min-w-[130px]">
        <Link to="/pages">
          <img
            className="h-10 sm:h-12 w-auto"
            src={Object.keys(props.workspaceDetails).length && props.me.type === "user" ? props.workspaceDetails.image_logo : props.site.image_logo}
            alt="Site logo"
            onError={(e) => (e.target.src = `${noLogo}`)}
          />
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    workspaceDetails: state.workspaceDetails,
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, null)(Logo);
