import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { manageWorkspaceSettings } from "src/actions/workspace";
import Button from "src/components/Shared/Buttons/Button";
import { classNames } from "src/helpers/classNames";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import { useLoadData } from "src/hooks/useLoadData";
import { useParams } from "react-router";
import { HomeIcon } from "@heroicons/react/24/outline";

const WorkspaceLayouts = ({ workspace, ...props }) => {
  const [layoutType, setLayoutType] = useState(workspace.layout_type);
  const [pages, setPages] = useState([]);
  const [loadedPages, setLoadedPages] = useState([]);
  const [landingPageOptions, setLandingPageOptions] = useState([]);
  const [layoutLandingPageId, setLayoutLandingPageId] = useState(workspace.layout_landing_page_id);

  const { id: workspaceId } = useParams();

  useLoadData("get", `/workspaces/${workspaceId}/pages?page_type=LANDING_PAGE`, {}, (res) => setPages(res.data), loadedPages, setLoadedPages);

  useEffect(() => {
    if (workspace?._id) {
      setLayoutType(workspace.layout_type);
      setLayoutLandingPageId(workspace.layout_landing_page_id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace]);

  useEffect(() => {
    if (pages?.length > 0) {
      let sortedPages = pages;
      sortedPages.sort(function (a, b) {
        return a.workspace_id?.name.localeCompare(b.workspace_id?.name);
      });
      let navigationPages = sortedPages.map((page) => {
        return { value: page._id, key: page.name };
      });
      setLandingPageOptions(navigationPages);
    }
  }, [pages]);

  const onClear = () => {
    setLayoutType(workspace.layout_type || "LEFT_NAVIGATION");
  };

  const onSubmit = async () => {
    try {
      let payload = {
        workspace_id: workspaceId,
        layout_type: layoutType,
      };
      if (layoutType === "NO_NAVIGATION") {
        if (!layoutLandingPageId) {
          toast.error('You must select a landing page when "No Navigation" is selected');
          return;
        }
        payload.layout_landing_page_id = layoutLandingPageId;
      }
      const message = await props.manageWorkspaceSettings(payload);
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const LayoutContainer = ({ children, name, value, checked, onChange = () => {}, defaultChecked = false }) => {
    return (
      <>
        <div
          onClick={() => onChange(value)}
          className={classNames(
            "group h-72 hover:bg-gray-50/50 cursor-pointer relative bg-white border 2xl:py-12 py-8 rounded-md mx-4 flex 2xl:px-6 px-2 xl:flex-grow max-w-[440px] justify-center items-center",
            checked ? "border-highlightColor shadow-md" : "hover:border-gray-400"
          )}>
          <div className="absolute top-5 left-4 flex items-center gap-3 min-w-[28px]">
            <Toggle
              checked={checked}
              onChange={() => onChange(value)}
            />
          </div>
          <div className="relative h-full">{children}</div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="relative w-full h-full flex flex-col gap-y-10 2xl:flex-row 2xl:gap-y-0 2xl:flex-no-wrap justify-evenly bg-white py-20 px-10 rounded-md">
        {/* Layout 1 */}
        <LayoutContainer
          name={"layouts"}
          value={"LEFT_NAVIGATION"}
          defaultChecked={true}
          checked={layoutType === "LEFT_NAVIGATION"}
          onChange={setLayoutType}>
          <div className="w-60 h-full border border-gray-400 rounded overflow-hidden">
            <div
              style={{ color: workspace?.top_bar_text_color, backgroundColor: workspace?.top_bar_color }}
              className="border-b border-gray-200 h-8 w-full"
            />
            <div className="h-[calc(100%-2rem)] flex">
              <div
                style={{ color: workspace?.side_bar_text_color, backgroundColor: workspace?.side_bar_color }}
                className="border-r border-gray-200 text-xs w-16 min-w-[64px] p-2 space-y-2">
                <div>Products</div>
                <div>About Us</div>
                <div>Feedback</div>
              </div>
              <div className="w-full bg-gray-50"></div>
            </div>
          </div>
        </LayoutContainer>

        {/* Layout 2 */}
        <LayoutContainer
          name={"layouts"}
          value={"TOP_NAVIGATION"}
          checked={layoutType === "TOP_NAVIGATION"}
          onChange={setLayoutType}>
          <div className="w-60 h-full border border-gray-400 rounded overflow-hidden">
            <div
              style={{ color: workspace?.top_bar_text_color, backgroundColor: workspace?.top_bar_color }}
              className="h-8 w-full"
            />
            <div
              style={{ color: workspace?.side_bar_text_color, backgroundColor: workspace?.side_bar_color }}
              className="border-y border-gray-200 text-xs h-5 w-full px-3 space-x-2 flex items-center">
              <div>Products</div>
              <div>About Us</div>
              <div>Feedback</div>
            </div>
            <div className="h-[calc(100%-3.25rem)] flex">
              <div className="w-full bg-gray-50"></div>
            </div>
          </div>
        </LayoutContainer>

        {/* Layout 3 */}
        <LayoutContainer
          name={"layouts"}
          value={"NO_NAVIGATION"}
          checked={layoutType === "NO_NAVIGATION"}
          onChange={setLayoutType}>
          <div className="flex flex-col h-52 gap-y-2">
            <div className="w-60 h-full bg-gray-50 border border-gray-400 rounded overflow-hidden">
              <div
                style={{ color: workspace?.top_bar_text_color, backgroundColor: workspace?.top_bar_color }}
                className="h-8 w-full"
              />
              <div
                style={{ color: workspace?.side_bar_text_color, backgroundColor: workspace?.side_bar_color }}
                className="border-y border-gray-200 text-xs h-5 w-full px-3 space-x-2 flex items-center">
                <div>
                  <HomeIcon className="h-3 w-3" />
                </div>
              </div>
              <div className="h-[calc(100%-3rem)] flex">
                <div className="w-full bg-gray-50"></div>
              </div>
            </div>
            <div className="w-full">
              <SelectMenu
                startIndex={landingPageOptions.findIndex((option) => option.value === layoutLandingPageId)}
                options={landingPageOptions || [{ key: "Select Page", value: -1 }]}
                setOption={(option) => {
                  setLayoutLandingPageId(option.value);
                }}
              />
            </div>
          </div>
        </LayoutContainer>
      </div>
      <div className="mt-5 w-full flex justify-end gap-x-4 pb-20">
        <Button
          styleType="gray"
          onClick={onClear}>
          Undo changes
        </Button>
        {authorizeUserComponentAccess(props.me, workspaceId, "layout", ["update"]) && <Button onClick={onSubmit}>Update</Button>}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, { manageWorkspaceSettings })(WorkspaceLayouts);
