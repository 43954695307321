import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { manageSiteSettings, getSiteSettings } from "src/actions/site";
import FrontLabeledInput from "src/components/Shared/Forms/Inputs/FrontLabeledInput";
import Input from "src/components/Shared/Forms/Inputs/Input";
import RadioButtons from "src/components/Shared/Forms/Toggles/RadioButtons";
import { classNames } from "src/helpers/classNames";
import Button from "src/components/Shared/Buttons/Button";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { handleUpdateDomoData } from "src/actions/site";
import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import { apiUrl } from "src/config/host";
import { fetchData } from "src/async/fetch";
import { ArrowPathIcon } from "@heroicons/react/24/solid";

const SiteSettings = ({ ...props }) => {
  const [siteName, setSiteName] = useState(props?.site.site_name || "");
  const [siteDomain, setSiteDomain] = useState(props?.site.site_domain || "");
  const [datasetClientId, setDatasetClientId] = useState(props?.site.dataset_client_id || "");
  const [datasetClientSecret, setDatasetClientSecret] = useState(props?.site.dataset_client_secret || "");
  const [datasetId, setDatasetId] = useState(props?.site.dataset_id || "");
  const [passwordState, setPasswordState] = useState(props?.site.password_state || false);
  const [portalDatasetId, setPortalDatasetId] = useState(props?.site.portal_dataset_id || "");
  const [domoLoginUrl, setDomoLoginUrl] = useState(props?.site.domo_login_url || "");
  const [apiState, setApiState] = useState(props?.site.api_state || "PORTAL_CREATED");
  const [isSync, setSync] = useState(false);

  const setPageData = () => {
    if (props?.site._id) {
      setSiteName(props.site.site_name || "");
      setSiteDomain(props.site.site_domain || "");
      setApiState(props.site.api_state || "");
      setDatasetClientId(props.site.dataset_client_id || "");
      setDatasetClientSecret(props.site.dataset_client_secret || "");
      setDatasetId(props.site.dataset_id || "");
      setPasswordState(props.site.password_state || false);
      setPortalDatasetId(props.site.portal_dataset_id || "");
      setDomoLoginUrl(props.site.domo_login_url || "");
    }
  };

  useEffect(() => {
    setPageData();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.site._id]);

  const onClear = () => {
    setPageData();
  };

  const onSubmit = async () => {
    try {
      let body = {
        site_name: siteName,
        site_domain: siteDomain,
        dataset_id: datasetId,
        password_state: passwordState,
        portal_dataset_id: portalDatasetId,
        domo_login_url: domoLoginUrl,
        api_state: apiState,
      };
      if (datasetClientId.split("").filter((char) => char !== "*").length > 0) {
        body.dataset_client_id = datasetClientId;
      }
      if (datasetClientSecret.split("").filter((char) => char !== "*").length > 0) {
        body.dataset_client_id = datasetClientSecret;
      }
      const res = await fetchData("PUT", `${apiUrl}/site-settings`, body);
      const data = await res.json();

      await getSiteSettings();
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const randomHashString = (length) => {
    let result = "";
    const characters = "**************************************************************";
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  };

  const updateDomo = async () => {
    try {
      setSync(true);
      const message = await handleUpdateDomoData();
      toast.success(message);
      setSync(false);
    } catch (error) {
      console.dir(error);
      toast.error(error.message);
      setSync(false);
    }
  };

  return (
    <div className="grid space-y-8">
      <div className="bg-white shadow-md px-6 py-8 rounded-md flex flex-wrap justify-between w-full">
        <div className="text-gray-500 text-lg font-medium w-full px-2 mb-6">Site Settings</div>
        <div className="flex flex-wrap w-full gap-y-6">
          {/* Site name */}
          <div className="sm:w-1/2 w-full px-2">
            <Input
              name="site-name"
              label="Site Name"
              value={siteName}
              onChange={(e) => setSiteName(e.target.value)}
            />
          </div>
          <div className="sm:w-1/2 w-full px-2">
            <Input
              name="site-domain"
              label="User Portal URL"
              value={siteDomain || props?.site.site_domain}
              onChange={(e) => setSiteDomain(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="bg-white shadow-md px-6 py-8 rounded-md flex flex-col justify-between w-full gap-y-6 lg:gap-x-12">
        <ToggleHeader
          title="Import Users from Dataset"
          subtitle="User creation will be disabled locally.">
          <Toggle
            checked={"DOMO_DATASET" === apiState}
            onChange={(e) => setApiState(e ? "DOMO_DATASET" : "PORTAL_CREATED")}
          />
        </ToggleHeader>

        <div className="relative rounded-md w-full">
          <div className={classNames("h-full rounded w-full absolute flex items-center justify-center bg-gray-100 opacity-80 z-10", apiState !== "DOMO_DATASET" ? "absolute" : "hidden")}>
            <div className="w-full flex justify-center rotate-[-10deg] text-4xl md:text-4xl lg:text-5xl xl:text-7xl font-extrabold text-gray-300 text-center select-none">For Domo Dataset Use</div>
          </div>
          <div className="px-4 w-full flex flex-col gap-y-6 py-6">
            <div className="grid space-y-2 text-sm lg:w-2/3">
              <FrontLabeledInput
                label="Client ID"
                type="password"
                disabled={apiState !== "DOMO_DATASET"}
                value={randomHashString(datasetClientId.length) || randomHashString(props?.site.dataset_client_id?.length)}
                onChange={(e) => setDatasetClientId(e.target.value)}
                onFocus={(e) => {
                  if (e.target.value.includes("*")) {
                    e.target.value = "";
                  }
                }}
              />
              <FrontLabeledInput
                label="Client Secret"
                type="password"
                disabled={apiState !== "DOMO_DATASET"}
                value={randomHashString(datasetClientSecret.length) || randomHashString(props?.site.dataset_client_secret?.length)}
                onChange={(e) => setDatasetClientSecret(e.target.value)}
                onFocus={(e) => {
                  if (e.target.value.includes("*")) {
                    e.target.value = "";
                  }
                }}
              />
              <FrontLabeledInput
                label="Dataset ID"
                type="text"
                disabled={apiState !== "DOMO_DATASET"}
                value={datasetId || props?.site.portal_dataset_id}
                onChange={(e) => setDatasetId(e.target.value)}
              />
            </div>
            {(portalDatasetId || props?.site.portal_dataset_id) && (
              <div className="flex items-center w-full">
                <div className="w-full sm:w-1/2">
                  <Input
                    name="domo-login-url"
                    label="Domo Login URL"
                    value={domoLoginUrl || props?.site.domo_login_url}
                    onChange={(e) => setDomoLoginUrl(e.target.value)}
                  />
                </div>
                <a
                  href={domoLoginUrl + "/datasources/" + datasetId + "/details/data/table"}
                  className="mt-6 flex items-center gap-x-2 pr-6 ml-4 text-regular font-medium text-gray-500 hover:text-highlightColor hover:underline"
                  target="_blank"
                  rel="noreferrer">
                  <p>Link to domo dataset</p>
                  <ArrowTopRightOnSquareIcon className="h-6 w-6" />
                </a>
              </div>
            )}
            <div className="max-w-[260px]">
              <RadioButtons
                disabled={apiState !== "DOMO_DATASET"}
                heading="Overwrite user passwords"
                subheading="Does your dataset contain your user's passwords?"
                items={[
                  { id: "yes", name: "Yes", checked: passwordState === true, onChange: () => setPasswordState(true) },
                  { id: "no", name: "No", checked: passwordState === false, onChange: () => setPasswordState(false) },
                ]}
              />
            </div>
            <div className="flex flex-wrap justify-between w-full gap-y-6">
              <div className="flex w-full mt-5 gap-x-4 justify-end items-center">
                <Button
                  disabled={!(datasetId || props?.site.portal_dataset_id)}
                  styleType="secondary"
                  onClick={updateDomo}>
                  <ArrowPathIcon className={classNames("inline-block h-5 w-5", isSync ? "animate-spin" : "")} />
                  Sync with dataset
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex justify-end gap-x-4 pb-40">
        <Button
          styleType="gray"
          onClick={onClear}>
          Undo changes
        </Button>
        <Button onClick={onSubmit}>Update</Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    creds: state.creds,
  };
};

export default connect(mapStateToProps, { manageSiteSettings, getSiteSettings })(SiteSettings);
