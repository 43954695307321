import { IdentificationIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteSso, getSSODataCount } from "src/actions/sso";
import Button from "src/components/Shared/Buttons/Button";
import IconsWithPlus from "src/components/Shared/Icons/IconsWithPlus";
import ModalDefault from "src/components/Shared/Modals/ModalDefault";
import PaginationFooter from "src/components/Shared/PaginationFooter";
import Table from "src/components/Shared/Table/Table";
import TRHeader from "src/components/Shared/Table/TRHeader";
import TableOptions from "src/components/Shared/TableOptions";
import SSOEntry from "src/components/SSO/SSOEntry";
import Search from "src/components/Shared/Search";

const SSOList = ({ sso, deleteSso, ...props }) => {
  const [deleteId, setDeleteId] = useState(null);

  const [loaded, setLoaded] = useState(false);
  const [keyword, setKeyword] = useState("");

  // Pagination
  const [totalEntriesCount, setTotalEntriesCount] = useState(0);
  const [paginationLimit, setPaginationLimit] = useState(10);
  const [paginationOffset, setPaginationOffset] = useState(0);
  const [paginationTabCount, setPaginationTabCount] = useState(1);

  const navigate = useNavigate();

  const loadSSO = async ({ params = {}, ac = {} }) => {
    params = { keyword, limit: paginationLimit, offset: paginationOffset * paginationLimit, ...params };
    try {
      const data = await props.getSSODataCount({ params }, ac.signal);
      const numberOfPaginationTabsRequired = Math.ceil(data.length / params.limit);
      setTotalEntriesCount(data.length);
      setPaginationTabCount(numberOfPaginationTabsRequired);
      setLoaded(true);
    } catch (error) {
      setLoaded(true);
      console.dir(error.message);
    }
  };

  const handlePaginationChange = ({ limit, offset }) => {
    setPaginationLimit(limit);
    setPaginationOffset(offset);
    loadSSO({ params: { limit, offset: offset * limit } });
  };

  useEffect(() => {
    const ac = new AbortController();

    setLoaded(false);
    loadSSO(ac);

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  const removeSSO = async (e) => {
    e.preventDefault();
    try {
      const message = await deleteSso(deleteId);
      setDeleteId(null);
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <>
      <TableOptions
        leftContent={
          <Search
            keyword={keyword}
            setKeyword={setKeyword}
          />
        }
        rightContent={
          <Button
            styleType="secondary"
            onClick={() => navigate("/sso/add")}>
            Add SSO
            <IconsWithPlus
              strokeColor={"stroke-highlightColor"}
              item={{ icon: IdentificationIcon }}
            />
          </Button>
        }></TableOptions>
      <ModalDefault
        isOpen={!!deleteId}
        closeModal={() => setDeleteId(null)}
        onSubmit={removeSSO}
        submitText="Delete"
        cancelText="Cancel"
        title="SSO"
        secondaryTitle="Delete">
        <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete {sso.find((item) => item._id === deleteId)?.provider_name}?</div>
      </ModalDefault>
      <Table
        tableHeader={
          <TRHeader>
            <th
              scope="col"
              className="rounded-l-xl py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Identity Provider
            </th>
            <th
              scope="col"
              className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              IDP Entity ID OR Issuer
            </th>
            <th
              scope="col"
              className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              SAML login URL
            </th>
            <th
              scope="col"
              className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Status
            </th>
            <th
              scope="col"
              className="rounded-r-xl relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Edit</span>
            </th>
          </TRHeader>
        }
        colSpan="5"
        setDeleteId={setDeleteId}
        deleteId={deleteId}
        loaded={loaded}
        dataExists={sso.length > 0}>
        {sso &&
          sso.map((item) => (
            <SSOEntry
              key={item._id}
              sso={item}
              deleteId={deleteId}
              setDeleteId={setDeleteId}
              onChange={() => loadSSO({})}
            />
          ))}
      </Table>
      <PaginationFooter
        itemName="SSO providers"
        entryCount={sso.length}
        totalEntriesCount={totalEntriesCount}
        loaded={loaded}
        limit={paginationLimit}
        offset={paginationOffset}
        tabCount={paginationTabCount}
        onChange={handlePaginationChange}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    sso: Object.values(state.sso),
  };
};

export default connect(mapStateToProps, { getSSODataCount, deleteSso })(SSOList);
