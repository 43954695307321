import { motion, useIsPresent } from "framer-motion";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import QuickMenu from "src/components/Shared/QuickMenu";
import TRBody from "src/components/Shared/Table/TRBody";
import { baseUrl, noFavicon } from "src/config/host";

const WorkspaceEntry = ({ workspace, setDeleteId, site, ...props }) => {
  let isPresent = useIsPresent();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  let generateStyle = () => {
    if (isPresent) {
      return {
        position: "relative",
      };
    } else {
      return {
        position: "absolute",
        display: "flex",
        alignItems: "center",
      };
    }
  };

  useEffect(() => {
    let user = [];
    if (workspace?.groups) {
      for (let index = 0; index < workspace?.groups.length; index++) {
        const element = workspace?.groups[index];
        if (element.users.length > 0) {
          for (let index2 = 0; index2 < element.users.length; index2++) {
            const element2 = element.users[index2];
            let ind = user.findIndex((us) => us._id === element2._id);
            if (ind === -1) {
              user.push(element2);
            }
          }
        }
      }
    }
    setUsers(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace?.groups]);

  return (
    <motion.tr
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{
        opacity: 0,
      }}
      transition={{ opacity: { duration: 0.2 } }}
      style={generateStyle}
      key={workspace?._id}
      className="relative bg-white">
      <TRBody className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-6">
        <Link
          to={`/workspaces/${workspace._id}`}
          className="text-gray-900 transition-all duration-200 font-medium cursor-pointer hover:underline group">
          <div className="flex gap-4 items-center">
            <div className="w-auto transition-all duration-200 h-11 overflow-hidden group-hover:bg-gray-50/50 border border-transparent group-hover:border-gray-200 p-2 rounded">
              <img
                src={workspace.image_logo?.includes(".s3.") ? workspace.image_logo : workspace.image_logo?.includes("data:image/") ? workspace.image_logo : baseUrl + workspace.image_logo}
                alt="Logo"
                className="w-full h-full object-contain"
                onError={(e) => (e.target.src = `${noFavicon}`)}
              />
            </div>
            {workspace?.name}
          </div>
        </Link>
      </TRBody>
      <TRBody className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-6">
        <div className="text-gray-900 font-medium">{workspace?.groups.length}</div>
      </TRBody>
      <TRBody className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-6">
        <div className="text-gray-900 font-medium">{users.length}</div>
      </TRBody>
      <TRBody className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-6">
        <div className="text-gray-900 font-medium">{workspace?.pages.length}</div>
      </TRBody>
      <td className="whitespace-nowrap py-3 pl-3 pr-4 text-sm font-medium">
        <div className="flex justify-end">
          <QuickMenu
            items={[
              {
                name: "Edit",
                onClick: () => {
                  navigate(`/workspaces/${workspace._id}`);
                },
              },
              {
                name: "Delete",
                onClick: () => {
                  setDeleteId(workspace._id);
                },
                color: "text-red-500",
              },
            ]}
          />
        </div>
      </td>
    </motion.tr>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(WorkspaceEntry);
