import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { getOperators } from "src/actions/operator";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import { workspaceIntegrationList } from "src/actions/workspace";
import WorkspaceIntegrationItem from "./WorkspaceIntegrationItem";

const WorkspaceIntegration = ({ workspaceId, ...props }) => {
  const [loaded, setLoaded] = useState(false);
  const [integrations, setIntegrations] = useState([]);

  useEffect(() => {
    const ac = new AbortController();
    const loadData = async () => {
      await props.getOperators({}, ac.signal);
      const getIntegration = await props.workspaceIntegrationList({ workspace_id: workspaceId });
      setIntegrations(getIntegration);
      setLoaded(true);
    };
    loadData();
    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId]);

  return (
    <>
      <div className="relative space-y-4">
        {loaded ? (
          integrations.length > 0 &&
          integrations.map((integration, index) => {
            return (
              <WorkspaceIntegrationItem
                key={index}
                integration={integration}
                workspaceId={workspaceId}
              />
            );
          })
        ) : (
          <Preloader />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    workspace: state.workspaceDetails,
  };
};
export default connect(mapStateToProps, { getOperators, workspaceIntegrationList })(WorkspaceIntegration);
