import { apiRequest } from "src/async/apiUtils";
import { fetchData } from "src/async/fetch";
import { apiUrl } from "src/config/host";
import { GET_WORKSPACE_DATA, REMOVE_WORKSPACE, GET_WORKSPACE_DETAILS, CLEAR_WORKSPACE_DETAILS } from "./type";

export const getWorkspaceData =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      const { status, data } = await apiRequest("post", `${apiUrl}/workspaces/list?include=["groups","users","pages"]`, { body: payload });
      if (status === 200) {
        dispatch({ type: GET_WORKSPACE_DATA, payload: data.users });
        return data;
      } else {
        throw new Error(data.meta.message);
      }
    } catch (error) {
      throw new Error(error.data.meta.message);
    }
  };

export const getWorkspaceDetails =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("POST", `${apiUrl}/workspaces/details`, payload, signal);
      const data = await res.json();

      if (data.status === 200) {
        dispatch({ type: GET_WORKSPACE_DETAILS, payload: data.data });
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const clearWorkspaceDetails =
  (payload = null) =>
  (dispatch) =>
    dispatch({ type: CLEAR_WORKSPACE_DETAILS, payload: {} });

export const getWorkspaceGroupDetails =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("GET", `${apiUrl}/workspaces/${payload.workspace_id}/groups`, signal);
      const data = await res.json();

      if (data.status === 200) {
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const addWorkspaceGroup =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("POST", `${apiUrl}/workspaces/groups`, payload, signal);
      const data = await res.json();

      if (data.status === 200) {
        return data.message;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const editWorkspaceGroupDetails =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("PUT", `${apiUrl}/workspaces/${payload.workspaceId}/groups`, payload, signal);
      const data = await res.json();

      if (data.status === 200) {
        return data.message;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const manageGroupUser =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("POST", `${apiUrl}/workspaces/groups/users/manage`, payload, signal);
      const data = await res.json();

      if (data.status === 200) {
        return data.message;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const userPermissionList =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("GET", `${apiUrl}/workspaces/${payload.workspace_id}/users/permission/list`, payload, signal);
      const data = await res.json();

      if (data.status === 200) {
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };
export const userListOfWorkspacesAndPermissions2 =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("GET", `${apiUrl}/users/${payload.user_id}/workspaces/permissions`, payload, signal);
      const data = await res.json();

      if (data.status === 200) {
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const userListOfWorkspacesAndPermissions =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("POST", `${apiUrl}/workspaces/${payload.workspace_id}/permission/list`, payload, signal);
      const data = await res.json();

      if (data.status === 200) {
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const userPermissionManage =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("PUT", `${apiUrl}/workspaces/${payload.workspace_id}/users/permission/manage`, payload, signal);
      const data = await res.json();

      if (data.status === 200) {
        return data.message;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const userPermissionCheckboxManage =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("POST", `${apiUrl}/workspaces/${payload.workspace_id}/users/permission/checkbox/manage`, payload, signal);
      const data = await res.json();

      if (data.status === 200) {
        return data.message;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const manageWorkspaceSettings = (payload) => async (dispatch) => {
  try {
    const res = await fetchData("PUT", `${apiUrl}/workspaces/${payload.workspace_id}/settings`, payload);
    const data = await res.json();

    if (data.status === 200) {
      dispatch({ type: GET_WORKSPACE_DETAILS, payload: data.data });
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getUserWorkspaceData =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("GET", `${apiUrl}/users/${payload.user_id}/workspaces`, signal);
      const data = await res.json();
      if (data.status === 200) {
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const workspaceIntegrationList =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("GET", `${apiUrl}/workspaces/${payload.workspace_id}/integration`, signal);
      const data = await res.json();

      if (data.status === 200) {
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const workspaceIntegrationFilterCreate =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("POST", `${apiUrl}/workspaces/${payload.workspace_id}/integration/${payload.integration_id}/filter`, payload, signal);
      const data = await res.json();

      if (data.status === 200) {
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const workspaceIntegrationFilterManage =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("PUT", `${apiUrl}/workspaces/${payload.workspace_id}/integration/${payload.integration_id}/filter`, payload, signal);
      const data = await res.json();

      if (data.status === 200) {
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const workspaceIntegrationFilterDelete =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("DELETE", `${apiUrl}/workspaces/${payload.workspace_id}/integration/${payload.integration_id}/filter/${payload.id}`, signal);
      const data = await res.json();

      if (data.status === 200) {
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const workspaceIntegrationCredentialCreate =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("POST", `${apiUrl}/workspaces/${payload.workspace_id}/integration/${payload.integration_id}/credential`, payload, signal);
      const data = await res.json();

      if (data.status === 200) {
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const workspaceIntegrationCredentialManage =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("PUT", `${apiUrl}/workspaces/${payload.workspace_id}/integration/${payload.integration_id}/credential/manage`, payload, signal);
      const data = await res.json();

      if (data.status === 200) {
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const removeWorkspaceImages = (payload) => async (dispatch) => {
  try {
    const res = await fetchData("DELETE", `${apiUrl}/workspaces/${payload.workspace_id}/images/${payload.imageType}`, payload);

    const resData = await res.json();

    if (resData.status === 200) {
      dispatch({ type: GET_WORKSPACE_DETAILS, payload: resData.data });
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const deleteWorkspace = (id) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/workspaces/remove `, { id });
    const data = await res.json();

    if (data.status === 200) {
      dispatch({ type: REMOVE_WORKSPACE, payload: id });
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};
