import { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { verifyUser } from "src/actions/auth";
import AuthContainer from "src/components/Auth/AuthContainer";
import AuthHeader from "src/components/Auth/AuthHeader";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Button from "src/components/Shared/Buttons/Button";

const ForgotPasswordContent = ({ site, verifyUser, seoTitle }) => {
  const [cred, setCred] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const { message } = await verifyUser({ email: cred });
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <AuthHeader
        seoTitle={seoTitle}
        title="Send a reset link"
        switchTo={{
          startText: "Enter your sign up email and we’ll take care of the rest",
          location: site?.site_domain || "/",
        }}
      />
      <AuthContainer>
        {/* Email */}
        <div className="w-full">
          <Input
            name="contact-email"
            label="Email"
            error={"error" === "Email field is required"}
            value={cred}
            onChange={(e) => setCred(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSubmit(e);
              }
            }}
          />
        </div>

        <div className="w-full mt-4">
          <Button
            onClick={onSubmit}
            width="w-full">
            Send reset link
          </Button>
        </div>
      </AuthContainer>
      <p className="mt-4 text-center text-regular font-md text-gray-600">
        Return to
        <Link
          to="/login"
          className="underline pl-1 text-highlightColor">
          login page
        </Link>
      </p>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, { verifyUser })(ForgotPasswordContent);
