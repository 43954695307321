import { fetchData } from "src/async/fetch";
import { baseUrl } from "src/config/host";

let base64String = "";

export const convertToBase64 = (file, setImage) => {
  if (file instanceof File && file.name) {
    const reader = new FileReader();

    reader.onload = () => {
      base64String = reader.result;
      const imageBase64Stringsep = base64String;
      setImage(imageBase64Stringsep);
    };

    reader.readAsDataURL(file);
  }
};

export const getS3ImageDetails = async (key) => {
  try {
    const res = await fetchData("GET", baseUrl + `${key}`);
    const resData = await res.blob();
    return {
      status: 200,
      data: URL.createObjectURL(resData),
    };
  } catch (err) {
    return { status: 400, message: err.message };
  }
};
