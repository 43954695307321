import React from "react";
import PageList from "src/components/Pages/PageList";
import { H1 } from "src/components/Shared/Text/Headers";

const pages = () => {
  return (
    <>
      <H1>Pages</H1>
      <PageList pageName="Pages" />
    </>
  );
};

export default pages;
