import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { getOperators } from "src/actions/operator";
import { getPages } from "src/actions/page";
import { deleteGroupData, getGroupData, getUsers } from "src/actions/user";
import GroupEntry from "src/components/Groups/GroupEntry";
import Button from "src/components/Shared/Buttons/Button";
import IconsWithPlus from "src/components/Shared/Icons/IconsWithPlus";
import ModalDefault from "src/components/Shared/Modals/ModalDefault";
import PaginationFooter from "src/components/Shared/PaginationFooter";
import Table from "src/components/Shared/Table/Table";
import TRHeader from "src/components/Shared/Table/TRHeader";
import TableOptions from "src/components/Shared/TableOptions";
import GroupModalEdit from "src/components/Users/GroupModalEdit";
import Search from "src/components/Shared/Search";
import useFetch from "src/hooks/useFetch";

const GroupList = ({ ...props }) => {
  const [groupModalOpen, setGroupModalOpen] = useState(false);
  const [paginationLimit, setPaginationLimit] = useState(10);
  const [paginationOffset, setPaginationOffset] = useState(0);
  const [deleteId, setDeleteId] = useState(null);
  const [addGroupStatus, setAddGroupStatus] = useState(false);
  const [editGroupStatus, setEditGroupStatus] = useState(false);
  const [editId, setEditId] = useState(null);
  const [keyword, setKeyword] = useState("");

  const {
    response: { data: groups, meta },
    status: { done: groupsLoaded },
    refreshData: refreshGroups,
  } = useFetch("/groups", { query: { limit: paginationLimit, offset: paginationOffset, keyword: keyword } });

  const handlePaginationChange = ({ limit, offset }) => {
    setPaginationLimit(limit);
    setPaginationOffset(offset);
  };

  useEffect(() => {
    refreshGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationLimit, paginationOffset]);

  useEffect(() => {
    refreshGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  const removeGroup = async (e) => {
    e.preventDefault();

    try {
      const message = await props.deleteGroupData(deleteId);
      setDeleteId(null);
      refreshGroups();
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <TableOptions
        leftContent={
          <Search
            keyword={keyword}
            setKeyword={setKeyword}
          />
        }
        rightContent={
          <Button
            styleType="secondary"
            onClick={() => {
              setGroupModalOpen(true);
              setAddGroupStatus(true);
              setEditGroupStatus(false);
              setEditId(null);
            }}>
            Add Group
            <IconsWithPlus
              strokeColor={"stroke-highlightColor"}
              item={{ icon: ClipboardDocumentListIcon }}
            />
          </Button>
        }
      />

      <Table
        tableHeader={
          <TRHeader>
            <th
              scope="col"
              className="rounded-l-xl overflow-hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Name
            </th>
            <th
              scope="col"
              className="rounded-l-xl overflow-hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Pages
            </th>
            <th
              scope="col"
              className="rounded-r-xl overflow-hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
          </TRHeader>
        }
        colSpan="5"
        setDeleteId={setDeleteId}
        deleteId={deleteId}
        loaded={groupsLoaded}
        dataExists={groups?.length > 0}>
        {groups?.length > 0 &&
          groups.map((group, i) => {
            return (
              <Fragment key={group._id}>
                {group?.workspace_id?.name !== groups[i - 1]?.workspace_id?.name && (
                  <tr>
                    <td
                      colSpan={3}
                      className="break-all py-4 px-4 sm:pl-6 sm:py-3 text-sm font-semibold text-gray-500 whitespace-pre-wrap bg-gray-100">
                      {group?.workspace_id?.name}
                    </td>
                  </tr>
                )}
                <GroupEntry
                  key={group._id}
                  group={group}
                  deleteId={deleteId}
                  setDeleteId={setDeleteId}
                  setEditId={setEditId}
                  setGroupModalOpen={setGroupModalOpen}
                  setAddGroupStatus={setAddGroupStatus}
                  setEditGroupStatus={setEditGroupStatus}
                />
              </Fragment>
            );
          })}
      </Table>
      <PaginationFooter
        offset={paginationOffset}
        limit={paginationLimit}
        tabCount={groups?.length && Math.ceil(meta.count / paginationLimit)}
        totalEntriesCount={groups?.length && meta.count}
        itemName="Group records"
        onChange={handlePaginationChange}
      />

      <ModalDefault
        isOpen={!!deleteId}
        closeModal={() => setDeleteId(null)}
        onSubmit={removeGroup}
        submitText="Delete"
        cancelText="Cancel"
        title="Group"
        secondaryTitle="Delete">
        <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete {groups?.find((item) => item._id === deleteId)?.name}?</div>
      </ModalDefault>
      <GroupModalEdit
        isOpen={groupModalOpen}
        setIsOpen={setGroupModalOpen}
        setRefresh={() => {refreshGroups()}}
        submitText="Save"
        cancelText="Cancel"
        secondaryTitle={editGroupStatus ? "Edit" : "Add"}
        title={editGroupStatus ? "Group" : "Group"}
        addGroupStatus={addGroupStatus}
        setAddGroupStatus={setAddGroupStatus}
        editGroupStatus={editGroupStatus}
        setEditGroupStatus={setEditGroupStatus}
        editId={editId}
        setEditId={setEditId}
        groups={groups}
      />
    </>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, { getGroupData, deleteGroupData, getPages, getOperators, getUsers })(GroupList);
