import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { deleteWorkspace, getWorkspaceData } from "src/actions/workspace";
import ModalDefault from "src/components/Shared/Modals/ModalDefault";
import PaginationFooter from "src/components/Shared/PaginationFooter";
import Table from "src/components/Shared/Table/Table";
import TRHeader from "src/components/Shared/Table/TRHeader";
import { RectangleStackIcon } from "@heroicons/react/24/outline";
import Button from "src/components/Shared/Buttons/Button";
import WorkspaceAddModal from "src/components/Workspaces/WorkspaceAddModal";
import WorkspaceEntry from "src/components/Workspaces/WorkspaceEntry";
import IconsWithPlus from "src/components/Shared/Icons/IconsWithPlus";
import TableOptions from "src/components/Shared/TableOptions";
import Search from "src/components/Shared/Search";

const WorkspaceList = ({ user, workspaces, viewOnly = false, ...props }) => {
  const [loaded, setLoaded] = useState(false);
  const [workspaceModalOpen, setWorkspaceModalOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [paginationLimit, setPaginationLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pages, setPages] = useState(1);
  const [deleteId, setDeleteId] = useState(null);

  const [filteredWorkspaces, setFilteredWorkspaces] = useState([]);
  const [keyword, setKeyword] = useState("");

  const loadWorkspace = async (ac = {}) => {
    setLoaded(false);
    const params = { limit: paginationLimit, offset: offset * paginationLimit, userType: user?.type, userId: user?._id };
    try {
      const { meta } = await props.getWorkspaceData(params, ac.signal);
      setCount(meta.count);

      const availablePages = Math.ceil(meta.count / paginationLimit);
      setPages(availablePages);
      setLoaded(true);
    } catch (error) {
      setLoaded(true);
      console.dir(error.message);
    }
  };

  useEffect(() => {
    if (user?._id) {
      const ac = new AbortController();
      loadWorkspace(ac);
      return () => ac.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    let filtered = workspaces.filter((workspace) => workspace?.name.toLowerCase().includes(keyword?.toLowerCase()));
    setFilteredWorkspaces(filtered);
  }, [keyword, workspaces]);

  const getOffsetPageCount = (offset, paginationLimit) => {
    return !offset ? offset + 1 : offset * paginationLimit + 1;
  };

  const removeWorkspace = async (e) => {
    e.preventDefault();

    try {
      const message = await props.deleteWorkspace(deleteId);
      setDeleteId(null);
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handlePaginationChange = ({ limit, offset }) => {
    setPaginationLimit(limit);
    setOffset(offset);
    loadWorkspace();
  };

  const loadDataAfterAdded = async (e) => {
    const ac = new AbortController();
    loadWorkspace(ac);
    return () => ac.abort();
  };

  return (
    <>
      <TableOptions
        leftContent={
          <Search
            keyword={keyword}
            setKeyword={setKeyword}
          />
        }
        rightContent={
          <Button
            styleType="secondary"
            onClick={() => setWorkspaceModalOpen(true)}>
            Add Workspace
            <IconsWithPlus
              strokeColor={"stroke-highlightColor"}
              item={{ icon: RectangleStackIcon }}
            />
          </Button>
        }
      />
      <Table
        tableHeader={
          <TRHeader>
            <th
              scope="col"
              className="rounded-l-xl py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Name
            </th>
            <th
              scope="col"
              className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Groups
            </th>
            <th
              scope="col"
              className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Users
            </th>
            <th
              scope="col"
              className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Custom pages
            </th>
            <th
              scope="col"
              className="rounded-r-xl relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Edit</span>
            </th>
          </TRHeader>
        }
        colSpan="5"
        setDeleteId={setDeleteId}
        deleteId={deleteId}
        loaded={loaded}
        dataExists={filteredWorkspaces.length > 0}>
        {filteredWorkspaces.length > 0 &&
          filteredWorkspaces.map((workspace) => (
            <WorkspaceEntry
              viewOnly={viewOnly}
              key={workspace._id}
              workspace={workspace}
              deleteId={deleteId}
              setDeleteId={setDeleteId}
              setWorkspaceModalOpen={setWorkspaceModalOpen}
            />
          ))}
      </Table>
      {(count >= paginationLimit || count === filteredWorkspaces.length) && filteredWorkspaces.length > 0 && loaded && (
        <PaginationFooter
          itemName="Workspace records"
          getOffsetPageCount={getOffsetPageCount}
          offset={offset}
          paginationLimit={paginationLimit}
          setPaginationLimit={setPaginationLimit}
          totalEntriesCount={count}
          tabCount={pages}
          setOffset={setOffset}
          onChange={handlePaginationChange}
        />
      )}

      <ModalDefault
        isOpen={!!deleteId}
        closeModal={() => setDeleteId(null)}
        onSubmit={removeWorkspace}
        submitText="Delete"
        cancelText="Cancel"
        title="Workspace"
        secondaryTitle="Delete">
        <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete {filteredWorkspaces.find((workspace) => workspace._id === deleteId)?.name}?</div>
      </ModalDefault>
      <WorkspaceAddModal
        isOpen={workspaceModalOpen}
        setIsOpen={setWorkspaceModalOpen}
        loadDataAfterAdded={loadDataAfterAdded}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    workspaces: Object.values(state.workspaces),
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { getWorkspaceData, deleteWorkspace })(WorkspaceList);
