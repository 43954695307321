import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import FormContainer from "src/components/Shared/Forms/FormContainer";
import Input from "src/components/Shared/Forms/Inputs/Input";
import MultiSelectObject from "src/components/Shared/Forms/Selects/MultiSelectObject";
import ProfileImage from "src/components/Users/Edit/ProfileImage";

const AddUserForm = ({ index, state, setState, isAdmin, groups, ...props }) => {
  const [selectedGroups, setSelectedGroups] = useState([]);

  const removeForm = (e) => {
    e.preventDefault();

    delete state[index];
    setState(state);

    const availableForms = props.formCount.filter((count) => count !== index);
    props.setFormCount(availableForms);
  };

  const getValue = (key) => (state[index] ? state[index][key] || "" : "");

  const updateState = (value, key) => {
    setState({
      ...state,
      [index]: {
        ...state[index],
        [key]: value,
      },
    });
  };

  // const updateNotification = (e, key) => {
  //   setState({
  //     ...state,
  //     [index]: {
  //       ...state[index],
  //       [key]: e.target.checked,
  //     },
  //   });
  // };

  useEffect(() => {
    setState({
      ...state,
      [index]: {
        name: "",
        email: "",
        password: "",
        confirm_password: "",
        image: "",
        type: isAdmin ? "admin" : "user",
        notifications: false,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let updatedGroups = groups.filter((group) => selectedGroups.includes(group._id));
    setState({
      ...state,
      [index]: {
        ...state[index],
        groups: updatedGroups,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroups]);

  const handleSelectedGroups = (groups) => {
    let filteredGroups = groups.filter((group) => group.selected);
    setSelectedGroups(filteredGroups.map((group) => group.key));
  };

  return (
    <FormContainer>
      {index !== 0 && (
        <button type="button" className="absolute top-4 right-4 bg-white rounded-md text-red-400 hover:text-red-500 focus:outline-none" onClick={removeForm}>
          <span className="sr-only">Close</span>
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      )}

      <div className="flex flex-wrap w-full gap-y-4">
        {/* Photo */}
        <div className="w-full min-w-[200px] px-2">
          <ProfileImage user={state[index]} image={state[index]?.image} setImage={(value) => updateState(value, "image")} />
        </div>

        {/* Name */}
        <div className="sm:w-1/2 w-full px-2">
          <Input autocomplete="off" name="full-name" label="Full name" type="text" value={getValue("name")} onChange={(e) => updateState(e.target.value, "name")} />
        </div>

        {/* Email */}
        <div className="sm:w-1/2 w-full px-2">
          <Input name="email" label="Email" value={getValue("email")} onChange={(e) => updateState(e.target.value, "email")} />
        </div>

        {/* Password */}
        <div className="sm:w-1/2 w-full px-2">
          <Input autoComplete="new-password" type="password" label="Password" value={getValue("password")} onChange={(e) => updateState(e.target.value, "password")} />
        </div>

        {/* Confirm Password */}
        <div className="sm:w-1/2 w-full px-2">
          <Input autoComplete="new-password" type="password" name="confirm-password" label="Confirm password" value={getValue("confirm_password")} onChange={(e) => updateState(e.target.value, "confirm_password")} />
        </div>

        <div className="sm:w-1/2 w-full px-2">
          {/* Groups */}
          {groups && (
            <MultiSelectObject
              defaultOptions={groups.map((group) => {
                return { key: group._id, value: group.name, selected: false, object: group };
              })}
              searchableFields={["name"]}
              title="Assign groups"
              onChange={handleSelectedGroups}
            />
          )}
          {/* Notification */}
          {/* <div className="relative flex flex-col pt-3.5">
            <div className="flex justify-between text-sm font-medium text-gray-700 mb-1">Receive notifications</div>
            <div className="pt-1 flex items-center">
              <input checked={getValue("notifications") === true} onChange={(e) => updateNotification(e, "notifications")} aria-describedby="notifications-description" name="notifications" type="checkbox" className="h-5 w-5 border-gray-300 rounded text-highlightColor focus:ring-highlightColor" />
              <p className="ml-3 text-sm text-gray-500">Get notified when company updates occur.</p>
            </div>
          </div> */}
        </div>
      </div>
    </FormContainer>
  );
};

export default AddUserForm;
