import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { H1 } from "src/components/Shared/Text/Headers";
import WorkspaceList from "src/components/Workspaces/WorkspaceList";

const Workspaces = ({ user }) => {
  const [viewOnly, setViewOnly] = useState(true);

  useEffect(() => {
    if (user?._id) {
      if (user?.type === "admin") {
        setViewOnly(false);
      }
    }
  }, [user]);
  return (
    <>
      <H1>Workspaces</H1>
      <WorkspaceList viewOnly={viewOnly} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    workspaces: Object.values(state.workspaces),
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(Workspaces);
