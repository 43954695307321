import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { apiUrl } from "src/config/host";

const useFetch = (url, options = {}) => {
  let accessToken = localStorage.getItem("access_token");
  let headers = { "Content-Type": "application/json", Accept: "application/json" };
  if (accessToken) {
    headers = {
      ...headers,
      Authorization: accessToken,
    };
  }

  const [response, setResponse] = useState({ data: [], meta: {} });
  const [status, setStatus] = useState({ idle: true, fetching: false, done: false, fail: false });
  const optionsRef = useRef({
    headers,
    ...options,
  });

  const requestData = () => {
    setStatus({ idle: false, fetching: true, done: false, fail: false });
    (async () => {
      try {
        const response = await axios(apiUrl + url, { ...optionsRef.current, params: options.query });
        setResponse(response.data);
        setStatus({ idle: false, fetching: false, done: true, fail: false });
      } catch (e) {
        setStatus({ idle: false, fetching: false, done: false, fail: true });
      }
    })();
  };

  useEffect(() => {
    requestData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const refreshData = () => {
    requestData();
  };

  return { response, status, refreshData };
};

export default useFetch;
