import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getEmailerDataCount } from "src/actions/emailer";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import Search from "src/components/Shared/Forms/Inputs/Search";

const CardEmailerForm = ({ emailers, getEmailerDataCount, paginationLimit, setPaginationLimit, offset, ...props }) => {
  const [keyword, setKeyword] = useState("");
  // const navigate = useNavigate();
  useEffect(() => {
    const ac = new AbortController();

    const loadFilteredEmailer = async () => {
      props.setLoaded(false);

      try {
        const emailerCount = await getEmailerDataCount(
          {
            keyword,
            limit: paginationLimit,
            offset: offset * paginationLimit,
          },
          ac.signal
        );

        props.setCount(emailerCount);

        const availablePages = Math.ceil(emailerCount / paginationLimit);
        props.setPages(availablePages);
        props.setLoaded(true);
      } catch (err) {
        props.setLoaded(true);
        console.dir(err.message);
      }
    };

    loadFilteredEmailer();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, paginationLimit, emailers.length]);

  useEffect(() => {
    const ac = new AbortController();

    const loadFilteredEmailer = async () => {
      props.setLoaded(false);

      try {
        const emailerCount = await getEmailerDataCount(
          {
            keyword,
            limit: paginationLimit,
            offset: 0,
          },
          ac.signal
        );

        props.setCount(emailerCount);

        const availablePages = Math.ceil(emailerCount / paginationLimit);
        props.setPages(availablePages);
        props.setOffset(0);
        props.setLoaded(true);
      } catch (err) {
        props.setLoaded(true);
        console.dir(err.message);
      }
    };

    loadFilteredEmailer();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  return (
    <div className="flex items-center justify-between">
      <div className="flex w-full justify-between">
        <div className="flex w-4/5 gap-x-2">
          <Search
            keyword={keyword}
            setKeyword={setKeyword}
          />
          <div className="hidden gap-x-1 md:flex">
            <SelectMenu
              options={[
                { key: "10 Per Page", value: 10 },
                { key: "20 Per Page", value: 25 },
                { key: "50 Per Page", value: 50 },
                { key: "75 Per Page", value: 75 },
                { key: "100 Per Page", value: 100 },
                { key: "All", value: 1000000000 },
              ]}
              setOption={(option) => {
                setPaginationLimit(option.value);
                props.setOffset(0);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, { getEmailerDataCount })(CardEmailerForm);
