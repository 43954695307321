import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { deleteEmailerData, getEmailerDataCount } from "src/actions/emailer";
import ModalDefault from "src/components/Shared/Modals/ModalDefault";
import PaginationFooter from "src/components/Shared/PaginationFooter";
import CardEmailerForm from "src/components/Emailer/CardEmailerForm";
import EmailerEntry from "src/components/Emailer/EmailerEntry";
import EmailerList from "src/components/Emailer/EmailerList";

const EmailerListSection = ({ emailers, getEmailerDataCount, deleteEmailerData, ...props }) => {
  const [loaded, setLoaded] = useState(false);
  const [count, setCount] = useState(0);
  const [paginationLimit, setPaginationLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pages, setPages] = useState(1);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    const ac = new AbortController();

    const loadEmailer = async () => {
      setLoaded(false);
      const params = { limit: paginationLimit, offset: offset * paginationLimit };

      try {
        const emailerCount = await getEmailerDataCount(params, ac.signal);
        setCount(emailerCount);

        const availablePages = Math.ceil(emailerCount / paginationLimit);
        setPages(availablePages);
        setLoaded(true);
      } catch (err) {
        setLoaded(true);
        console.dir(err.message);
      }
    };

    loadEmailer();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOffsetPageCount = (offset, paginationLimit) => {
    return !offset ? offset + 1 : offset * paginationLimit + 1;
  };

  const removeEmailer = async (e) => {
    e.preventDefault();

    try {
      const message = await deleteEmailerData(deleteId);
      setDeleteId(null);
      toast.success(message);
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <>
      <CardEmailerForm
        emailers={emailers}
        setLoaded={setLoaded}
        offset={offset}
        setCount={setCount}
        setPages={setPages}
        setPaginationLimit={setPaginationLimit}
        setOffset={setOffset}
        paginationLimit={paginationLimit}
      />
      <ModalDefault
        isOpen={!!deleteId}
        closeModal={() => setDeleteId(null)}
        onSubmit={removeEmailer}
        submitText="Delete"
        cancelText="Cancel"
        title="Emailer"
        secondaryTitle="Delete">
        <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete?</div>
      </ModalDefault>
      <AnimatePresence>
        <EmailerList
          setDeleteId={setDeleteId}
          deleteId={deleteId}
          loaded={loaded}
          dataExists={emailers.length > 0}>
          {emailers.length > 0 &&
            emailers.map((item) => (
              <EmailerEntry
                key={item._id}
                email={item}
                deleteId={deleteId}
                setDeleteId={setDeleteId}
              />
            ))}
        </EmailerList>
      </AnimatePresence>
      {(count >= paginationLimit || count === emailers.length) && emailers.length > 0 && loaded && (
        <PaginationFooter
          getOffsetPageCount={getOffsetPageCount}
          offset={offset}
          paginationLimit={paginationLimit}
          setPaginationLimit={setPaginationLimit}
          totalEntriesCount={count}
          tabCount={pages}
          setOffset={setOffset}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    emailers: Object.values(state.emailers),
  };
};

export default connect(mapStateToProps, { getEmailerDataCount, deleteEmailerData })(EmailerListSection);
