import { apiUrl } from "src/config/host";
import { fetchData } from "src/async/fetch";
import { GET_EMAILER_DATA, REMOVE_EMAILER } from "./type";

export const getEmailerDataCount =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("POST", `${apiUrl}/email/list`, body, signal);
      const data = await res.json();

      if (data.status === 200) {
        dispatch({ type: GET_EMAILER_DATA, payload: data.data });
        return data.count;
      } else {
        throw new Error(data.message);
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

export const deleteEmailerData = (id) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/email/remove `, { id });
    const data = await res.json();

    if (data.status === 200) {
      dispatch({ type: REMOVE_EMAILER, payload: id });
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (err) {
    throw new Error(err.message);
  }
};

export const manageEmailerData = async (body) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/emailer/manage`, body);
    const resData = await res.json();
    if (resData.status === 200) {
      return { message: resData.message, data: resData.data, status: resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getEmailerdData =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("POST", `${apiUrl}/emailer/list`, body, signal);
      const data = await res.json();

      if (data.status === 200) {
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
