import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";

const ModalDefault = ({ isOpen = false, closeModal = () => {}, submitText = "Submit", cancelText = "Cancel", title, secondaryTitle = "", children, onSubmit = () => {}, removeSubmit = false, removeClose = false, maxWidth = "sm:max-w-3xl sm:w-full" }) => {
  return (
    <Transition.Root
      show={isOpen}
      as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className={`relative bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 p-0 ${maxWidth}`}>
                <div className="flex justify-between py-4 px-4">
                  <Dialog.Title
                    as="h3"
                    className="text-lg text-left leading-6 font-medium text-gray-900">
                    {title} <span className="text-gray-400">/ {secondaryTitle}</span>
                  </Dialog.Title>
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-200 focus:outline-none"
                    onClick={closeModal}>
                    <span className="sr-only">Close</span>
                    <XMarkIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                    />
                  </button>
                </div>

                <div className="px-4">{children}</div>
                <div className="p-4 flex justify-end space-x-4">
                  {!removeClose && (
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={closeModal}>
                      {cancelText}
                    </button>
                  )}
                  {!removeSubmit && (
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-highlightColor text-base font-medium text-white  focus:outline-none sm:w-auto sm:text-sm"
                      onClick={onSubmit}>
                      {submitText}
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ModalDefault;
