import { KeyIcon, UsersIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteUser, getMenuUserList } from "src/actions/user";
import DataExport from "src/components/Shared/DataExport";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import ModalDefault from "src/components/Shared/Modals/ModalDefault";
import PaginationFooter from "src/components/Shared/PaginationFooter";
import Table from "src/components/Shared/Table/Table";
import TRHeader from "src/components/Shared/Table/TRHeader";
import UserEntry from "src/components/Users/List/UserEntry";
import Button from "src/components/Shared/Buttons/Button";
import IconsWithPlus from "src/components/Shared/Icons/IconsWithPlus";
import TableOptions from "src/components/Shared/TableOptions";
import { fetchData } from "src/async/fetch";
import { apiUrl } from "src/config/host";
import Search from "src/components/Shared/Search";

const UsersList = ({ title = "", filterUserType = [], users = [], ...props }) => {
  const [loaded, setLoaded] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  // Search
  const [keyword, setKeyword] = useState("");
  const [status, setStatus] = useState("");
  const [group] = useState("");

  // Pagination
  const [totalEntriesCount, setTotalEntriesCount] = useState(0);
  const [paginationLimit, setPaginationLimit] = useState(10);
  const [paginationOffset, setPaginationOffset] = useState(0);
  const [paginationTabCount, setPaginationTabCount] = useState(1);

  const navigate = useNavigate();

  const loadUsers = async ({ params = {}, ac = {} }) => {
    params = { type: filterUserType, limit: paginationLimit, offset: paginationOffset * paginationLimit, keyword: keyword, status: status, group: group, ...params };
    try {
      const { meta } = await props.getMenuUserList({ params }, ac.signal);
      const numberOfPaginationTabsRequired = Math.ceil(meta.count / params.limit);
      setTotalEntriesCount(meta.count);
      setPaginationTabCount(numberOfPaginationTabsRequired);
      setLoaded(true);
    } catch (error) {
      setLoaded(true);
      console.dir(error.message);
    }
  };

  const handlePaginationChange = ({ limit, offset }) => {
    setPaginationLimit(limit);
    setPaginationOffset(offset);
  };

  useEffect(() => {
    setTimeout(() => loadUsers(false), 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, paginationLimit, paginationOffset, status, group]);

  useEffect(() => {
    const ac = new AbortController();
    setLoaded(false);
    loadUsers({ ac });

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeUser = async (e) => {
    e.preventDefault();

    try {
      const message = await props.deleteUser(deleteId);
      setDeleteId(null);
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const prepData = async () => {
    const params = { status: status, group: group, keyword: keyword };
    if (filterUserType.length) params.filterTypes = filterUserType.toString();
    try {
      const res = await fetchData("POST", `${apiUrl}/users/list`, params);
      const { data } = await res.json();

      let exportDoc = [];
      if (data.length > 0) {
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          let groupName = [];
          if (element.groups.length > 0) {
            for (let i = 0; i < element.groups.length; i++) {
              const grup = element.groups[i];
              groupName.push(grup.name);
            }
          }
          exportDoc.push({
            Name: element.name,
            Email: element.email,
            Type: element.type,
            Groups: groupName.toString(),
            "Date Created": moment(new Date(element.created_at)).format("MM/DD/YYYY HH:mm:ss"),
            "Last Login": element.last_login ? moment(new Date(element.last_login)).format("MM/DD/YYYY HH:mm:ss") : "n/a",
            "Is Active": element.active_status ? "true" : "false",
            "Deactivation Dated": !element.active_status ? moment(new Date(element.deactivated_at)).format("MM/DD/YYYY HH:mm:ss") : "n/a",
          });
        }
      }
      return exportDoc;
    } catch (error) {
      console.dir(error.message);
    }
  };

  return (
    <>
      <TableOptions
        leftContent={
          <Search
            keyword={keyword}
            setKeyword={setKeyword}
          />
        }
        middleContent={
          <>
            <SelectMenu
              options={[
                { key: "All Status", value: "" },
                { key: "Active", value: "true" },
                { key: "Inactive", value: "false" },
              ]}
              setOption={(option) => {
                if (option.value) {
                  setStatus(option.value === "true");
                } else {
                  setStatus(option.value);
                }
              }}
            />
          </>
        }
        rightContent={
          <Button
            styleType="secondary"
            onClick={() => navigate(title === "Admin" ? `/admins/add` : `/users/add`)}>
            {`Add ${title}`}
            <IconsWithPlus
              strokeColor={"stroke-highlightColor"}
              item={{ icon: title === "Admin" ? KeyIcon : UsersIcon }}
            />
          </Button>
        }></TableOptions>
      <Table
        tableHeader={
          <TRHeader>
            <th
              scope="col"
              className="rounded-l-xl py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Name
            </th>
            <th
              scope="col"
              className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Email
            </th>
            <th
              scope="col"
              className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Status
            </th>
            <th
              scope="col"
              className="rounded-r-xl relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Edit</span>
            </th>
          </TRHeader>
        }
        colSpan="4"
        setDeleteId={setDeleteId}
        deleteId={deleteId}
        loaded={loaded}
        dataExists={users.length > 0}>
        {users.length &&
          users.reduce((filteredUsers, user) => {
            return filterUserType.includes(user.type) && filteredUsers.length < paginationLimit
              ? [
                  ...filteredUsers,
                  <UserEntry
                    key={user._id}
                    user={user}
                    deleteId={deleteId}
                    setDeleteId={setDeleteId}
                  />,
                ]
              : filteredUsers;
          }, [])}
      </Table>
      <PaginationFooter
        itemName={title === "Admin" ? `Admin` : `User`}
        entryCount={users.length}
        totalEntriesCount={totalEntriesCount}
        loaded={loaded}
        limit={paginationLimit}
        offset={paginationOffset}
        tabCount={paginationTabCount}
        onChange={handlePaginationChange}
      />
      <DataExport
        title="user_list_report"
        headings={["Name", "Email", "Type", "Groups", "Date Created", "Last Login", "Is Active", "Deactivated Date"]}
        prepData={prepData}
      />
      <ModalDefault
        isOpen={!!deleteId}
        closeModal={() => setDeleteId(null)}
        onSubmit={removeUser}
        submitText="Delete"
        cancelText="Cancel"
        title="User"
        secondaryTitle="Delete">
        <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete {users.length && users.find((user) => user._id === deleteId)?.name}?</div>
      </ModalDefault>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    users: Object.values(state.users),
  };
};

export default connect(mapStateToProps, {
  getMenuUserList,
  deleteUser,
})(UsersList);
