import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { getUsers } from "src/actions/user";
import { userPermissionCheckboxManage, userPermissionList, userPermissionManage } from "src/actions/workspace";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import WorkspaceAddUserModal from "src/components/Workspaces/WorkspaceAddUserModal";
import { classNames } from "src/helpers/classNames";
import WorkspacePermissionTabData from "src/components/Workspaces/Permissions/WorkspacePermissionTabData";

const WorkspacePermissions = ({ workspaceId, ...props }) => {
  const [loaded, setLoaded] = useState(false);
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedTab, setSelectedTab] = useState("user");
  const [isAddUserModal, setIsAddUserModal] = useState(false);
  const tabs = [
    { name: "Users", code: "user", disabledSections: ["create", "delete"] },
    { name: "Groups", code: "group" },
    { name: "Pages", code: "page" },
    { name: "Permissions", code: "permission" },
    { name: "Layout & Styles", code: "layout", disabledSections: ["create"] },
    { name: "Integrations", code: "integration" },
  ];

  useEffect(() => {
    if (workspaceId) {
      const ac = new AbortController();
      const loadData = async () => {
        const users = await props.userPermissionList({ workspace_id: workspaceId }, ac.signal);
        setUserList(users);
        setLoaded(true);
      };
      loadData();
      return () => ac.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId]);

  const getUsers = async () => {
    try {
      setLoaded(false);
      const users = await props.userPermissionList({ workspace_id: workspaceId });
      setUserList(users);
      setLoaded(true);
    } catch (error) {
      console.dir(error);
    }
  };

  useEffect(() => {
    let userId = [];
    for (let index = 0; index < userList.length; index++) {
      const element = userList[index];
      userId.push(element?.user_id?._id);
    }
    setSelectedUser(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userList]);

  const userAdd = async () => {
    try {
      const data = await props.userPermissionManage({ user_id: selectedUser, workspace_id: workspaceId });
      getUsers();
      setIsAddUserModal(false);
      setSelectedUser([]);
      toast.success(data.message);
    } catch (error) {
      setIsAddUserModal(false);
      setSelectedUser([]);
      toast.success(error.message);
    }
  };

  const handleSinglePermissionChange = async (e, users) => {
    try {
      let permission = { [e.target.name]: e.target.checked }
      let permissions = [];
      let resetUserList = [];
      for (let i = 0; i < userList.length; i++) {
        const user = userList[i];
        let index2 = users.findIndex((usr) => usr._id === user._id);
        if (index2 !== -1) {
          permissions.push({ _id: users[index2]._id, [e.target.name]: e.target.checked });
          resetUserList.push({ ...user, [e.target.name]: e.target.checked });
        } else {
          resetUserList.push(user);
        }
      }

      setUserList(resetUserList);
      await props.userPermissionUpdate({ permissions: permissions, workspace_id: workspaceId });
    } catch (error) {}
  };

  const handleMultiplePermissionChanges = async (e, users) => {
    try {
      let permissions = [];
      let resetUserList = [];
      for (let i = 0; i < userList.length; i++) {
        const user = userList[i];
        let index2 = users.findIndex((usr) => usr._id === user._id);
        if (index2 !== -1) {
          permissions.push({ _id: users[index2]._id, [e.target.name]: e.target.checked });
          resetUserList.push({ ...user, [e.target.name]: e.target.checked });
        } else {
          resetUserList.push(user);
        }
      }
      setUserList(resetUserList);
      await props.userPermissionCheckboxManage({ permissions: permissions, workspace_id: workspaceId });
    } catch (error) {}
  };

  return (
    <>
      <div className="relative w-full rounded-md space-y-4 h-auto">
        {loaded ? (
          <>
            <div className="relative w-full max-w-5xl shadow rounded-md bg-white border border-slate-100">
              <div className="relative mb-5 bg-slate-100">
                <nav
                  className="flex"
                  aria-label="Tabs">
                  {tabs.map((tab) => (
                    <div
                      key={tab.code}
                      onClick={() => setSelectedTab(tab.code)}
                      className={classNames(tab.code === selectedTab ? "!bg-white text-slate-800 " : "text-slate-400 hover:bg-slate-50 hover:text-slate-600", "py-4 px-6 bg-transparent w-full flex justify-center items-center rounded-t-md cursor-pointer")}
                      aria-current={tab.code === selectedTab ? "page" : undefined}>
                      <span className="font-semibold">{tab.name}</span>
                    </div>
                  ))}
                </nav>
              </div>
              <div className="mt-4">
                <WorkspacePermissionTabData
                  workspaceId={workspaceId}
                  isAddUserModal={isAddUserModal}
                  setIsAddUserModal={setIsAddUserModal}
                  userList={userList}
                  disabledColumns={tabs.filter((tab) => tab.code === selectedTab)[0].disabledSections}
                  selectedTab={selectedTab}
                  handleSinglePermissionChange={handleSinglePermissionChange}
                  handleMultiplePermissionChanges={handleMultiplePermissionChanges}
                />
              </div>
            </div>
          </>
        ) : (
          <Preloader />
        )}
      </div>
      <WorkspaceAddUserModal
        isPermissionPage={true}
        isOpen={isAddUserModal}
        setIsOpen={setIsAddUserModal}
        pSelectedUser={selectedUser}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        onSubmit={userAdd}
        users={userList}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};
export default connect(mapStateToProps, { userPermissionList, userPermissionManage, getUsers, userPermissionCheckboxManage })(WorkspacePermissions);
