import { apiUrl } from "src/config/host";
import { fetchData } from "src/async/fetch";
import {
  GET_PAGE_DATA,
  GET_PAGE_CREDENTIAL_OPTIONS,
  GET_WORKSPACE_DETAILS,
  CLEAR_WORKSPACE_DETAILS,
  // REMOVE_PAGE
} from "./type";

export const setPageData = (body) => (dispatch) => {
  dispatch({ type: GET_PAGE_DATA, payload: body });
};

export const managePageData = (body) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/pages/manage`, body);
    const resData = await res.json();

    if (resData.status === 200) {
      dispatch({ type: GET_PAGE_DATA, payload: resData.data });
      return { message: resData.message, data: resData.data };
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getPages =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("POST", `${apiUrl}/pages/list`, body, signal);
      const data = await res.json();

      if (data.status === 200) {
        dispatch({ type: GET_PAGE_DATA, payload: data.data });
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.dir("error");
      throw new Error(error.message);
    }
  };

export const removePage = (id) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/pages/delete`, { id });
    const data = await res.json();

    if (data.status === 200) {
      // dispatch({ type: REMOVE_PAGE, payload: id });
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getGroupPermissionData = async (groupIds) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/pages/groups/permissions`, { groupIds });
    const data = await res.json();

    if (data.status === 200) {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getPageCredentialOptions =
  (params, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("GET", `${apiUrl}/pages/credentials`, signal);

      const resData = await res.json();

      if (resData.status === 200) {
        dispatch({ type: GET_PAGE_CREDENTIAL_OPTIONS, payload: resData.data });
        return resData.data;
      } else {
        throw new Error(resData.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const managePageCredentialOptions =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("POST", `${apiUrl}/pages/credentials/manage`, body, signal);

      const resData = await res.json();

      if (resData.status === 200) {
        dispatch({ type: GET_PAGE_CREDENTIAL_OPTIONS, payload: resData.data });
        return resData;
      } else {
        throw new Error(resData.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const deletePageCredentialOptions =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      await fetchData("DELETE", `${apiUrl}/workspaces/${body.workspace_id}/pages/credentials/${body.credential_id}`, signal);
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const getDomoWorkspaceList = async (body) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/portal/workspaces/list`, body);
    const data = await res.json();
    return data.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getDomoWorkspaceFirstPage = async (body) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/portal/workspaces/first/page`, body);
    const data = await res.json();
    return data.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

// export const getDomoPageList = async (body) => {
//   try {
//     const res = await fetchData("POST", `${apiUrl}/portal/pages/list`, body);
//     const data = await res.json();
//     return data;
//   } catch (error) {
//     throw new Error(error.message);
//   }
// };

export const getDomoPageList = (body) => async (dispatch) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/portal/pages/list`, body);
    const resData = await res.json();

    if (resData.status === 200) {
      dispatch({ type: GET_WORKSPACE_DETAILS, payload: resData.data });
      return resData.menu;
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const resetWorkspaceDetails = (body) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_WORKSPACE_DETAILS, payload: {} });
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getDomoPageDetails = async (body) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/portal/page/details`, body);
    const data = await res.json();

    if (data.status === 200) {
      return data;
    } else {
      return data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getLandingPageDetails = async (body) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/portal/landing/page/details`, body);
    const data = await res.json();

    if (data.status === 200) {
      return data;
    } else {
      return data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getPreviousPageDetails = async (body) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/previous/page/details`, body);
    const data = await res.json();

    if (data.status === 200) {
      return data;
    } else {
      return data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};