import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import React, { useEffect } from "react";
import { useState } from "react";
import FrontLabeledInput from "src/components/Shared/Forms/Inputs/FrontLabeledInput";
import Input from "src/components/Shared/Forms/Inputs/Input";

import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { classNames } from "src/helpers/classNames";

const WorkspaceIntegrationClientsItem = ({ user, workspaceId, state, setState = () => {}, removePageCredential }) => {
  const [viewOnly, setViewOnly] = useState(true);
  const [integrationStatus, setIntegrationStatus] = useState("INCOMPLETE_DATA");
  const [existingData, setExistingData] = useState(state?.name && state?.client_id && state?.client_secret);

  useEffect(() => {
    let authResponse = authorizeUserComponentAccess(user, workspaceId, "integration", ["update"]);
    setViewOnly(!authResponse);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (e, name) => {
    let existingValues = {};
    if (e.target.value) {
      existingValues = { name: state?.name, client_id: state?.client_id, client_secret: state?.client_secret };
      existingValues[name] = e.target.value;
    }
    if (existingValues.name && existingValues.client_id && existingValues.client_secret) {
      setIntegrationStatus("COMPLETED_DATA");
    } else {
      setExistingData(false);
      setIntegrationStatus("INCOMPLETE_DATA");
    }
  };

  return (
    <div className="w-full h-full relative bg-gray-50/70 py-3 px-4">
      {!existingData && (
        <div className={classNames("absolute flex top-1 right-1 items-center gap-x-1 px-1 py-[2px] rounded transition-colors duration-100", integrationStatus === "INCOMPLETE_DATA" ? "bg-red-50 text-red-300" : "bg-green-50 text-green-300 border-[1px] border-green-200")}>
          {integrationStatus === "INCOMPLETE_DATA" ? (
            <>
              <XMarkIcon className="h-5 w-5" />
              Incomplete Credential
            </>
          ) : (
            <>
              <CheckIcon className="h-5 w-5" />
              Done
            </>
          )}
        </div>
      )}
      <div className="w-full h-full flex gap-x-2">
        <div className="w-full flex gap-x-2">
          <div className="w-[300px]">
            <Input
              label="Name"
              name="name"
              disabled={viewOnly}
              value={state.name || ""}
              onChange={(e) => {
                handleInputChange(e, "name");
                setState({ ...state, name: e.target.value });
              }}
            />
          </div>
          <div className="w-[calc(100%-300px)] flex mt-1 pt-5 gap-x-2">
            <FrontLabeledInput
              label="Client ID"
              type="password"
              value={state.client_id || ""}
              disabled={viewOnly}
              onChange={(e) => {
                handleInputChange(e, "client_id");
                setState({ ...state, client_id: e.target.value });
              }}
            />
            <FrontLabeledInput
              label="Client Secret"
              type="password"
              value={state.client_secret || ""}
              disabled={viewOnly}
              onChange={(e) => {
                handleInputChange(e, "client_secret");
                setState({ ...state, client_secret: e.target.value });
              }}
            />
          </div>
        </div>
        <div className="mt-1 pt-5">
          {authorizeUserComponentAccess(user, workspaceId, "integration", ["delete"]) && (
            <div className="h-full flex items-center">
              <button
                type="button"
                className="p-1 hover:bg-gray-100/70 transition-all duration-300 bg-white rounded-md text-gray-400 hover:text-gray-500 border-transparent focus:outline-none border-[2px] hover:border-gray-200"
                onClick={() => removePageCredential(state._id)}>
                <span className="sr-only">Delete</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  className="h-6 w-6">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"></path>
                </svg>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkspaceIntegrationClientsItem;
