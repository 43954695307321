import ButtonWithDropdown from "src/components/Shared/Buttons/ButtonWithDropdown";

const ButtonExportContainer = ({ exportType, setExportType, onSubmit = () => {} }) => {
  return (
    <>
      <div className="relative flex justify-end mt-5">
        <ButtonWithDropdown
          onSubmit={onSubmit}
          buttonText="Export"
          options={[
            { key: ".xls", value: "xls" },
            { key: ".csv", value: "csv" },
          ]}
          setOption={(option) => {
            setExportType(option.value);
          }}
        />
      </div>
    </>
  );
};

export default ButtonExportContainer;
