import SiteColorItem from "src/components/Site/SiteColorItem";
import { noLogo, noFavicon, baseUrl } from "src/config/host";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { HomeIcon } from "@heroicons/react/24/outline";
import { classNames } from "src/helpers/classNames";

const SitePreview = ({ layoutType="LEFT_NAVIGATION", menu, site, logo, favicon, headerColor, headerTextColor, leftNavColor, highlightColor, leftNavTextColor, leftNavActiveColor, leftNavActiveTextColor, ...props }) => {
  const { setHeaderColor, setHeaderTextColor, setLeftNavTextColor, setLeftNavColor, setHighlightColor, setLeftNavActiveTextColor, setLeftNavActiveColor } = props;

  return (
    <>
      <div className="relative">
        <div className="relative w-full hidden sm:flex flex-wrap border border-gray-200 rounded-lg mt-12">
          <div className="absolute -top-9 left-4 h-9 border-[1px] border-gray-200 bg-gray-100 rounded-t-lg flex px-3 justify-between items-center">
            <div className="flex items-center gap-x-3">
              <img
                className="py-[2px] h-6 w-auto"
                src={favicon?.includes(".s3.") ? favicon : favicon?.includes("data:image/") ? favicon : baseUrl + favicon}
                onError={(e) => (e.target.src = `${noFavicon}`)}
                alt="logo"
              />
              <p className="text-gray-600 mr-4 text-center">{site.site_name ? site.site_name : site.name}</p>
            </div>
            <XMarkIcon className="h-4 w-4 text-gray-600" />
          </div>
          <div
            style={{ backgroundColor: headerColor }}
            className="relative w-full flex items-center justify-between h-16 px-6 rounded-t-lg">
            <img
              className="py-[2px] h-10 w-auto"
              src={logo?.includes(".s3.") ? logo : logo?.includes("data:image/") ? logo : baseUrl + logo}
              onError={(e) => (e.target.src = `${noLogo}`)}
              alt="logo"
            />
            <div className="flex items-center gap-x-2">
              <div
                style={{ backgroundColor: headerTextColor }}
                className="h-4 rounded w-24"></div>
              <div className="rounded-full w-6 h-6 bg-gray-100"></div>
            </div>
            <div className="absolute z-40 -top-12 left-60 bg-white flex flex-col w-[160px] border border-gray-100 py-2 px-2 rounded-xl drop-shadow-md before:content-[''] before:absolute before:-bottom-2 before:left-4 before:w-4 before:h-4 before:bg-white before:rotate-[45deg] before:-z-10 before:border before:border-gray-100 before:border-l-0 before:border-t-0">
              <SiteColorItem
                onChange={setHeaderColor}
                color={headerColor}
                name="Header"
              />
            </div>
            <div className="absolute z-10 -top-11 right-14 bg-white flex flex-col w-[160px] border border-gray-100 py-2 px-2 rounded-xl drop-shadow-md before:content-[''] before:absolute before:-bottom-2 before:right-4 before:w-4 before:h-4 before:bg-white before:rotate-[45deg] before:-z-10 before:border before:border-gray-100 before:border-l-0 before:border-t-0">
              <SiteColorItem
                onChange={setHeaderTextColor}
                color={headerTextColor}
                name="Header text"
              />
            </div>
          </div>
          <div
            style={{ backgroundColor: leftNavColor }}
            className={"relative p-4 " + (layoutType === "LEFT_NAVIGATION" ? "w-3/12 rounded-bl-lg space-y-4" : "w-full flex space-x-4")}>
            {layoutType === "NO_NAVIGATION" ? (
              <div
                style={{ color: leftNavTextColor }}
                className="h-5 py-4 px-3 rounded font-medium flex items-center">
                <HomeIcon
                  className="h-6 w-6"
                  style={{ color: leftNavTextColor }}
                />
              </div>
            ) : (
              <>
                <div
                  style={{ color: leftNavActiveTextColor, backgroundColor: leftNavActiveColor }}
                  className="h-5 py-4 px-3 font-medium flex items-center rounded">
                  Pages
                </div>
                {menu.map((mnu) => {
                  return (
                    <div
                      key={mnu}
                      style={{ color: leftNavTextColor }}
                      className="h-5 py-4 px-3 rounded font-medium flex items-center">
                      {mnu}
                    </div>
                  );
                })}
              </>
            )}

            {layoutType !== "NO_NAVIGATION" && (
              <div
                className={
                  "absolute z-30 bg-white flex flex-col w-[160px] border border-gray-100 gap-y-4 py-2 px-2 rounded-xl drop-shadow-md before:content-[''] before:absolute before:-top-2 before:left-4 before:w-4 before:h-4 before:bg-white before:rotate-[45deg] before:-z-10 before:border before:border-gray-100 before:border-r-0 before:border-b-0 " +
                  (layoutType === "TOP_NAVIGATION" ? "top-14 left-0" : "top-8 left-12")
                }>
                <SiteColorItem
                  onChange={setLeftNavActiveColor}
                  color={leftNavActiveColor}
                  name="Navigation Active"
                />
                <SiteColorItem
                  onChange={setLeftNavActiveTextColor}
                  color={leftNavActiveTextColor}
                  name="Navigation Active Text"
                />
              </div>
            )}

            <div
              className={classNames(
                "absolute z-20 bg-white flex flex-col w-[160px] border border-gray-100 py-2 px-2 rounded-xl drop-shadow-md before:content-[''] before:absolute before:-top-2 before:left-4 before:w-4 before:h-4 before:bg-white before:rotate-[45deg] before:-z-10 before:border before:border-gray-100 before:border-r-0 before:border-b-0 ",
                layoutType === "TOP_NAVIGATION" && "top-14 left-72",
                layoutType === "LEFT_NAVIGATION" && "top-[150px] left-6",
                layoutType === "NO_NAVIGATION" && "top-[44px] left-0"
              )}>
              <SiteColorItem
                onChange={setLeftNavTextColor}
                color={leftNavTextColor}
                name="Navigation text"
              />
            </div>
            <div
              className={classNames(
                "absolute z-10 bg-white flex flex-col w-[160px] border border-gray-100 py-2 px-2 rounded-xl drop-shadow-md before:content-[''] before:absolute before:-top-2 before:left-4 before:w-4 before:h-4 before:bg-white before:rotate-[45deg] before:-z-10 before:border before:border-gray-100 before:border-r-0 before:border-b-0 ",
                layoutType === "TOP_NAVIGATION" && "top-14 right-12",
                layoutType === "LEFT_NAVIGATION" && "top-[310px] left-12",
                layoutType === "NO_NAVIGATION" && "top-8 right-96"
              )}>
              <SiteColorItem
                onChange={setLeftNavColor}
                color={leftNavColor}
                name="Navigation background"
              />
            </div>
          </div>

          <div className={"relative bg-gray-50 space-y-4 p-4 rounded-br-lg " + (layoutType === "NO_NAVIGATION" || layoutType === "TOP_NAVIGATION" ? "w-full" : "w-9/12")}>
            <div
              className={classNames(
                "absolute z-20 bg-white flex flex-col w-[160px] border border-gray-100 py-2 px-2 rounded-xl drop-shadow-md before:content-[''] before:absolute before:-top-2 before:left-4 before:w-4 before:h-4 before:bg-white before:rotate-[45deg] before:-z-10 before:border before:border-gray-100 before:border-r-0 before:border-b-0",
                layoutType === "TOP_NAVIGATION" && "top-60 left-32",
                layoutType === "LEFT_NAVIGATION" && "top-16 left-32",
                layoutType === "NO_NAVIGATION" && "top-60 left-56"
              )}>
              <SiteColorItem
                onChange={setHighlightColor}
                color={highlightColor}
                name="Highlight"
              />
            </div>

            <div
              style={{ backgroundColor: highlightColor }}
              className={"h-4 rounded " + (layoutType !== "TOP_NAVIGATION" ? "w-48" : "bg-gray-400")}></div>
            <div className="bg-gray-400 h-4 rounded"></div>
            <div className="bg-gray-400 h-4 rounded"></div>
            <div className="bg-gray-400 h-4 w-48 rounded"></div>
            <div className="h-12"></div>
            <div
              style={{ backgroundColor: highlightColor }}
              className={"h-4 rounded " + (layoutType === "TOP_NAVIGATION" ? "w-48" : "bg-gray-400")}></div>
            <div className="bg-gray-400 h-4 rounded"></div>
            <div className="bg-gray-400 h-4 w-48 rounded"></div>
            <div className="h-48"></div>
          </div>
        </div>
      </div>
      <div className="relative flex flex-col sm:hidden">
        <div className="mb-4">
          <SiteColorItem
            onChange={setHeaderColor}
            color={headerColor}
            name="Header"
          />
        </div>
        <div className="mb-4">
          <SiteColorItem
            onChange={setHeaderTextColor}
            color={headerTextColor}
            name="Header text"
          />
        </div>
        <div className="mb-4">
          <SiteColorItem
            onChange={setLeftNavColor}
            color={leftNavColor}
            name="Navigation background"
          />
        </div>
        <div className="mb-4">
          <SiteColorItem
            onChange={setLeftNavTextColor}
            color={leftNavTextColor}
            name="Navigation text"
          />
        </div>
        <div className="mb-4">
          <SiteColorItem
            onChange={setLeftNavActiveColor}
            color={leftNavActiveColor}
            name="Navigation Active"
          />
        </div>
        <div className="mb-4">
          <SiteColorItem
            onChange={setLeftNavActiveTextColor}
            color={leftNavActiveTextColor}
            name="Navigation Active Text"
          />
        </div>
        <div className="mb-4">
          <SiteColorItem
            onChange={setHighlightColor}
            color={highlightColor}
            name="Highlight"
          />
        </div>
      </div>
    </>
  );
};

export default SitePreview;
