import { useEffect, useState } from "react";
import { builderQueryString } from "src/async/apiUtils";
import { apiUrl } from "src/config/host";
import axios from "axios";

const useLoadData = (httpMethod = "post", url = "", payload = { body: null, queries: null }, onSuccess = null, loaded = false, setLoaded = () => {}) => {
  const [firstLoad, setFirstLoad] = useState(true);

  const loadData = async () => {
    try {
      let queryString = builderQueryString(payload.queries);

      let accessToken = localStorage.getItem("access_token");

      let headers = { "Content-Type": "application/json", Accept: "application/json" };

      if (accessToken) {
        headers = {
          ...headers,
          Authorization: accessToken,
        };
      }
      let request = axios.create({
        baseURL: apiUrl,
        headers,
      });

      const res = await request[httpMethod](`${url}${queryString}`, payload.body);

      if (res.status === 200) {
        setFirstLoad(false);
        setLoaded(true);
        if (onSuccess) {
          return onSuccess(res.data);
        }
      } else {
        throw new Error(res.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  useEffect(() => {
    if (firstLoad || !loaded) {
      loadData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, firstLoad]);
};

export { useLoadData };
