import React from "react";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";
import UserProfile from "src/components/Users/UserProfile";
import moment from "moment";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";

const WorkspacePermissionTabDataItem = ({ workspaceId, workspaceDetails, disabledColumns, userPermission, user, selectedTab, permissionChange = () => {} }) => {
  const formatMemberDate = (date) => {
    if (!date) return "";
    return moment(new Date(date)).format("MM/DD/YYYY");
  };

  const insertTypes = {
    SSO_LOGIN: "SSO",
    MANUAL_INPUT: "Manually",
    BULK_UPLOAD: "Bulk",
  };

  const handlePermissionChange = (e, user) => {
    let authorized = authorizeUserComponentAccess(userPermission, workspaceId, "permission", ["update"]);
    if (authorized) {
      permissionChange(e, user);
    }
  };

  return (
    <>
      <div className="flex items-center gap-4 py-4 even:bg-slate-50 odd:bg-white">
        <div className="w-full min-w-[320px]">
          <div className="flex items-center gap-3 px-4">
            <div className="w-10 h-10 min-w-[40px] min-h-[40px] flex items-center justify-center font-bold uppercase bg-slate-200 rounded-full">
              <UserProfile user={user?.user_id} />
            </div>
            <div className="w-full">
              <div className="text-slate-800 font-semibold">{user?.user_id?.name}</div>
              <div className="text-sm text-slate-400">
                {insertTypes[user.creation_type]} created {formatMemberDate(user.created_at)}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2 items-center w-16 min-w-[64px]">
          <Checkbox
            disabled={disabledColumns.includes("create")}
            checkboxName={selectedTab + "_create"}
            isChecked={user[selectedTab + "_create"]}
            onChange={handlePermissionChange}
          />
        </div>
        <div className="flex flex-col gap-2 items-center w-16 min-w-[64px]">
          <Checkbox
            disabled={disabledColumns.includes("update")}
            checkboxName={selectedTab + "_update"}
            isChecked={user[selectedTab + "_update"]}
            onChange={handlePermissionChange}
          />
        </div>
        <div className="flex flex-col gap-2 items-center w-16 min-w-[64px]">
          <Checkbox
            disabled={disabledColumns.includes("delete")}
            checkboxName={selectedTab + "_delete"}
            isChecked={user[selectedTab + "_delete"]}
            onChange={handlePermissionChange}
          />
        </div>
        <div className="w-full"></div>
      </div>
    </>
  );
};

export default WorkspacePermissionTabDataItem;
