import HorizonatalBarAccordian from "src/components/Shared/Accordians/HorizonatalBarAccordian";
import WorkspaceIntegrationClients from "src/components/Workspaces/Integrations/WorkspaceIntegrationClients";
import WorkspaceIntegrationFilters from "src/components/Workspaces/Integrations/WorkspaceIntegrationFilters";

const WorkspaceIntegrationItem = ({ workspaceId, integration, ...props }) => {
  return (
    <HorizonatalBarAccordian
      headerContent={
        <div className="flex items-center">
          <div className="w-12 h-12 min-w-[48px] min-h-[48px] overflow-hidden">
            <img
              src={"https://www.domo.com/assets/images/domo-logos/domo-svg.svg"}
              alt={"Domo"}
            />
          </div>
          <div className="w-full px-4">
            <div className="text-slate-400">{integration.name}</div>
          </div>
        </div>
      }>
      <div className="relative  space-y-6">
        <WorkspaceIntegrationClients
          integration={integration}
          workspaceId={workspaceId}
        />
        <WorkspaceIntegrationFilters
          integration={integration}
          workspaceId={workspaceId}
        />
      </div>
    </HorizonatalBarAccordian>
  );
};

export default WorkspaceIntegrationItem;
