import React from "react";

export default function ToggleHeader({ title = "", subtitle = "", children }) {
  return (
    <div className="font-medium text-gray-600 mb-4 flex gap-x-5">
      <div>
        <p>{title}</p>
        <p className="text-gray-400 text-sm">{subtitle}</p>
      </div>
      {children}
    </div>
  );
}
