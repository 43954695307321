import Table from "src/components/Shared/Table/Table";
import TableBody from "src/components/Shared/Table/TableBody";
import TableBodyLoader from "src/components/Shared/Table/TableBodyLoader";
import TRHeader from "src/components/Shared/Table/TRHeader";

const EmailerList = ({ ...props }) => {
  return (
    <Table>
      <TRHeader>
        <th scope="col" className="rounded-l-xl overflow-hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
          Email To
        </th>
        <th scope="col" className="rounded-l-xl overflow-hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
          Subject
        </th>
        <th scope="col" className="rounded-l-xl overflow-hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
          Content
        </th>
        <th scope="col" className="rounded-r-xl overflow-hidden  hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
      </TRHeader>
      <TableBody>
        <TableBodyLoader props={props} colSpan="5">
          {props.children}
        </TableBodyLoader>
      </TableBody>
    </Table>
  );
};

export default EmailerList;
