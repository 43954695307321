import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";

const Dropdown = ({ addPage = () => {} }) => {
  return (
    <>
      <Menu
        as="div"
        className="relative inline-block text-left">
        <div>
          <Menu.Button className="h-full inline-flex items-center justify-center transition-all duration-200 rounded-md px-4 py-2 text-regular font-medium focus:ring-2 focus:border-highlightColor  bg-white text-highlightColor border-[1px] border-highlightColor hover:shadow-sm sm:w-auto gap-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="w-6 h-6">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 8.25V18a2.25 2.25 0 002.25 2.25h13.5A2.25 2.25 0 0021 18V8.25m-18 0V6a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 6v2.25m-18 0h18M5.25 6h.008v.008H5.25V6zM7.5 6h.008v.008H7.5V6zm2.25 0h.008v.008H9.75V6z"></path>
            </svg>
            <div className="flex items-center gap-x-4">Create page</div>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className="absolute z-50 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1">
              <Menu.Item>
                <button
                  type="button"
                  onClick={() => {
                    addPage("DOMO");
                  }}
                  className={"group flex w-full items-center rounded-md px-3 py-2 gap-x-4 text-base"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8 13v-1m4 1v-3m4 3V8M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
                    />
                  </svg>
                  Dashboard
                </button>
              </Menu.Item>
              <Menu.Item>
                <button
                  onClick={() => {
                    addPage("CUSTOM_HTML");
                  }}
                  type="button"
                  className={"relative z-50 bg-white group flex w-full items-center rounded-md px-3 py-2 gap-x-4 text-base"}>
                  <i className="far fa-fw fa-code"></i>
                  Custom HTML
                </button>
              </Menu.Item>
              <Menu.Item>
                <button
                  onClick={() => {
                    addPage("LANDING_PAGE");
                  }}
                  type="button"
                  className={"relative z-50 bg-white group flex w-full items-center rounded-md px-3 py-2 gap-x-4 text-base"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="w-6 h-6">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 8.25V18a2.25 2.25 0 002.25 2.25h13.5A2.25 2.25 0 0021 18V8.25m-18 0V6a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 6v2.25m-18 0h18M5.25 6h.008v.008H5.25V6zM7.5 6h.008v.008H7.5V6zm2.25 0h.008v.008H9.75V6z"></path>
                  </svg>
                  Landing Page
                </button>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default Dropdown;
