import React, { useState } from "react";
import Input from "src/components/Shared/Forms/Inputs/Input";
import ModalDefault from "src/components/Shared/Modals/ModalDefault";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { fetchData } from "src/async/fetch";
import { apiUrl } from "src/config/host";

const WorkspaceAddModal = ({ isOpen, setIsOpen, ...props }) => {
  const [name, setName] = useState("");

  const createWorkspace = async () => {
    try {
      const res = await fetchData("POST", `${apiUrl}/workspaces/add`, { name });
      const data = await res.json();
      await props.loadDataAfterAdded();
      setName("");
      setIsOpen(false);
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <ModalDefault
      isOpen={isOpen}
      closeModal={() => {
        setIsOpen(!isOpen);
      }}
      onSubmit={() => {
        createWorkspace();
      }}
      submitText="Save"
      cancelText="Cancel"
      title="Workspace"
      secondaryTitle="Create"
      maxWidth="w-full max-w-2xl">
      <div className="relative w-full space-y-4">
        <div className="relative w-full mb-4">
          <Input
            name="name"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </div>
    </ModalDefault>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { })(WorkspaceAddModal);
