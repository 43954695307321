import React from "react";
import { H1 } from "src/components/Shared/Text/Headers";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Select from "src/components/Shared/Forms/Selects/Select";
import InputDropBox from "src/components/Shared/Forms/Inputs/InputDropBox";
import { UsersIcon, TrashIcon } from "@heroicons/react/24/outline";
import IconsWithPlus from "src/components/Shared/Icons/IconsWithPlus";

const PageAddNew = () => {
  return (
    <>
      <H1>Add Pages</H1>
      <div className="relative w-full mb-10">
        <div className="mb-2 text-xl font-semibold text-gray-700">Create a Landing Page</div>
        <div className="relative w-full bg-white py-4 px-5 rounded shadow mb-4">
          <div className="relative w-full space-y-4 max-w-3xl">
            <div className="relative w-full mb-4">
              <Input
                name="name"
                label="Name"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="relative w-full">
        <div className="mb-2 text-lg font-semibold text-gray-700">Navigation Blocks</div>
        <div className="relative w-full bg-white py-4 px-5 rounded shadow mb-4">
          <button
            type="button"
            className="absolute top-3 right-3">
            <TrashIcon className="h-5 w-5" />
          </button>
          <div className="relative w-full space-y-4 max-w-3xl">
            <div className="relative w-full mb-4">
              <Input
                name="title"
                label="Title"
              />
            </div>
            <div className="relative w-full flex mb-4 space-x-4">
              <InputDropBox
                name="image"
                label="Image"
              />

              {/* <div className="w-full flex items-end space-x-4">
                <div className="w-full max-w-xs overflow-hidden rounded">
                  <img src="https://images.unsplash.com/photo-1605098096512-f980b1ac818d" alt=""/>
                </div>
                <div className="">
                  <Button styleType={"black"}>Update Image</Button>
                </div>
              </div> */}

              <div className="w-[100px] min-w-[100px] mt-5">
                <div className="relative w-full mb-4">
                  <Input
                    name="height"
                    label="Height"
                  />
                </div>
                <div className="relative w-full mb-4">
                  <Input
                    name="width"
                    label="Width"
                  />
                </div>
              </div>
            </div>

            <div className="relative w-full mb-4">
              <label
                htmlFor="title"
                className="flex justify-between text-sm font-medium text-gray-700 mb-1">
                Links
              </label>
              <div className="flex space-x-4">
                <div className="w-3/6">
                  <Input
                    name="link"
                    label=""
                    placeholder="Link Text"
                  />
                </div>
                <div className="w-2/6">
                  <Select
                    options={[
                      { name: "Option 01", value: "1" },
                      { name: "Option 02", value: "2" },
                      { name: "Option 03", value: "3" },
                      { name: "Option 04", value: "4" },
                    ]}
                    selected={"Page"}
                  />
                </div>
                <div className="w-1/6">
                  <Button styleType={"black"}>
                    <IconsWithPlus
                      strokeColor={"stroke-gray-600"}
                      item={{ icon: UsersIcon }}
                      className={""}
                      size={"h-5"}
                    />
                  </Button>
                </div>
              </div>
            </div>
            <div className="relative w-full mb-4">
              <Button styleType={"black"}>Add Link</Button>
            </div>
          </div>
        </div>
      </div>

      <div className="relative w-full mb-4">
        <Button styleType={"black"}>Add Navigation Block</Button>
      </div>

      <div className="relative w-full mb-4 flex justify-end space-x-4">
        <Button styleType={"gray"}>Cancel</Button>
        <Button styleType={"primary"}>Submit</Button>
      </div>
    </>
  );
};

export default PageAddNew;
