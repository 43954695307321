import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Button from "src/components/Shared/Buttons/Button";
import { workspaceIntegrationFilterCreate, workspaceIntegrationFilterManage, workspaceIntegrationFilterDelete } from "src/actions/workspace";
import WorkspaceIntergrationFiltersItem from "src/components/Workspaces/Integrations/WorkspaceIntergrationFiltersItem";
import { operatorNameConverter } from "src/helpers/operatorNameConverter";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import IconsWithPlus from "src/components/Shared/Icons/IconsWithPlus";
import { FunnelIcon } from "@heroicons/react/24/outline";

const WorkspaceIntegrationFilters = ({ user, workspaceId, integration, ...props }) => {
  const [filters, setFilters] = useState([]);
  const trustedAttributeArr = [
    {
      _id: "user.fullName",
      value: "user.fullName",
      name: "Full Name",
    },
    {
      _id: "user.email",
      value: "user.email",
      name: "Email Address",
    }
  ];

  const valueType = [
    {
      _id: "value",
      value: "value",
      name: "Text Value",
    },
    {
      _id: "attribute",
      value: "attribute",
      name: "User Attribute",
    }
  ];
  useEffect(() => {
    setFilters(integration?.page_filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integration]);

  useEffect(() => {
    const manageFilter = async () => {
      try {
        await props.workspaceIntegrationFilterManage({ filters: filters, workspace_id: workspaceId, integration_id: integration._id });
      } catch (error) {
        console.dir(error.message);
      }
    };
    manageFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const addFilter = async () => {
    try {
      const data = await props.workspaceIntegrationFilterCreate({ integration_id: integration._id, workspace_id: workspaceId });
      setFilters([...filters, data]);
    } catch (error) {
      console.dir(error.message);
    }
  };

  const removeFilter = async (id) => {
    const ac = new AbortController();
    try {
      setFilters(filters.filter((flt) => flt._id !== id));
      await props.workspaceIntegrationFilterDelete({ id, integration_id: integration._id, workspace_id: workspaceId }, ac.signal);
    } catch (error) {}
    return () => ac.abort();
  };

  return (
    <>
      <div className="relative">
        <div className="w-full justify-between flex items-center gap-4">
          <div className="text-base font-medium text-slate-400">Workspace Filters</div>
          {authorizeUserComponentAccess(user, workspaceId, "integration", ["create"]) && (
            <Button
              styleType="black"
              className="group !py-1 !pl-[5px] !pr-[10px]"
              onClick={addFilter}>
              <IconsWithPlus
                strokeColor={"stroke-gray-700 group-hover:stroke-highlightColor"}
                item={{ icon: FunnelIcon }}
              />
            </Button>
          )}
        </div>
        <div className="space-y-3 mt-2 bg-white border border-slate-200 rounded py-3 px-4">
          {filters.length > 0 ? (
            filters.map((flt, index) => {
              return (
                <WorkspaceIntergrationFiltersItem
                  key={integration._id + "_" + index}
                  user={user}
                  workspaceId={workspaceId}
                  state={flt}
                  operators={props.operators?.map((operator) => {
                    return { ...operator, name: operatorNameConverter(operator.name), id: operator._id };
                  })}
                  trustedAttributeArr={trustedAttributeArr}
                  valueType={valueType}
                  setState={(e) => {
                    setFilters(
                      filters.map((fl, i) => {
                        if (index === i) {
                          return e;
                        } else {
                          return fl;
                        }
                      })
                    );
                  }}
                  removeFilter={removeFilter}
                />
              );
            })
          ) : (
            <p className="text-gray-400">None found</p>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
    workspace: state.workspaceDetails,
    operators: Object.values(state.operators),
  };
};
export default connect(mapStateToProps, { workspaceIntegrationFilterCreate, workspaceIntegrationFilterManage, workspaceIntegrationFilterDelete })(WorkspaceIntegrationFilters);
