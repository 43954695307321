import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getOperators } from "src/actions/operator";
import { getUserWorkspaceData } from "src/actions/workspace";
import HorizonatalBarAccordian from "src/components/Shared/Accordians/HorizonatalBarAccordian";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import WorkspaceAddModal from "src/components/Workspaces/WorkspaceAddModal";
import WorkspaceGroupList from "src/components/Workspaces/Groups/WorkspaceGroupList";
import { baseUrl, noLogo } from "src/config/host";

const UserWorkspaceList = ({ ...props }) => {
  const { id } = useParams();
  const [loaded, setLoaded] = useState(false);
  const [workspaces, setWorkspaces] = useState([]);
  const [isWorkspaceAddModal, setIsWorkspaceAddModal] = useState(false);

  useEffect(() => {
    const ac = new AbortController();
    const loadData = async () => {
      const getWorkspaces = await props.getUserWorkspaceData({ user_id: id });
      setWorkspaces(getWorkspaces);
      setLoaded(true);
    };
    loadData();
    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loaded ? (
        workspaces.length > 0 && (
          <div className="relative grid gap-y-4">
            {workspaces.map((workspace) => (
              <HorizonatalBarAccordian
                key={workspace._id}
                headerContent={
                  <div className="p-2 flex gap-4 items-center">
                    <div className="w-auto h-11 p-2 overflow-hidden">
                      <img
                        src={workspace.image_logo?.includes(".s3.") ? workspace.image_logo : workspace.image_logo?.includes("data:image/") ? workspace.image_logo : baseUrl + workspace.image_logo}
                        alt="Logo"
                        className="w-full h-full object-contain"
                        onError={(e) => (e.target.src = `${noLogo}`)}
                      />
                    </div>
                    <div className="text-gray-900 font-medium">{workspace?.name}</div>
                  </div>
                }>
                <WorkspaceGroupList
                  pageName="user"
                  workspaceId={workspace._id}
                  userId={id}
                  viewUsers={false}
                  viewOnly={true}
                />
              </HorizonatalBarAccordian>
            ))}
          </div>
        )
      ) : (
        <Preloader />
      )}
      <WorkspaceAddModal
        isOpen={isWorkspaceAddModal}
        setIsOpen={setIsWorkspaceAddModal}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, { getUserWorkspaceData, getOperators })(UserWorkspaceList);
