import React from "react";
import NoData from "src/components/Shared/LoadingAnimations/NoData";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";

export default function TableBodyLoader({ dataExists = false, loaded = false, colSpan = "5" }) {
  return loaded && !dataExists ? (
    <tr>
      <td colSpan={colSpan}>
        <NoData />
      </td>
    </tr>
  ) : !loaded ? (
    <tr>
      <td colSpan={colSpan}>
        <Preloader />
      </td>
    </tr>
  ) : null;
}
