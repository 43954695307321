import { Fragment } from "react";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import { classNames } from "src/helpers/classNames";

const options = [
  { key: "10", value: 10 },
  { key: "20", value: 20 },
  { key: "50", value: 50 },
  { key: "75", value: 75 },
  { key: "100", value: 100 },
  { key: "All", value: 1000000000 },
];

const HorizontalEllipsis = () => (
  <div className="flex h-8 w-auto items-end gap-x-1 px-2">
    <div className="h-1 w-1 rounded-full bg-gray-300"></div>
    <div className="h-1 w-1 rounded-full bg-gray-300"></div>
    <div className="h-1 w-1 rounded-full bg-gray-300"></div>
  </div>
);

const PaginationFooter = ({ offset = 0, limit = 10, tabCount = 0, totalEntriesCount = 0, itemName = "Document", loaded = false, onChange = () => {} }) => {
  return (
    <div className="relative z-40 flex flex-wrap items-center py-3">
      <div className="mx-auto my-2 text-sm text-gray-400 sm:ml-0">
        <div className="flex items-center gap-x-2">
          Showing
          <SelectMenu
            options={options}
            startIndex={options.findIndex((option) => option.value === limit)}
            setOption={(option) => {
              onChange({ limit: option.value, offset: 0 });
            }}
          />{" "}
          per page.
        </div>
      </div>
      <div className="flex w-full flex-wrap items-center gap-x-2 sm:w-auto sm:justify-end">
        <div className="text-sm text-gray-500 font-light mr-5">
          {`${itemName}`}
          <span className="px-1.5 font-bold">{`${offset * limit + 1}  -  ${totalEntriesCount < offset * limit + limit ? totalEntriesCount : offset * limit + limit}`}</span>
          {`of ${totalEntriesCount}`}
        </div>
        <div
          className={classNames("flex h-8 w-8 cursor-pointer items-center justify-center rounded bg-white text-sm shadow hover:bg-gray-50", offset > 0 ? "" : "opacity-40 cursor-not-allowed")}
          onClick={() => {
            if (offset - 1 >= 0) {
              onChange({ limit, offset: offset - 1 });
            }
          }}>
          <i className="far fa-fw fa-chevron-double-left"></i>
        </div>
        <div className="flex w-full flex-wrap items-center justify-center space-x-1 sm:w-auto sm:justify-end">
          <div
            className={`flex h-8 w-8 cursor-pointer items-center justify-center rounded bg-white text-sm shadow hover:bg-gray-50 ${offset === 0 ? "bg-highlightColor text-white hover:bg-highlightColor hover:text-white" : ""}`}
            onClick={() => onChange({ limit, offset: 0 })}>
            {1}
          </div>
          {tabCount > 1 && (
            <>
              {offset > 2 ? <HorizontalEllipsis /> : null}
              {offset >= 2 ? (
                <div
                  className="flex h-8 w-8 cursor-pointer items-center justify-center rounded bg-white text-sm shadow hover:bg-gray-50"
                  onClick={() => onChange({ limit, offset: offset - 1 })}>
                  {offset}
                </div>
              ) : null}
              {0 !== offset && offset + 1 !== tabCount ? <div className="flex h-8 w-8 cursor-pointer items-center justify-center rounded bg-white text-sm shadow hover:bg-gray-50 bg-highlightColor text-white hover:bg-highlightColor hover:text-white">{offset + 1}</div> : null}
              {offset < tabCount - 2 ? (
                <div
                  className="flex h-8 w-8 cursor-pointer items-center justify-center rounded bg-white text-sm shadow hover:bg-gray-50"
                  onClick={() => onChange({ limit, offset: offset + 1 })}>
                  {offset + 2}
                </div>
              ) : null}
              {offset < tabCount - 3 ? <HorizontalEllipsis /> : null}
            </>
          )}
          {tabCount > 1 && (
            <div
              className={`flex h-8 w-8 cursor-pointer items-center justify-center rounded bg-white text-sm shadow hover:bg-gray-50 ${offset + 1 === tabCount ? "bg-highlightColor text-white hover:bg-highlightColor hover:text-white" : ""}`}
              onClick={() => onChange({ limit, offset: tabCount - 1 })}>
              {tabCount}
            </div>
          )}
        </div>
        <div
          className={classNames("flex h-8 w-8 cursor-pointer items-center justify-center rounded bg-white text-sm shadow hover:bg-gray-50", offset + 1 < tabCount ? "" : "opacity-40 cursor-not-allowed")}
          onClick={() => {
            if (offset + 1 < tabCount) {
              onChange({ limit, offset: offset + 1 });
            }
          }}>
          <i className="far fa-fw fa-chevron-double-right"></i>
        </div>
      </div>
    </div>
  );
};

export default PaginationFooter;
