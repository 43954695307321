import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { classNames } from "src/helpers/classNames";

const HorizonatalBarAccordian = ({ children, headerContent }) => {
  const [hideDetails, setHideDetails] = useState(true);

  return (
    <div className="relative">
      <div className="flex items-center justify-between bg-white py-2.5 px-3 rounded-xl overflow-hidden shadow border border-slate-200">
        <div>{headerContent}</div>
        <div>
          <div
            onClick={() => setHideDetails(!hideDetails)}
            className="h-10 w-10 flex items-center justify-center py-1 px-1 rounded-md border-0 text-slate-400 outline-none ring-0 ring-transparent shadow-none bg-transparent hover:bg-slate-100">
            <ChevronDownIcon
              className={classNames("h-5 w-5 transition-all duration-150 ", hideDetails ? "-rotate-90" : "")}
              aria-hidden="true"
            />
          </div>
        </div>
      </div>
      <div className={classNames("relative transition-all ease-in-out duration-[.6s]", hideDetails ? "max-h-0 overflow-hidden" : "max-h-[1000px]")}>
        <div className="relative py-6 pl-10 pr-2 space-y-10">{children}</div>
      </div>
    </div>
  );
};

export default HorizonatalBarAccordian;
