import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { manageSiteSettings } from "src/actions/site";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Button from "src/components/Shared/Buttons/Button";
import DatasetModal from "./DatasetModal";

const SiteCredentials = ({ site, manageSiteSettings, creds }) => {
  const [siteEmail, setSiteEmail] = useState(site?.site_email || "");
  const [smtpHost, setSmtpHost] = useState(site?.smtp_host || "");
  const [smtpUser, setSmtpUser] = useState(site?.smtp_user || "");
  const [smtpPassword, setSmtpPassword] = useState(site?.smtp_password || "");
  const [smtpPort, setSmtpPort] = useState(site?.smtp_port || "");
  const [smtpSecure, setSmtpSecure] = useState(site?.smtp_secure || true);
  const [apiState, setApiState] = useState(site?.api_state || "PORTAL_CREATED");

  const [modalOpen, setModalOpen] = useState(false);
  const [refresh, setRrefresh] = useState(false);

  const setPageData = (site) => {
    if (site?._id) {
      setSiteEmail(site.site_email);
      setApiState(site.api_state || "");
      setSmtpUser(site.smtp_user || "");
      setSmtpHost(site.smtp_host || "");
      setSmtpPassword(site.smtp_password || "");
      setSmtpPort(site.smtp_port || "");
      setSmtpSecure(site.smtp_secure || true);
    }
  };

  useEffect(() => {
    setPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site, refresh]);

  const onClear = () => {
    setPageData();
  };

  const onSubmit = async () => {
    try {
      const message = await manageSiteSettings({
        site_email: siteEmail,
        api_state: apiState,
        smtp_host: smtpHost,
        smtp_user: smtpUser,
        smtp_password: smtpPassword,
        smtp_port: smtpPort,
        smtp_secure: smtpSecure,
      });

      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const randomHashString = (length) => {
    let result = "";
    const characters = "**************************************************************";
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  };

  return (
    <div className="grid space-y-8">
      <div className="bg-white shadow-md px-6 py-8 rounded-md flex flex-wrap justify-between w-full">
        <div className="text-gray-500 text-lg font-medium w-full px-2 mb-6">SMTP Server</div>
        <div className="flex flex-wrap w-full gap-y-6">
          {/* Contact email */}
          <div className="w-full">
            <div className="sm:w-1/2 w-full px-2">
              <Input name="contact-email" label="Contact email" value={siteEmail} onChange={(e) => setSiteEmail(e.target.value)} />
            </div>
          </div>
          {/* SMTP USER */}
          <div className="sm:w-1/2 w-full px-2">
            <Input name="smtp-user" label="User" value={smtpUser} onChange={(e) => setSmtpUser(e.target.value)} />
          </div>

          {/* SMTP PASSWORD */}
          <div className="sm:w-1/2 w-full px-2">
            <Input
              name="smtp-password"
              label="Password"
              value={randomHashString(smtpPassword.length)}
              onChange={(e) => setSmtpPassword(e.target.value)}
              onFocus={(e) => {
                if (e.target.value.includes("*")) {
                  e.target.value = "";
                }
              }}
            />
          </div>

          {/* SMTP HOST */}
          <div className="sm:w-1/2 w-full px-2">
            <Input name="smtp-host" label="Host" value={smtpHost} onChange={(e) => setSmtpHost(e.target.value)} />
          </div>
          {/* SMTP port */}
          <div className="relative sm:w-1/2 w-full px-2">
            {/* SMTP HOST */}
            <div className="absolute z-30 flex items-center gap-x-2 ml-2 top-[2px] right-4 text-gray-700 opacity-70 text-xs cursor-pointer">
              <input className="rounded-sm text-gray-400 focus:ring-highlightColor cursor-pointer" type="checkbox" checked={smtpSecure} onChange={(e) => setSmtpSecure(e.target.checked)} />
              <label className="cursor-pointer">STMP Secure</label>
            </div>

            <div className="w-full">
              <Input label="SMTP Port" value={smtpPort} onChange={(e) => setSmtpPort(e.target.value)} />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex justify-end gap-x-4 pb-40">
        <Button styleType="gray" onClick={onClear}>
          Undo changes
        </Button>
        <Button onClick={onSubmit}>Update</Button>
      </div>
      <DatasetModal site={site} creds={creds} setRrefresh={setRrefresh} isOpen={modalOpen} setIsOpen={setModalOpen} submitText="Save" cancelText="Cancel" title="Download Domo Dataset" />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, { manageSiteSettings })(SiteCredentials);
