import { ArrowUturnLeftIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useNavigate } from "react-router-dom";
import { classNames } from "src/helpers/classNames";

export default function BackIndicator() {
  const navigate = useNavigate();
  return (
    <ArrowUturnLeftIcon
      onClick={() => navigate("/" + window.location.pathname.split("/")[1])}
      className={classNames("h-8 w-8", "mr-1 border-gray-300 border cursor-pointer p-1 text-gray-400 heroicon-stroke-w-4 transition-all duration-200 rounded", "hover:bg-gray-200/50 hover:border-gray-400 hover:text-gray-600")}
    />
  );
}
