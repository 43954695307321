import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";
import { classNames } from "src/helpers/classNames";

const Select = ({ options, searchable = false, disabled = false, label = "", secondaryLabel = "", setSelected, selected = { id: -1, name: " " }, required = false, selectType = '' }) => {
  let selectedVal = selected.name === "None selected" && required ? "" : "";

  return (
    <Listbox
      disabled={disabled}
      className="cursor-not-allowed"
      value={selected}
      onChange={setSelected}>
      {({ open }) => (
        <div className="min-w-[80px] w-full flex flex-col">
          {(label || secondaryLabel) && (
            <Listbox.Label className="mb-1 block text-sm font-medium text-gray-700">
              <span className="relative">
                {label && <span className="pr-2">{label}</span>}
                {secondaryLabel && <span className=" text-gray-300">{secondaryLabel}</span>}
              </span>
            </Listbox.Label>
          )}
          <div className={"relative " + (selectType !== 'label'? 'h-10' : '')}>
            {selectType === 'label'? (
              <Listbox.Button
                disabled={disabled}
                className={`relative w-full text-left focus:outline-none flex items-center mb-1 text-sm ${disabled ? " text-gray-50/50 cursor-not-allowed" : " cursor-default"}` + selectedVal}>
                <span className="block truncate">{selected.name}</span>
                <span className="inset-y-0 flex items-center pl-2 pointer-events-none">
                  <ChevronDownIcon
                    className="h-3 w-3 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
            ): (
              <Listbox.Button
                disabled={disabled}
                className={`h-10 relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left focus:outline-none focus:border-highlightColor sm:text-sm ${disabled ? " bg-gray-50/50 cursor-not-allowed" : " cursor-default"}` + selectedVal}>
                <span className="block truncate min-h-[17.5px]">{selected.name}</span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
            )}
            
            {!disabled && (
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Listbox.Options className="absolute z-30 mt-1 w-full bg-white shadow-lg max-h-60 overflow-y-auto rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {options.map((option, i) => (
                    selectType === 'label'? (
                      <Listbox.Option
                        key={i}
                        className={({ active }) => classNames(active ? "text-white bg-highlightColor" : "text-gray-900", "text-xs cursor-default select-none relative")}
                        value={option}>
                        {({ selected, active }) => (
                          <>
                            <div className={classNames(selected ? "font-semibold" : "font-normal", "block truncate py-2 px-2")}>{option.name}</div>
                          </>
                        )}
                      </Listbox.Option>
                    ): (
                      <Listbox.Option
                        key={i}
                        className={({ active }) => classNames(active ? "text-white bg-highlightColor" : "text-gray-900", "text-sm cursor-default select-none relative py-2 pl-4 pr-4")}
                        value={option}>
                        {({ selected, active }) => (
                          <>
                            <span className={classNames(selected ? "font-semibold" : "font-normal", "pl-3 block truncate")}>{option.name}</span>

                            {selected ? (
                              <span className={classNames(active ? "text-white" : "text-highlightColor", "absolute inset-y-0 left-0 flex items-center pl-1.5")}>
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    )
                  ))}
                </Listbox.Options>
              </Transition>
            )}
          </div>
        </div>
      )}
    </Listbox>
  );
};

export default Select;
