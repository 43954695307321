import React, { useState } from "react";
import Dropdown from "src/components/Shared/Buttons/Dropdown";
import Table from "src/components/Shared/Table/Table";
import TRHeader from "src/components/Shared/Table/TRHeader";
import PageNewEntry from "./PageNewEntry";

const PageNewList = () => {
  const [loaded] = useState(true);

  const tableData = [
    { _id: 1, name: "Marketing Dashboard", type: "DOMO_CARD", workspace: "3" },
    { _id: 2, name: "Sales Dashboard", type: "DOMO", workspace: "3" },
    { _id: 3, name: "Home", type: "LANDING_PAGE", workspace: "1" },
    { _id: 4, name: "Contact Us", type: "CUSTOM_HTML", workspace: "3" },
  ];

  return (
    <>
      <div className="flex items-center justify-between relative z-10">
        <div className="relative flex min-w-[140px] items-center justify-end gap-x-1 ml-auto">
          <Dropdown />
        </div>
      </div>
      <Table
        tableHeader={
          <TRHeader>
            <th
              scope="col"
              className="rounded-l-xl py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Page Name
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Type
            </th>
            <th
              scope="col"
              className="rounded-r-xl relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Actions</span>
            </th>
          </TRHeader>
        }
        colSpan="3"
        loaded={loaded}
        dataExists={tableData.length > 0}>
        {tableData.map((item) => (
          <PageNewEntry
            key={item._id}
            id={item._id}
            name={item.name}
            type={item.type}
            workspace={item.workspace}
          />
        ))}
      </Table>
    </>
  );
};

export default PageNewList;
