import React from "react";
import EmailerSection from "src/components/Emailer/EmailerSection";
// import { useLocation } from "react-router";

const Emailer = (props) => {
  // const location = useLocation();
  return <EmailerSection />;
};

export default Emailer;
