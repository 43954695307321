import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useEffect, useRef, useState } from "react";
import { classNames } from "src/helpers/classNames";
import useOnClickOutside from "src/hooks/useOnClickOutside";

// EXAMPLE OPTION:
// const option = {
//   key: 1,
//   value: "text",
//   selected: false,
//   object: {
//     _id: 'ObjectId("HDJNC14HDB792")',
//     name: "John Snow",
//     age: 26
//   },
// };

const MultiSelectObject = ({ defaultOptions = [], searchable = true, searchableFields = [], placeholder = "Search", title, error = false, onChange = () => {}, createP = true }) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [panelOpen, setPanelOpen] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);

  const ref = useRef();

  useOnClickOutside(ref, () => setPanelOpen(false));

  useEffect(() => {
    if (!selectedOptions.length) {
      setSelectedOptions(defaultOptions);
      setFilteredOptions(defaultOptions);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOptions]);

  const optionClicked = (key) => {
    let updatedOptions = selectedOptions.map((option) => {
      if (option.key === key) {
        if (option.selected) option.selected = false;
        else option.selected = true;
      }
      return option;
    });
    setSelectedOptions(updatedOptions);
    onChange(updatedOptions);
  };

  useEffect(() => {
    if (searchable && searchableFields) {
      let updatedFilteredOptions = selectedOptions.filter((option) => {
        let keywordMatched = false;
        searchableFields.forEach((searchableField) => {
          if (option?.object[searchableField]?.toLowerCase().includes(keyword.toLowerCase())) {
            keywordMatched = true;
          }
        });
        return keywordMatched;
      });
      setFilteredOptions(updatedFilteredOptions);
    }
  }, [keyword, selectedOptions, searchable, searchableFields]);

  return (
    <div className="relative">
      <div className={classNames("flex justify-between text-sm font-medium text-gray-700 mb-1", error ? "text-red-600" : "")}>
        {title} <div className="pl-2">{error ? "*Required" : ""}</div>
      </div>
      <div
        className="relative"
        ref={ref}>
        {createP && (
          <>
            <div
              className="relative rounded-md shadow-sm w-full flex"
              onClick={() => setPanelOpen(true)}>
              <div
                className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                aria-hidden="true">
                <MagnifyingGlassIcon
                  className="mr-3 h-4 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="text"
                autoComplete="off"
                name="search"
                id="search"
                value={keyword}
                className="block w-full pl-9 border-gray-300 text-sm rounded-md focus:ring-0 focus:border-highlightColor"
                placeholder={placeholder}
                onChange={(e) => setKeyword(e.target.value)}
              />
            </div>
            <div className="absolute z-50 top-8 left-0 mt-3 w-full flex justify-between">
              <div className={classNames("bg-white max-h-48 overflow-auto min-w-full shadow z-30 absolute", panelOpen ? "flex flex-col" : "hidden")}>
                {filteredOptions.map((option) => {
                  return (
                    <div
                      key={option.key}
                      className="cursor-pointer w-full flex items-center px-4 py-2 gap-x-3 border-white hover:bg-gray-100"
                      onClick={() => optionClicked(option.key)}>
                      <input
                        type="checkbox"
                        checked={option.selected}
                        className="cursor-pointer rounded text-highlightColor focus:ring-highlightColor"
                        readOnly
                      />
                      {option.value}
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
        <div className="h-10 mt-3 flex gap-2 flex-wrap">
          {selectedOptions.length ? (
            selectedOptions
              .filter((selectedOption) => selectedOption.selected)
              .map((selectedOption) => (
                <div
                  className="flex items-center rounded-full px-[2px] pl-[8px] py-[2px] border border-highlightColor text-highlightColor"
                  key={selectedOption.key + "selected"}>
                  <p className="text-center pb-[1px]">{selectedOption.value}</p>
                  <div
                    onClick={() => optionClicked(selectedOption.key)}
                    className="hover:bg-gray-100 cursor-pointer rounded-full p-1 ml-1">
                    <XMarkIcon className="h-4 w-4 text-highlightColor" />
                  </div>
                </div>
              ))
          ) : (
            <div className="h-6 flex items-center">None selected</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MultiSelectObject;
