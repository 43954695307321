import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { manageSiteSettings, removeFavLogo } from "src/actions/site";
import { baseUrl, noFavicon, exampleFavi, noLogo } from "src/config/host";

import { XMarkIcon } from "@heroicons/react/24/solid";
import Button from "src/components/Shared/Buttons/Button";
import { convertToBase64 } from "src/helpers/image";
import SitePreview from "src/components/Site/SitePreview";

const SiteThemeAndStyles = ({ user, site, manageSiteSettings, removeFavLogo }) => {
  const [imageLogo, setImageLogo] = useState(site.image_logo || noLogo);
  const [imageFavicon, setImageFavicon] = useState(site.image_favicon || noFavicon);
  const [headerColor, setHeaderColor] = useState(site?.top_bar_color || "#ffffff");
  const [headerTextColor, setHeaderTextColor] = useState(site?.top_bar_text_color || "#ffffff");
  const [leftNavColor, setLeftNavColor] = useState(site?.side_bar_color || "#ffffff");
  const [leftNavTextColor, setLeftNavTextColor] = useState(site?.side_bar_text_color || "#ffffff");
  const [leftNavActiveColor, setLeftNavActiveColor] = useState(site?.side_bar_shade_color || "#ffffff");
  const [highlightColor, setHighlightColor] = useState(site?.highlight_color || "#ffffff");
  const [leftNavActiveTextColor, setLeftNavActiveTextColor] = useState(site?.left_nav_active_text_color || "#ffffff");
  const allowedExts = ["jpg", "jpeg", "png", "bmp", "gif", "svg", "webp"];

  useEffect(() => {
    if (site?._id) {
      setImageLogo(site.image_logo || noLogo);
      setImageFavicon(site.image_favicon || noFavicon);
      setHeaderColor(site.top_bar_color || "#ffffff");
      setHeaderTextColor(site.top_bar_text_color || "#ffffff");
      setLeftNavColor(site.side_bar_color || "#ffffff");
      setLeftNavTextColor(site.side_bar_text_color || "#ffffff");
      setLeftNavActiveColor(site.side_bar_shade_color || "#ffffff");
      setHighlightColor(site.highlight_color || "#ffffff");
      setLeftNavActiveTextColor(site?.left_nav_active_text_color || "#ffffff");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  const onFavChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts, "ico", "x-icon"].includes(ext)) {
        toast.error("Please upload a valid imageLogo.");
      } else {
        convertToBase64(e.target.files[0], setImageFavicon);
      }
    }
  };

  const onFileChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (!allowedExts.includes(ext)) {
        toast.error("Please upload a valid imageLogo.");
      } else {
        convertToBase64(e.target.files[0], setImageLogo);
      }
    }
  };

  const onClear = () => {
    setImageLogo(site.image_logo || noLogo);
    setImageFavicon(site.image_favicon || noFavicon);
    setHeaderColor(site.top_bar_color || "#ffffff");
    setHeaderTextColor(site.top_bar_text_color || "#ffffff");
    setLeftNavColor(site.side_bar_color || "#ffffff");
    setLeftNavTextColor(site.side_bar_text_color || "#ffffff");
    setLeftNavActiveColor(site.side_bar_shade_color || "#ffffff");
    setHighlightColor(site.highlight_color || "#ffffff");
    setLeftNavActiveTextColor(site?.left_nav_active_text_color || "#ffffff");
  };

  const onSubmit = async () => {
    try {
      const message = await manageSiteSettings({
        image_logo: imageLogo,
        image_favicon: imageFavicon,
        top_bar_color: headerColor,
        top_bar_text_color: headerTextColor,
        side_bar_color: leftNavColor,
        side_bar_text_color: leftNavTextColor,
        side_bar_shade_color: leftNavActiveColor,
        left_nav_active_text_color: leftNavActiveTextColor,
        highlight_color: highlightColor,
        sso_button_color: site.sso_button_color,
        sso_button_text_color: site.sso_button_text_color,
      });

      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onFavLogoDelete = async (body) => {
    try {
      const message = await removeFavLogo(body);
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="grid space-y-8">
        <div className="bg-white shadow-md p-6 rounded-md flex flex-wrap justify-between w-full">
          <div className="w-full flex flex-wrap">
            <div className="w-full sm:w-8/12">
              <SitePreview
                menu={["Users", "Admins", "Groups", "Pages", "SSO", "Activity", "Settings"]}
                site={site}
                logo={imageLogo}
                favicon={imageFavicon}
                headerColor={headerColor}
                headerTextColor={headerTextColor}
                leftNavColor={leftNavColor}
                highlightColor={highlightColor}
                leftNavTextColor={leftNavTextColor}
                leftNavActiveColor={leftNavActiveColor}
                leftNavActiveTextColor={leftNavActiveTextColor}
                setHeaderColor={setHeaderColor}
                setHeaderTextColor={setHeaderTextColor}
                setLeftNavTextColor={setLeftNavTextColor}
                setLeftNavColor={setLeftNavColor}
                setHighlightColor={setHighlightColor}
                setLeftNavActiveColor={setLeftNavActiveColor}
                setLeftNavActiveTextColor={setLeftNavActiveTextColor}
              />
            </div>
            <div className="w-full sm:w-4/12 sm:pl-10">
              <div className="relative w-full mt-6">
                <div className="text-base uppercase text-black font-semibold">Site Logo</div>
                <div className="group w-60 relative h-60  border border-gray-200 p-4 rounded-md bg-headerColor">
                  {site._id && (
                    <img
                      src={imageLogo?.includes(".s3.") ? imageLogo : imageLogo?.includes("data:image/") ? imageLogo : baseUrl + imageLogo}
                      alt="Logo"
                      className="w-full h-full object-contain"
                      onError={(e) => (e.target.src = `${noLogo}`)}
                    />
                  )}
                  {site.image_logo && (user?.type === "admin" || (user?.user_permissions && user?.user_permissions?.layout_update)) && (
                    <div
                      className="group-hover:flex cursor-pointer hidden bg-gray-200 hover:bg-gray-300 absolute h-8 w-8 rounded-full text-center -top-3 -right-3 z-30 justify-center items-center transition-all"
                      onClick={() => onFavLogoDelete({ logo: true })}>
                      <XMarkIcon className="h-5 w-5 text-gray-600" />
                    </div>
                  )}
                </div>
                {(user?.type === "admin" || (user?.user_permissions && user?.user_permissions?.layout_update)) && (
                  <div className="relative w-full mt-3 overflow-hidden">
                    <label htmlFor="change-logo">
                      <div className="h-full inline-flex items-center justify-center rounded-md border px-4 py-2 text-regular font-medium shadow-sm focus:outline-none text-white bg-highlightColor cursor-pointer">Change Logo</div>
                    </label>
                    <input
                      type="file"
                      id="change-logo"
                      className="hidden"
                      onChange={onFileChange}
                    />
                  </div>
                )}
              </div>
              <div className="relative w-full mt-12">
                <div className="text-base uppercase text-black font-semibold">Favicon</div>
                <div className="group w-32 relative h-32 border border-gray-200 bg-gray-100 p-4 rounded-md">
                  {site._id && (
                    <img
                      src={imageFavicon?.includes(".s3.") ? imageFavicon : imageFavicon?.includes("data:image/") ? imageFavicon : baseUrl + imageFavicon}
                      alt="Logo"
                      className="w-full h-full object-contain"
                      onError={(e) => (e.target.src = `${exampleFavi}`)}
                    />
                  )}
                  {site.image_favicon && (user?.type === "admin" || (user?.user_permissions && user?.user_permissions?.layout_update)) && (
                    <div
                      className="group-hover:flex cursor-pointer hidden bg-gray-200 hover:bg-gray-300 absolute h-8 w-8 rounded-full text-center -top-3 -right-3 z-30 justify-center items-center transition-all"
                      onClick={() => onFavLogoDelete({ delete_favicon: true })}>
                      <XMarkIcon className="h-5 w-5 text-gray-600" />
                    </div>
                  )}
                </div>
                {(user?.type === "admin" || (user?.user_permissions && user?.user_permissions?.layout_update)) && (
                  <div className="relative w-full mt-3 overflow-hidden">
                    <label htmlFor="change-fav">
                      <div className="h-full inline-flex items-center justify-center rounded-md border px-4 py-2 text-regular font-medium shadow-sm focus:outline-none text-white bg-highlightColor cursor-pointer">Change Favicon</div>
                    </label>
                    <input
                      type="file"
                      id="change-fav"
                      className="hidden"
                      onChange={onFavChange}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3 w-full flex justify-end gap-x-4 pb-20">
        <Button
          styleType="gray"
          onClick={onClear}>
          Undo changes
        </Button>
        {(user?.type === "admin" || (user?.user_permissions && user?.user_permissions?.layout_update)) && <Button onClick={onSubmit}>Update</Button>}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { manageSiteSettings, removeFavLogo })(SiteThemeAndStyles);
