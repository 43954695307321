import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ModalDefault from "src/components/Shared/Modals/ModalDefault";
import MultiSelectObject from "src/components/Shared/Forms/Selects/MultiSelectObject";
import { apiRequest } from "src/async/apiUtils";

const WorkspaceAddUserModal = ({ workspaceDetails, user, isOpen, setIsOpen, pSelectedUser, selectedUser, setSelectedUser, onSubmit = () => {}, isPermissionPage = false, ...props }) => {
  const [users, setUsers] = useState([]);

  const [createP] = useState(true);

  const loadUsers = async (ac) => {
    try {
      let data = await apiRequest("post", `/users/list`, { body: { sort_direction: "ASC" } });
      setUsers(data.data.data);
    } catch (error) {
      console.dir(error.message);
    }
  };

  useEffect(() => {
    const ac = new AbortController();
    loadUsers(ac);

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectedUsers = (e) => {
    let userIds = [];
    if (isPermissionPage) {
      if (user?.type === "admin" || (workspaceDetails?.user_permissions && workspaceDetails?.user_permissions?.permission_delete)) {
        userIds = [];
      } else {
        userIds = pSelectedUser;
      }
    }
    for (let index = 0; index < e.length; index++) {
      const element = e[index];
      if (element.selected) {
        userIds.push(element.key);
      }
    }
    setSelectedUser(userIds);
  };
  return (
    <>
      <ModalDefault
        isOpen={isOpen}
        closeModal={() => {
          setIsOpen(!isOpen);
        }}
        onSubmit={() => {
          onSubmit();
        }}
        submitText="Save"
        cancelText="Cancel"
        title="User"
        secondaryTitle="Create">
        <div className="relative min-h-[200px] py-4">
          <MultiSelectObject
            createP={createP}
            defaultOptions={users
              .filter((filterUser) => filterUser.type !== "admin")
              .map((user) => {
                return { key: user._id, value: user.name, selected: selectedUser.includes(user._id), object: user };
              })}
            searchableFields={["name", "email"]}
            title="Assign users"
            onChange={handleSelectedUsers}
          />
        </div>
      </ModalDefault>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    users: Object.values(state.users),
    user: state.auth.user,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, {})(WorkspaceAddUserModal);
