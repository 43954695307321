import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { manageSiteSettings } from "src/actions/site";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import { H3 } from "src/components/Shared/Text/Headers";
import SiteColorItem from "src/components/Site/SiteColorItem";

const SSOCustomizations = ({ site, manageSiteSettings }) => {
  const [ssoButtonText, setSsoButtonText] = useState(site?.sso_button_text || "");
  const [ssoButtonColor, setSsoButtonColor] = useState(site?.sso_button_color || "");
  const [ssoButtonTextColor, setSSOButtonTextColor] = useState(site?.sso_button_text_color || "#ffffff");
  const [ssoAdminRedirect, setSsoAdminRedirect] = useState(site?.sso_admin_redirect || false);

  const onSubmit = async () => {
    try {
      const message = await manageSiteSettings({
        sso_button_text: ssoButtonText,
        sso_button_color: ssoButtonColor,
        sso_button_text_color: ssoButtonTextColor,
        sso_admin_redirect: ssoAdminRedirect,
      });

      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const setPageData = (site) => {
    if (site?._id) {
      setSsoButtonText(site.sso_button_text || "");
      setSsoButtonColor(site.sso_button_color);
      setSSOButtonTextColor(site.sso_button_text_color || "#ffffff");
      setSsoAdminRedirect(site.sso_admin_redirect || false);
    }
  };

  const onClear = () => {
    setPageData();
  };

  useEffect(() => {
    setPageData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  return (
    <div className="grid gap-y-3">
      <div className="bg-white shadow-md px-8 py-8 rounded-md flex flex-col justify-between w-full">
        <H3>SSO Styling</H3>
        <div className="flex flex-col md:flex-row md:items-center w-full gap-y-6 gap-x-4">
          <div className="w-[200px] mb-10 md:mb-0">
            <Input
              name="sso-button-text"
              label="Button Text"
              value={ssoButtonText}
              onChange={(e) => setSsoButtonText(e.target.value)}
            />
          </div>
          <div className="relative">
            <div className="flex space-x-4">
              <div
                className="mt-8 sm:mt-6 min-w-[130px] bg-gray-400 h-10 px-4 rounded-md flex items-center justify-center"
                style={{ backgroundColor: ssoButtonColor }}>
                <p
                  className="h-6 flex items-center justify-center rounded-md font-medium"
                  style={{ color: ssoButtonTextColor }}>
                  {ssoButtonText}
                </p>
              </div>
            </div>
            <div className="absolute z-10 bottom-10 left-28 bg-white flex flex-col w-[160px] border border-gray-100 py-2 px-2 rounded-xl drop-shadow-md before:content-[''] before:absolute before:-bottom-2 before:left-4 before:w-4 before:h-4 before:bg-white before:rotate-[45deg] before:-z-10 before:border before:border-gray-100 before:border-l-0 before:border-t-0">
              <SiteColorItem
                onChange={setSsoButtonColor}
                color={ssoButtonColor}
                name="SSO Button Background"
              />
            </div>
            <div className="absolute z-20 -bottom-16 left-[70px] bg-white flex flex-col w-[160px] border border-gray-100 py-2 px-2 rounded-xl drop-shadow-md before:content-[''] before:absolute before:-top-2 before:left-4 before:w-4 before:h-4 before:bg-white before:rotate-[45deg] before:-z-10 before:border before:border-gray-100 before:border-r-0 before:border-b-0">
              <SiteColorItem
                onChange={setSSOButtonTextColor}
                color={ssoButtonTextColor}
                name="SSO Button Text"
              />
            </div>
          </div>
        </div>

        <div className="w-full flex justify-end gap-x-4 mt-20 sm:mt-4">
          <Button
            styleType="gray"
            onClick={onClear}
            disabled={ssoButtonText === site?.sso_button_text && ssoButtonTextColor === site?.sso_button_text_color && ssoButtonColor === site?.sso_button_color}>
            Undo changes
          </Button>
          <Button
            onClick={onSubmit}
            disabled={ssoButtonText === site?.sso_button_text && ssoButtonTextColor === site?.sso_button_text_color && ssoButtonColor === site?.sso_button_color}>
            Update
          </Button>
        </div>
      </div>
      <div className="bg-white shadow-md px-8 py-8 rounded-md flex flex-col justify-between w-full">
        <H3>Navigation Controls</H3>
        <div className="relative w-full sm:px-8">
          <div className="font-medium text-gray-500 mb-4 flex gap-x-5">
            <div>
              <p>Admin URL Redirect</p>
            </div>
          </div>
          <div className="flex">
            <Toggle
              checked={ssoAdminRedirect}
              onChange={setSsoAdminRedirect}
            />
            <div className="ml-3 -mt-[2px] text-sm text-gray-600">
              <p className="p-0">Redirect admin users to SSO login url</p>
              <p className="p-0 text-gray-400/80">Turned "Off" by default. Verify correct SSO setup before toggling</p>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-end gap-x-4 mt-4">
          <Button
            styleType="gray"
            onClick={onClear}
            disabled={ssoAdminRedirect === site?.sso_admin_redirect}>
            Undo changes
          </Button>
          <Button
            onClick={onSubmit}
            disabled={ssoAdminRedirect === site?.sso_admin_redirect}>
            Update
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, { manageSiteSettings })(SSOCustomizations);
