import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserDetails, updateUser } from "src/actions/user";
import Button from "src/components/Shared/Buttons/Button";
import EditUserForm from "src/components/Users/Edit/EditUserForm";
import { useLoadData } from "src/hooks/useLoadData";

const UserProfileDetailsEdit = ({ ...props }) => {
  const [user, setUser] = useState({});
  const [userLoaded, setUserLoaded] = useState(false);

  const [isAdmin, setIsAdmin] = useState();
  const { id } = useParams();

  useLoadData("post", "/users/details", { body: { id } }, (res) => setUser(res.data), userLoaded, setUserLoaded);

  useEffect(() => {
    if (user?.type === "admin") {
      setIsAdmin(true);
    }
  }, [user?.type]);

  const onSubmit = async () => {
    const updateData = {
      ...user,
      type: !isAdmin ? (user.type === "editor" ? "editor" : "user") : "admin",
    };

    try {
      const message = await props.updateUser(updateData);
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSetUser = (value, key) => {
    setUser({ ...user, [key]: value });
  };

  return (
    <>
      <EditUserForm
        isAdmin={isAdmin}
        setIsAdmin={setIsAdmin}
        name={user.name}
        active_status={user.active_status}
        password={user.password}
        confirm_password={user.confirm_password}
        email={user.email}
        username={user.username}
        image={user.image}
        notifications={user.notifications}
        user={user}
        setUser={handleSetUser}
      />
      <div className="mt-5 flex w-full justify-end gap-x-3 pt-3">
        <Button
          styleType="gray"
          type="button"
          onClick={() => setUserLoaded(false)}>
          Clear all
        </Button>
        <Button
          type="button"
          onClick={onSubmit}>
          Save
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, { getUserDetails, updateUser })(UserProfileDetailsEdit);
