import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";
import UserPermissionTabDataItem from "src/components/Users/Edit/UserPermissionTabDataItem";

const UserPermissionTabData = ({ userPermission, workspaceList, selectedTab}) => {
  const [createCheckbox, setCreateCheckox] = useState(false);
  const [updateCheckbox, setUpdateCheckox] = useState(false);
  const [deleteCheckbox, setDeleteCheckox] = useState(false);

  useEffect(() => {
    if (workspaceList.length > 0) {
      let createCheckboxCount = 0;
      let updateCheckboxCount = 0;
      let deleteCheckboxCount = 0;
      for (let index = 0; index < workspaceList.length; index++) {
        const element = workspaceList[index];
        if (element[selectedTab + "_create"]) {
          createCheckboxCount++;
        }
        if (element[selectedTab + "_update"]) {
          updateCheckboxCount++;
        }
        if (element[selectedTab + "_delete"]) {
          deleteCheckboxCount++;
        }
      }
      if (workspaceList.length === createCheckboxCount) {
        setCreateCheckox(true);
      } else {
        setCreateCheckox(false);
      }
      if (workspaceList.length === updateCheckboxCount) {
        setUpdateCheckox(true);
      } else {
        setUpdateCheckox(false);
      }
      if (workspaceList.length === deleteCheckboxCount) {
        setDeleteCheckox(true);
      } else {
        setDeleteCheckox(false);
      }
    }
  }, [selectedTab, workspaceList]);

  return (
    <>
      <div className="relative w-full">
        <div className="flex items-center gap-4 py-4 even:bg-slate-50 odd:bg-white">
          <div className="w-full min-w-[320px]"></div>
          <div className="flex flex-col gap-2 items-center w-16 min-w-[64px]">
            <div className="text-xs uppercase text-slate-400">CREATE</div>
            <Checkbox checkboxName={selectedTab + "_create"} isChecked={createCheckbox} users={workspaceList} />
          </div>
          <div className="flex flex-col gap-2 items-center w-16 min-w-[64px]">
            <div className="text-xs uppercase text-slate-400">UPDATE</div>
            <Checkbox checkboxName={selectedTab + "_update"} isChecked={updateCheckbox} users={workspaceList} />
          </div>
          <div className="flex flex-col gap-2 items-center w-16 min-w-[64px]">
            <div className="text-xs uppercase text-slate-400">DELETE</div>
            <Checkbox checkboxName={selectedTab + "_delete"} isChecked={deleteCheckbox} users={workspaceList} />
          </div>
          <div className="w-full flex justify-end items-center pr-4"></div>
        </div>
        {workspaceList.map((workspace, index) => {
          return <UserPermissionTabDataItem  userPermission={userPermission} key={index} workspace={workspace} users={[workspace]} selectedTab={selectedTab} />;
        })}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userPermission: state.auth.user,
  };
};
export default connect(mapStateToProps, {})(UserPermissionTabData);
