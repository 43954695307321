import React from "react";
import PageNewList from "src/components/Pages/PageNewList";
import { H1 } from "src/components/Shared/Text/Headers";

const pageNew = () => {
  return (
    <>
      <H1>Pages</H1>
      <PageNewList title="Pages"/>
    </>
  );
};

export default pageNew;
