import { useEffect, useState } from "react";
import { baseUrl } from "src/config/host";
import { formatName } from "src/helpers/formatName";
import { getS3ImageDetails } from "src/helpers/image";

export default function UserProfile({ user, size = "md" }) {
  const [isImgAvail, setIsImgAvail] = useState(false);
  const [s3Image, setS3Image] = useState({
    loading: false,
    data: null
  })

  useEffect(() => {
    setIsImgAvail(true);
  }, [user?.image]);

  useEffect(() => {
    if (user?.image && !user.image.includes('data:image/')) {
      const loadS3Image = async () => {
        setS3Image(data => ({ ...data, loading: true }))
        const res = await getS3ImageDetails(user.image)
        if (res.status === 200) {
          setS3Image(({ loading: false, data: res.data }))
        } else {
          setS3Image(data => ({ ...data, loading: false }))
        }
      }

      loadS3Image()
    }
  }, [ user?.image ])

  return (isImgAvail || (user?.image && s3Image.data && !s3Image.loading)) ? (
    <img
      className="h-full w-full object-cover"
      src={s3Image.data || (baseUrl + user?.image)}
      alt="profile"
      onError={() => setIsImgAvail(false)}
    />
  ) : (
    <div className={`flex justify-center items-center w-full h-full rounded-full text-gray-900 text-${size} p-3 uppercase bg-gray-200`}>{formatName(user?.name, user?.email)}</div>
  );
}
