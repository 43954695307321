import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { connect } from "react-redux";
import { manageUserStatus } from "src/actions/user";
import FormContainer from "src/components/Shared/Forms/FormContainer";
import Input from "src/components/Shared/Forms/Inputs/Input";
import ProfileImage from "src/components/Users/Edit/ProfileImage";
import { classNames } from "src/helpers/classNames";

const EditUserForm = ({ isAdmin, setIsAdmin, manageUserStatus, name, password, confirm_password, email, active_status, image, notifications, user = {}, setUser = () => {}, ...props }) => {
  const [changePasswordView, setChangePasswordView] = useState(false);
  return (
    <FormContainer>
      <div className="flex flex-wrap w-full gap-y-4">
        {/* Photo */}
        <div className="w-full px-2 flex items-center gap-5 justify-between relative">
          <ProfileImage
            user={user}
            image={image}
            setImage={(value) => setUser(value, "image")}
          />
          <div className="absolute top-1 right-1 h-full flex items-start">
            <div>
              <button
                onClick={() => {
                  manageUserStatus(user);
                  setUser(!user.active_status, "active_status");
                }}
                className={classNames("flex w-[52px] justify-center rounded-full px-2 text-xs font-semibold leading-5", active_status ? "bg-green-200 text-green-700 opacity-50" : "bg-gray-50 text-gray-500")}>
                {active_status ? "Active" : "Inactive"}
              </button>
            </div>
          </div>
        </div>

        {/* Name */}
        <div className="sm:w-1/2 w-full px-2">
          <Input
            name="full-name"
            label="Full name"
            type="text"
            value={name}
            onChange={(e) => setUser(e.target.value, "name")}
          />
        </div>
        {!["editor", "user"].includes(props.me?.type) && (
          <>
            {/* Email */}
            <div className="sm:w-1/2 w-full px-2">
              <Input
                name="email"
                label="Email"
                value={email}
                onChange={(e) => setUser(e.target.value, "email")}
              />
            </div>
            <div className={classNames("w-full transition-all duration-150", changePasswordView ? "h-[80px]" : "h-[30px] overflow-hidden")}>
              <p
                onClick={() => setChangePasswordView(!changePasswordView)}
                className="inline-flex px-2 py-1.5 rounded gap-x-3 cursor-pointer items-center text-sm font-medium text-gray-700 hover:bg-gray-50">
                Change password <ChevronRightIcon className={classNames("h-4", changePasswordView ? "rotate-90" : "")} />
              </p>
              <div className="mt-2">
                <div className="flex w-full flex-wrap gap-y-4">
                  {/* Password */}
                  <div className="sm:w-1/2 w-full px-2">
                    <Input
                      autoComplete="new-password"
                      type="password"
                      name="password"
                      label="Password"
                      value={password}
                      onChange={(e) => setUser(e.target.value, "password")}
                    />
                  </div>
                  {/* Confirm Password */}
                  <div className="sm:w-1/2 w-full px-2">
                    <Input
                      autoComplete="new-password"
                      type="password"
                      name="confirm-password"
                      label="Confirm password"
                      value={confirm_password}
                      onChange={(e) => setUser(e.target.value, "confirm_password")}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="sm:w-1/2 w-full px-2">
              {/* Admin status */}
              <div className="relative flex flex-col pt-3.5">
                <div className="flex justify-between text-sm font-medium text-gray-700 mb-1">Assign admin</div>
                <div className="pt-1 flex md:pt-0 items-center ">
                  <input
                    checked={isAdmin}
                    id="type"
                    aria-describedby="notifications-description"
                    name="type"
                    type="checkbox"
                    className="h-4 w-4 text-highlightColor border-gray-300 rounded"
                    onChange={() => setIsAdmin(!isAdmin)}
                  />
                  <p className="ml-3 text-sm text-gray-500">You are assigning admin status by selecting this box.</p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </FormContainer>
  );
};
const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, { manageUserStatus })(EditUserForm);
