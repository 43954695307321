import { apiUrl } from "src/config/host";
import axios from "axios";

const builderQueryString = (queries = []) => {
  let queryString = "";
  if (queries.length > 0) {
    queryString = new URLSearchParams(queries);
    queryString = "?" + queryString.toString();
  }

  return queryString;
};

const apiRequest = async (httpMethod = "post", url = "", payload = { body: null, queries: null }, options = { useCustomUrl: false }) => {
  try {
    let queryString = builderQueryString(payload?.queries);

    let accessToken = localStorage.getItem("access_token");

    let headers = { "Content-Type": "application/json", Accept: "application/json" };

    if (accessToken) {
      headers = {
        ...headers,
        Authorization: accessToken,
      };
    }
    const request = axios.create({
      baseURL: options.useCustomUrl ? "" : apiUrl,
      headers,
    });

    const res = await request[httpMethod.toLowerCase()](`${url}${queryString}`, payload.body);
    
    if (res.status === 200) {
      return res;
    } else {
      throw new Error(res?.message || res);
    }
  } catch (error) {
    throw new Error(error?.response?.data || error);
  }
};

export { builderQueryString, apiRequest };
