import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { deleteUser } from "src/actions/user";
import { fetchData } from "src/async/fetch";
import DataExport from "src/components/Shared/DataExport";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import ModalDefault from "src/components/Shared/Modals/ModalDefault";
import PaginationFooter from "src/components/Shared/PaginationFooter";
import Search from "src/components/Shared/Search";
import Table from "src/components/Shared/Table/Table";
import TRHeader from "src/components/Shared/Table/TRHeader";
import TableOptions from "src/components/Shared/TableOptions";
import UserEntry from "src/components/Users/List/UserEntry";
import { apiUrl } from "src/config/host";
import { getUrlParams } from "src/helpers/getUrlParams";
import useFetch from "src/hooks/useFetch";

const WorkspaceUsersList = ({ ...props }) => {
  const [deleteId, setDeleteId] = useState(null);

  // Search
  const [keyword, setKeyword] = useState("");
  const [status, setStatus] = useState("");
  const [group] = useState("");

  // Pagination
  const [paginationLimit, setPaginationLimit] = useState(10);
  const [paginationOffset, setPaginationOffset] = useState(0);

  let {
    params: { workspaces: workspaceId },
  } = getUrlParams(window);

  const {
    response: { data: users, meta },
    status: { done: usersLoaded },
    refreshData: refreshUsers,
  } = useFetch(`/workspaces/${workspaceId}/users`, { query: { limit: paginationLimit, offset: paginationOffset * paginationLimit, keyword: keyword, status: status, group: group } });

  const handlePaginationChange = ({ limit, offset }) => {
    setPaginationLimit(limit);
    setPaginationOffset(offset);
  };

  useEffect(() => {
    refreshUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationLimit, paginationOffset, status, group]);

  useEffect(() => {
    refreshUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  const removeUser = async (e) => {
    e.preventDefault();

    try {
      const message = await props.deleteUser(deleteId);
      setDeleteId(null);
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const prepData = async () => {
    const params = { keyword: keyword, workspace_id: workspaceId };
    try {
      const res = await fetchData("GET", `${apiUrl}/workspaces/${workspaceId}/users`, params);
      const { data } = await res.json();

      let exportDoc = [];
      if (data.length > 0) {
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          let groupName = [];
          if (element.groups.length > 0) {
            for (let i = 0; i < element.groups.length; i++) {
              const grup = element.groups[i];
              groupName.push(grup.name);
            }
          }
          exportDoc.push({
            Name: element.name,
            Email: element.email,
            Type: element.type,
            Groups: groupName.toString(),
            "Date Created": moment(new Date(element.created_at)).format("MM/DD/YYYY HH:mm:ss"),
            "Last Login": element.last_login ? moment(new Date(element.last_login)).format("MM/DD/YYYY HH:mm:ss") : "n/a",
            "Is Active": element.active_status ? "true" : "false",
            "Deactivation Dated": !element.active_status ? moment(new Date(element.deactivated_at)).format("MM/DD/YYYY HH:mm:ss") : "n/a",
          });
        }
      }
      return exportDoc;
    } catch (error) {
      console.dir(error.message);
    }
  };

  return (
    <>
      <TableOptions
        leftContent={
          <Search
            keyword={keyword}
            setKeyword={setKeyword}
          />
        }
        middleContent={
          <SelectMenu
            options={[
              { key: "All Status", value: "" },
              { key: "Active", value: "true" },
              { key: "Inactive", value: "false" },
            ]}
            setOption={(option) => {
              if (option.value) {
                setStatus(option.value === "true");
              } else {
                setStatus(option.value);
              }
            }}
          />
        }
      />
      <Table
        tableHeader={
          <TRHeader>
            <th
              scope="col"
              className="rounded-l-xl py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Name
            </th>
            <th
              scope="col"
              className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Email
            </th>
            <th
              scope="col"
              className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Status
            </th>
            <th
              scope="col"
              className="rounded-r-xl relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Edit</span>
            </th>
          </TRHeader>
        }
        colSpan="4"
        setDeleteId={setDeleteId}
        deleteId={deleteId}
        loaded={usersLoaded}
        dataExists={users?.length > 0}>
        {users?.length > 0 &&
          users.reduce((filteredUsers, user) => {
            return filteredUsers.length < paginationLimit
              ? [
                  ...filteredUsers,
                  <UserEntry
                    key={user._id}
                    user={user}
                    deleteId={deleteId}
                    setDeleteId={setDeleteId}
                    refreshUsers={refreshUsers}
                  />,
                ]
              : filteredUsers;
          }, [])}
      </Table>
      <PaginationFooter
        itemName="User records"
        totalEntriesCount={users?.length && meta.count}
        loaded={usersLoaded}
        limit={paginationLimit}
        offset={paginationOffset}
        tabCount={usersLoaded && Math.ceil(meta.count / paginationLimit)}
        onChange={handlePaginationChange}
      />
      <DataExport
        title="user_list_report"
        headings={["Name", "Email", "Type", "Groups", "Date Created", "Last Login", "Is Active", "Deactivated Date"]}
        prepData={prepData}
      />
      <ModalDefault
        isOpen={!!deleteId}
        closeModal={() => setDeleteId(null)}
        onSubmit={removeUser}
        submitText="Delete"
        cancelText="Cancel"
        title="User"
        secondaryTitle="Delete">
        <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete {usersLoaded && users.find((user) => user._id === deleteId)?.name}?</div>
      </ModalDefault>
    </>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {
  deleteUser,
})(WorkspaceUsersList);
