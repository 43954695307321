import React, { useState, useEffect } from "react";
import { EnvelopeIcon, Bars3Icon } from "@heroicons/react/24/solid";
import { connect } from "react-redux";
import mapKeys from "lodash/mapKeys";
import { v4 as uuidv4, validate as uuidValidate } from "uuid";
import { getEmailerdData, manageEmailerData } from "src/actions/emailer";
import { toast } from "react-toastify";
import { H1 } from "src/components/Shared/Text/Headers";
import EmailerItem from "src/components/Emailer/EmailerItem";
import { classNames } from "src/helpers/classNames";
import { getGroupData } from "src/actions/user";
import EmailerListSection from "src/components/Emailer/EmailerListSection";

const EmailerSection = ({ pages, getEmailerdData, groups, manageEmailerData, site, ...props }) => {
  const [state, setState] = useState({});
  const tabs = [
    { name: "Create Email", icon: EnvelopeIcon },
    { name: "Delivered Emails", icon: Bars3Icon },
  ];
  const [selectedTab, setSelectedTab] = useState("Create Email");

  // Load groups
  useEffect(() => {
    const ac = new AbortController();

    const loadGroups = async () => {
      try {
        await props.getGroupData({}, ac.signal);
      } catch (err) {
        console.dir(err.message);
      }
    };

    loadGroups();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const ac = new AbortController();

    const loadPageData = async () => {
      try {
        const data = await getEmailerdData({}, ac.signal);

        if (data && data.length) {
          setState({
            ...mapKeys(data, "_id"),
          });
        } else if (!data || !data.length) {
          const key = uuidv4();

          setState({
            [key]: {
              _id: key,
              name: "",
              credential_id: "",
              embed_id: "",
              is_default: false,
            },
          });
        }
      } catch (error) {
        console.dir(error.message);
      }
    };

    loadPageData();
    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (actualState = state) => {
    try {
      let updateData = [];

      Object.values(actualState).forEach((item) => {
        if (uuidValidate(item._id)) {
          updateData.push({ ...item, _id: null });
        } else {
          updateData.push(item);
        }
      });

      const { message, data } = await manageEmailerData({
        pages: updateData,
      });

      if (data && data.length) {
        // const key = uuidv4();

        setState({
          ...mapKeys(data, "_id"),
        });
      }

      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <H1>Emailer</H1>
      <div className="border-b border-gray-200">
        <nav
          className="-mb-px flex space-x-8"
          aria-label="Tabs">
          {tabs.map((tab, i) => (
            <div
              key={tab.name}
              onClick={() => setSelectedTab(tab.name)}
              className={classNames(tab.name === selectedTab ? "border-highlightColor text-highlightColor" : "border-transparent text-gray-400 hover:text-gray-500 hover:border-gray-400", "group inline-flex items-center pb-3 px-1 border-b-2 font-medium text-sm cursor-pointer")}
              aria-current={tab.name === selectedTab ? "page" : undefined}>
              <tab.icon
                className={classNames(tab.name === selectedTab ? "text-highlightColor" : "text-gray-400 group-hover:text-gray-400", "-ml-0.5 mr-2 h-5 w-5")}
                aria-hidden="true"
              />
              <span>{tab.name}</span>
            </div>
          ))}
        </nav>
      </div>
      <div className="mt-4">
        {tabs.find((tab) => tab.name === selectedTab).name === "Create Email" ? (
          Object.keys(state).length > 0 &&
          Object.values(state).map((item, i) => (
            <EmailerItem
              key={item._id}
              page={item}
              index={item._id}
              state={state}
              setState={setState}
              pages={pages}
              groups={groups}
              i={i}
              onSubmit={onSubmit}
            />
          ))
        ) : (
          <EmailerListSection></EmailerListSection>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    pages: Object.values(state.pages),
    groups: Object.values(state.groups),
    site: state.site,
  };
};

export default connect(mapStateToProps, { getEmailerdData, manageEmailerData, getGroupData })(EmailerSection);
