// import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { connect } from "react-redux";
import FrontLabeledInput from "src/components/Shared/Forms/Inputs/FrontLabeledInput";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import { classNames } from "src/helpers/classNames";

const CreateDomoPageContent = ({ newPage = {}, setNewPage = () => {}, startIndex = "", pageCredentialOptions = [], advancedFeatures = "", setAdvancedFeatures = () => {} }) => {
  return (
    <>
      <div className="grid gap-y-3">
        <SelectMenu
          label="DOMO credentials"
          startIndex={startIndex}
          options={pageCredentialOptions || [{ key: "Select credentials", value: -1 }]}
          setOption={(option) => {
            setNewPage({ ...newPage, credential_id: option.value });
          }}
        />
        <FrontLabeledInput
          label="Embed ID"
          type="text"
          value={newPage.embed_id}
          onChange={(e) => setNewPage({ ...newPage, embed_id: e.target.value })}
        />
      </div>
      <div className="mt-5 flex justify-end">
        <div
          onClick={() => setAdvancedFeatures(!advancedFeatures)}
          className="-mt-[10px] inline-flex cursor-pointer items-center gap-x-1 rounded-md py-1 px-2 text-gray-300 transition-all duration-200 sm:hover:bg-gray-50 sm:hover:text-gray-400">
          <p>Advanced features</p>
          <ChevronDownIcon className={classNames("h-5", advancedFeatures ? "" : "-rotate-90")} />
        </div>
      </div>
      {/* Group image */}
      <div className={classNames("overflow-hidden transition-all duration-150", advancedFeatures ? "mb-4 max-h-[120px] rounded-md bg-gray-50 p-3 opacity-100" : "max-h-[0px] opacity-0")}>
        <div className="-mx-2 flex flex-wrap">
          <div className="mb-4 flex w-full px-2">
            <input
              id="card-embed"
              checked={newPage.embed_type === "EMBED_CARD" ? true : false}
              onChange={(e) => {
                setNewPage({
                  ...newPage,
                  embed_type: e.target.checked ? "EMBED_CARD" : "EMBED_PAGE",
                });
              }}
              aria-describedby="card-embed"
              name="card-embed"
              type="checkbox"
              className="h-5 w-5 rounded border-gray-300 text-highlightColor focus:ring-highlightColor"
            />
            <div className="ml-3 max-w-[400px]">
              <label
                htmlFor="card-embed"
                className="text-sm text-gray-500">
                Display page as a Domo "Card"
              </label>
              <p className="whitespace-normal text-sm text-gray-400">*Individual cards must have card embed_ids using Domo Everywhere.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
  };
};

export default connect(mapStateToProps, {})(CreateDomoPageContent);
