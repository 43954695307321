import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { deletePageCredentialOptions, getPageCredentialOptions, managePageCredentialOptions } from "src/actions/page";
import FrontLabeledInput from "src/components/Shared/Forms/Inputs/FrontLabeledInput";
import Input from "src/components/Shared/Forms/Inputs/Input";
import ModalDefault from "src/components/Shared/Modals/ModalDefault";

const SiteIntegrations = ({ credentials, getPageCredentialOptions, managePageCredentialOptions, deletePageCredentialOptions }) => {
  const [newCredential, setNewCredential] = useState({
    name: "",
    client_id: "",
    client_secret: "",
  });

  const [credentialModal, setCredentialModal] = useState(false);

  const loadPageCreds = async () => {
    const ac = new AbortController();
    try {
      await getPageCredentialOptions({}, ac.signal);
    } catch (error) {
      console.dir(error);
    }
    return () => ac.abort();
  };

  useEffect(() => {
    loadPageCreds();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createNewCredential = async () => {
    const ac = new AbortController();
    try {
      const { message } = await managePageCredentialOptions(newCredential);
      setCredentialModal(false);
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
    return () => ac.abort();
  };

  const removePageCredential = async (credential) => {
    const ac = new AbortController();
    try {
      await deletePageCredentialOptions({ credential_id: credential._id, workspace_id: credential.workspace_id }, ac.signal);
      loadPageCreds();
    } catch (error) {
      toast.error(error.message);
    }
    return () => ac.abort();
  };

  return (
    <>
      <div className="flex flex-col">
        <div className="w-full mt-3">
          <div className="shadow-md ring-1 ring-black ring-opacity-5 rounded-md md:rounded-lg">
            <table className="w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="rounded-l-xl rounded-r-xl overflow-hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    Integrations
                  </th>
                </tr>
              </thead>

              <tbody className="w-full divide-y divide-gray-200 bg-white overflow-y-auto">
                {credentials.length > 0 ? (
                  credentials.map((workspace) => {
                    return (
                      <Fragment key={workspace._id}>
                        <tr className="justify-between w-full group relative block sm:table-row bg-white rounded-md">
                          <td
                            className="w-full relative md:flex md:justify-between whitespace-nowrap align-top px-3 py-2 bg-slate-100 font-semibold text-sm text-gray-500 block sm:table-cell"
                            colSpan={3}>
                            {workspace.name}
                          </td>
                        </tr>
                        {workspace?.page_credentials?.map((credential) => {
                          return (
                            <tr
                              key={credential._id}
                              className="justify-between w-full group relative block sm:table-row bg-white rounded-md">
                              <td className="w-full relative md:flex md:justify-between whitespace-nowrap align-top px-3 py-4 text-sm text-gray-500 block sm:table-cell">
                                <div className="grid gap-y-1 md:flex md:gap-y-0 md:gap-x-3">
                                  <p className="md:min-w-[340px] text-lg pl-1 mb-2 md:mb-0 truncate md:flex md:items-center">{credential.name}</p>
                                  <div className="flex flex-col md:flex-row space-y-2 max-w-[600px] lg:max-w-[1000px] md:space-y-0 md:space-x-2 space-x-0 w-full">
                                    <FrontLabeledInput
                                      label="Client ID"
                                      type="password"
                                      value={credential.client_id}
                                      disabled={true}
                                    />
                                    <FrontLabeledInput
                                      label="Client Secret"
                                      type="password"
                                      value={credential.client_secret}
                                      disabled={true}
                                    />
                                  </div>
                                </div>
                                <div className="h-[21px] w-[21px] absolute md:relative md:top-auto md:right-auto ml-4 top-1 right-3">
                                  <button
                                    type="button"
                                    className="p-1 hover:bg-gray-50 transition-all duration-300 bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                                    onClick={() => removePageCredential(credential)}>
                                    <span className="sr-only">Delete</span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      aria-hidden="true"
                                      className="h-6 w-6">
                                      <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"></path>
                                    </svg>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </Fragment>
                    );
                  })
                ) : (
                  <tr className="justify-between w-full h-20 group relative block sm:table-row bg-white rounded-md">
                    <div className="mt-4 m-0 text-md font-medium text-gray-400 ml-3 flex">
                      <p className="px-3.5 py-3 rounded-md bg-gray-50">No credentials created</p>
                    </div>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* <div className="h-full w-full flex items-center justify-end mt-4">
        <Button
          styleType="secondary"
          onClick={() => {
            setCredentialModal(true);
          }}
        >
          Add Domo Credential
        </Button>
      </div> */}
      <ModalDefault
        isOpen={credentialModal}
        closeModal={() => setCredentialModal(false)}
        onSubmit={() => {
          createNewCredential();
          setNewCredential({
            name: "",
            client_id: "",
            client_secret: "",
          });
        }}
        submitText="Submit"
        cancelText="Cancel"
        title="Credential"
        secondaryTitle="Create">
        <div className="whitespace-nowrap text-sm text-gray-500 grid gap-y-8">
          <div className="grid gap-y-1">
            <Input
              type="text"
              name="credential-name"
              autoComplete="off"
              label="Name"
              value={newCredential.name}
              onChange={(e) => setNewCredential({ ...newCredential, name: e.target.value })}
            />
          </div>
          <div className="grid gap-y-1">
            <div className="flex text-lg">Client Authorization</div>
            <div className="grid space-y-2">
              <FrontLabeledInput
                label="Client ID"
                type="password"
                autoComplete="new-password"
                value={newCredential.client_id}
                onChange={(e) =>
                  setNewCredential({
                    ...newCredential,
                    client_id: e.target.value,
                  })
                }
              />
              <FrontLabeledInput
                label="Client Secret"
                type="password"
                autoComplete="new-password"
                value={newCredential.client_secret}
                onChange={(e) =>
                  setNewCredential({
                    ...newCredential,
                    client_secret: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
      </ModalDefault>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    credentials: state.pageCredentialOptions,
    wholeSate: state,
  };
};

export default connect(mapStateToProps, { getPageCredentialOptions, managePageCredentialOptions, deletePageCredentialOptions })(SiteIntegrations);
