import { CodeBracketIcon, PresentationChartBarIcon, WindowIcon } from "@heroicons/react/24/outline";
import { motion, useIsPresent } from "framer-motion";
import QuickMenu from "src/components/Shared/QuickMenu";

const PageNewEntry = ({ id, name, type, workspace, ...props }) => {
  let isPresent = useIsPresent();

  let generateStyle = () => {
    if (isPresent) {
      return {
        position: "relative",
      };
    } else {
      return {
        position: "absolute",
        display: "flex",
        alignItems: "center",
      };
    }
  };

  const iconTypes = {
    DOMO_PAGE: {
      icon: <PresentationChartBarIcon />,
      name: "DOMO Page",
    },
    DOMO_CARD: {
      icon: <PresentationChartBarIcon />,
      name: "DOMO Page",
      type: "DOMO_CARD",
    },
    CUSTOM_HTML: {
      icon: <CodeBracketIcon />,
      name: "Custom HTML",
    },
    LANDING_PAGE: {
      icon: <WindowIcon />,
      name: "Landing Page",
    },
  };

  return (
    <>
      <motion.tr
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{
          opacity: 0,
        }}
        transition={{ opacity: { duration: 0.2 } }}
        style={generateStyle}
        // ref={innerRef}
        // {...draggableProps}
        className="group relative flex w-full flex-col items-start justify-between rounded-md bg-white py-4 sm:table-row">
        <td className="bg-white px-3 py-3 text-sm">
          <div className="text-gray-900 font-medium">{name}</div>
        </td>
        <td className="bg-white px-3 py-3 text-sm">
          <div className="text-gray-900 font-medium">
            <div className="flex item-center gap-x-4">
              <div className="h-5 w-5">{iconTypes[type].icon}</div>
              {iconTypes[type].name}
              {iconTypes[type].type === "DOMO_CARD" && <div className="px-2 py-1 text-gray-600 rounded-full bg-gray-100">Card</div>}
            </div>
          </div>
        </td>
        <td className="bg-white px-3 py-3 text-sm">
          <div className="flex justify-end">
            <QuickMenu items={[{ name: "Edit" }, { name: "Preview" }, { name: "Delete", color: "text-red-500" }]} />
          </div>
        </td>
      </motion.tr>
    </>
  );
};

export default PageNewEntry;
