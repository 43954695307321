import { motion, useIsPresent } from "framer-motion";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import QuickMenu from "src/components/Shared/QuickMenu";
import TRBody from "src/components/Shared/Table/TRBody";

const GroupEntry = ({ user, group, setDeleteId, site, ...props }) => {
  let isPresent = useIsPresent();

  const [items, setItems] = useState([]);

  useEffect(() => {
    let item = [];
    if (user?.type === "admin" || (user?.user_permissions && user?.user_permissions?.group_update)) {
      item.push({
        name: "Edit",
        onClick: () => {
          // navigate(`/groups/edit/${group._id}`);
          props.setEditId(group._id);
          props.setGroupModalOpen(true);
          props.setEditGroupStatus(true);
          props.setAddGroupStatus(false);
        },
      });
    }
    if (user?.type === "admin" || (user?.user_permissions && user?.user_permissions?.group_delete)) {
      item.push({
        name: "Delete",
        onClick: () => {
          setDeleteId(group._id);
        },
        color: "text-red-500",
      });
    }
    setItems(item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  let generateStyle = () => {
    if (isPresent) {
      return {
        position: "relative",
      };
    } else {
      return {
        position: "absolute",
        display: "flex",
        alignItems: "center",
      };
    }
  };

  return (
    <>
      <motion.tr
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{
          opacity: 0,
        }}
        transition={{ opacity: { duration: 0.2 } }}
        style={generateStyle}
        key={group?._id + "workspace"}
        className="relative"></motion.tr>
      <motion.tr
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{
          opacity: 0,
        }}
        transition={{ opacity: { duration: 0.2 } }}
        style={generateStyle}
        key={group?._id + "group"}
        className="relative bg-white">
        <TRBody className="whitespace-pre-wrap">
          <div className="text-gray-900 font-medium">{group?.name}</div>
        </TRBody>

        <TRBody className="whitespace-pre-wrap">
          <div className="text-gray-900 font-medium">{group?.permissions.length}</div>
        </TRBody>
        <td className="whitespace-nowrap py-3 pl-3 pr-4 text-sm font-medium">
          {(user?.type === "admin" || (user?.user_permissions && (user?.user_permissions?.group_update || user?.user_permissions?.group_delete))) && (
            <div className="flex justify-end">
              <QuickMenu items={items} />
            </div>
          )}
        </td>
      </motion.tr>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(GroupEntry);
