import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { classNames } from "src/helpers/classNames";

const Search = ({ label, labelClasses, keyword, setKeyword, placeholder }) => {
  const [focused, setFocused] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [text, setText] = useState(keyword);

  const handleInputChange = (event) => {
    const newValue = event.target.value;

    setText(newValue);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      setKeyword(newValue);
    }, 50);

    setTimeoutId(newTimeoutId);
  };

  return (
    <div className="w-full flex flex-col">
      {label && <label className={"mb-1 block text-sm font-medium text-gray-700 " + labelClasses}>{label}</label>}
      <div className="group relative rounded-md shadow-sm w-full flex">
        <button
          className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
          aria-hidden="true">
          <MagnifyingGlassIcon
            className={classNames("mr-3 h-4", focused ? "text-highlightColor" : "text-gray-400")}
            aria-hidden="true"
          />
        </button>
        <input
          type="text"
          name="search"
          id="search"
          onBlur={() => setFocused(false)}
          onFocus={() => setFocused(true)}
          value={text}
          className="block w-full pl-9 h-10 border-gray-300 text-sm rounded-md focus:ring-0 focus:border-highlightColor"
          placeholder={placeholder}
          onChange={handleInputChange}
        />
      </div>
    </div>
  );
};

export default Search;
